export function getHomeForm() {
  let fields = [
    {
      id: "username",
      label: "Nome Utente",
      name: "username",
      type: "input",
      disabled: false,
    },
    {
      id: "password",
      label: "Password",
      name: "password",
      type: "input",
      kind:"password",
      disabled: false,
      required: true,
    },
    {
      id: "submit",
      label: "Login",
      name: "submit",
      type: "button",
      require: false,
    },
  ];

  return fields;
}
