/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  getColumns,
  getOutflowsFormFields,
  getOutflowsFormFilters,
  getTypoFormField,
} from "./GestioneUsciteConfig";
import { Button, Table, Row, Col } from "antd";
import FormInModal from "../../components/FormInModal/FormInModal";
import dayjs from "dayjs";
import FormCustom from "../../components/FormCustom/FormCustom";
import styles from "./GestioneUscitePage.module.scss";
import TableCustom from "../../components/Table/TableCustom";
import { GestioneUsciteService } from "./GestioneUsciteService";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import { Form } from "antd";
import ModalCustom from "../../components/Modal/ModalCustom";
import openNotification from "./../../components/Notification/Notification";
import { useSelector, useDispatch } from "react-redux";

const getOutflowsParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default function GestioneUscitePage() {
  const [form] = Form.useForm();
  const defaultValues = useSelector(
    (state) => state.gestioneUscite.defaultValues
  );
  const { height, width } = useWindowDimensions();
  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [openTypo, setOpenTypo] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  /* END MODAL TRIGGERS */
  const [maxDate, setMaxDate] = useState();

  const [defaultSearchValues, setDefaultSearchValues] = useState({
    dataRange: [],
    meseRadioGroup: "",
  });
  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: "dataUscita",
    order: "desc",
    filters: {},
  });
  const [dataSource, setDataSource] = useState([]);
  /* END OF TABLE CONFIG */
  /* START MODAL FORM HANDLER SECTION */
  const [isView, setIsView] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  //const [initialValuesTypo] = useState({});
  const [outFlowsType, setOutFlowsTypes] = useState([]);
  const [typeIds, setTypeIds] = useState([]);
  //const [isNewTypo, setIsNewTypo] = useState([]);
  /* END MODAL FORM HANDLER SECTION */

  const readData = () => {
    GestioneUsciteService.getOutflows(
      getOutflowsParams(tableParams).page,
      getOutflowsParams(tableParams).pageSize,
      getOutflowsParams(tableParams).field +
        ":" +
        getOutflowsParams(tableParams).order,
      getOutflowsParams(tableParams).filters
    ).then((data) => {
      setDataSource(
        data.data.map((el) => {
          return {
            id: el.id,
            dataUscita: el.dataUscita,
            importo: parseFloat(el.importo.toFixed(2)),
            descrizioneUscita: el.descrizioneUscita,
            tipologiaUscita: el.outflow_type?.descrizione,
            disabled: dayjs(el.dataUscita) < maxDate ? true : false,
            sales: el.sales ? "SI" : "NO",
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    handleTypo();
  }, []);

  useEffect(() => {
    readData();
  }, [maxDate]);

  useEffect(() => {
    setLoading(true);
    GestioneUsciteService.getMaxDate().then((data) => {
      if (data.data) setMaxDate(dayjs(data?.data[0]?.dataUscita));
    });
    readData();
  }, [JSON.stringify(tableParams)]);

  const onSubmit = (values) => {
    setLoading(true);
    let subId = typeIds?.find(
      (el) => el.descrizione === values.tipologiaUscita
    ).id;

    let objToSend = {
      data: {
        ...values,
        dataUscita: dayjs(values.dataUscita).format("YYYY-MM-DD"),
        outflow_type: subId,
      },
    };

    delete objToSend.data.id;
    delete objToSend.data.tipologiaUscita;

    if (isNew) {
      GestioneUsciteService.createOutflow(objToSend)
        .then(() => {
          readData();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    } else if (isEdit) {
      GestioneUsciteService.updateOutflow(parseInt(values.id), objToSend.data)
        .then((res) => {
          setMaxDate(dayjs(res.dataUscita));
          readData();
          openNotification(
            "Successo",
            "La modifica è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }

    setOpen(false);
  };

  const onSearch = (values) => {
    let filtersToSend = values.dataRange?.length
      ? {
          and: [
            {
              dataUscita: {
                gte: dayjs(values.dataRange[0]).format("YYYY-MM-DD"),
              },
            },
            {
              dataUscita: {
                lte: dayjs(values.dataRange[1]).format("YYYY-MM-DD"),
              },
            },
          ],
        }
      : {};

    if (values.descrizione) {
      filtersToSend = {
        ...filtersToSend,
        outflow_type: {
          descrizione: {
            eq: values.descrizione ? values.descrizione : "",
          },
        },
      };
    }

    setTableParams((prevState) => {
      return {
        ...prevState,
        filters: filtersToSend,
      };
    });

    delete values.search;
    setDefaultSearchValues(values);
  };

  const onDelete = (id) => {
    setLoading(true);
    GestioneUsciteService.deleteOutflow(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });

    setDeleteModal(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let sort = {
      ...sorter,
      field: sorter.field ? sorter.field : "dataUscita",
      order: sorter?.order
        ? sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : ""
        : "desc",
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
        ...sort,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  const handleTypo = () => {
    GestioneUsciteService.getOutflowsTypes().then((res) => {
      let arr = [];
      res.data.forEach((el) => {
        arr.push({
          id: el.id,
          descrizione: el.descrizione,
        });
      });
      setOutFlowsTypes(arr);
      setTypeIds(res.data);
    });
  };

  const onTypoSubmit = (values) => {
    if(values != ""){
    let objToSend = {
      data: {
        descrizione: values,
      },
      /* data: {
        ...values,
      }, */
    };

    GestioneUsciteService.createTypo(objToSend)
      .then(() => {
        handleTypo();
        openNotification(
          "Successo",
          "La creazione è andata a buon fine",
          "success"
        );
      })
      .catch((err) => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });

    setOpenTypo(false);
   } };

  const handleDeleteTypo = (values) => {
    let typeToDelete = outFlowsType.filter(
      (el) => el.descrizione.toLowerCase().includes(values.toLowerCase()))[0].id;

    GestioneUsciteService.deleteOutflowType(typeToDelete)
      .then(() => {
        handleTypo();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
    //setOpenTypo(false);
  };

  const dataToExcel = (data) => {
    let list = [...data];
    let listToSend = [];

    list.forEach((el) => {
      listToSend.push({
        ["Data"]: el.dataUscita,
        ["Tipologia Uscita"]: el.tipologiaUscita,
        ["Descrizione Uscita"]: el.descrizioneUscita,
        ["Uscita"]: el.importo,
      });
    });

    return listToSend;
  };

  return (
    <div>
      {/* START OF FILTER SECTION */}
      <div className={styles.searchFormContainer}>
        <FormCustom
          form={form}
          currentRow={defaultSearchValues}
          className={styles.searchForm}
          id={"outflowsFilterForm"}
          name={"outflowsFilterForm"}
          fields={getOutflowsFormFilters(
            outFlowsType.map((el) => {
              return el.descrizione;
            })
          )}
          onFinish={onSearch}
          isSearch={true}
        />
      </div>
      {/* END OF FILTER SECTION */}

      {/* START TABLE SECTION */}
      <Row>
        <Col span={24}>
          <TableCustom
            excelConfig={{
              excel: true,
              excelText: "Esporta",
              dataToExport: dataToExcel(dataSource),
              fileName: "Gestione Uscite",
              colSize: 16,
            }}
            loading={loading}
            className={styles.table}
            rowClassName={(record, index) =>
              record.disabled === true ? styles.disabledRow : styles.defaultRow
            }
            size={width <= 1300 ? "small" : "large"}
            scroll={{
              x: 1250,
            }}
            columns={getColumns(
              setOpen,
              setIsEdit,
              setIsView,
              setInitialValues,
              setDeleteModal,
              setDeleteId,
              setIsNew
            )}
            dataSource={dataSource}
            title={() => (
              <span style={{ fontWeight: "bold" }}>
                {"Elenco Uscite".toLocaleUpperCase()}
              </span>
            )}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            showPagination={true}
            pageSizeOptions={["5", "10", "20", "50"]}
            showSizeChanger={true}
          />
        </Col>
      </Row>
      {/* END TABLE SECTION */}

      {/* START NEW/EDIT RECORD SECTION */}
      <Row>
        <Col span={4}>
          <Button
            className={styles.newEntry}
            type="primary"
            onClick={() => {
              setIsNew(true);
              setIsView(false);
              setIsEdit(false);
              setOpen(true);
            }}
          >
            {"Nuova Uscita"}
          </Button>
        </Col>
      </Row>

      {/* START OF OUTFLOW FORM */}
      <FormInModal
        isSearch={false}
        dataSource={dataSource}
        defaultValues={defaultValues}
        getFormFields={getOutflowsFormFields(
          isView,
          outFlowsType.map((el) => {
            return el.descrizione;
          }),
          handleDeleteTypo,
          onTypoSubmit
        )}
        modalStyle={!isView && !isEdit && styles.modalStyle}
        className={styles.outflowForm}
        isEdit={isEdit}
        isView={isView}
        initialValues={initialValues}
        title={isEdit ? "Modifica Uscita" : isView ? "Uscita" : "Nuova Uscita"}
        okText={isEdit ? "Salva Modifiche" : "Salva Uscita"}
        cancelText={"Annulla"}
        width={600}
        open={open}
        onSubmit={onSubmit}
        onCancel={() => {
          setOpen(false);
        }}
      />
      {/* END OF OUTFLOW FORM */}

      <ModalCustom
        centered={true}
        title={"Cancella Uscita"}
        open={deleteModal}
        okText={"Conferma"}
        cancelText={"Annulla"}
        width={500}
        handleCancel={() => {
          setDeleteModal(false);
        }}
        handleOk={() => {
          onDelete(deleteId);
        }}
      >
        <Row className={styles.textContainer}>
          <Col className={styles.text} span={18}>
            {"Sei sicuro di voler cancellare questa uscita?"}
          </Col>
        </Row>
      </ModalCustom>
    </div>
  );
}
