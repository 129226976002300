import React from "react";
import { Form, Modal, Button } from "antd";
import FormCustom from "../FormCustom/FormCustom";

export default function FormInModal(props) {
  const [form] = Form.useForm();

  return (
    <Modal
      className={props.modalStyle}
      bodyStyle={props.bodyStyle}
      centered={props.centered}
      width={props.width}
      open={props.open}
      title={props.title}
      okText={props.okText}
      cancelText={props.cancelText}
      destroyOnClose={true}
      onCancel={() => {
        props.onCancel();
        props.setIsIncome && props.setIsIncome(false);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            props.onSubmit(values);
          })
          .catch((info) => {});
      }}
      footer={
        props.isView ? (
          <Button
            style={{ width: 80 }}
            id="cancel-button"
            type="primary"
            onClick={props.onCancel}
          >
            {"Esci"}
          </Button>
        ) : (
          props.footer
        )
      }
    >
      <FormCustom
        isSearch={props.isSearch}
        isView={props.isView}
        dataSource={props.dataSource}
        isIncome={props.isIncome}
        totalExit={props.totalExit}
        className={props.className}
        preserve={false}
        id={"incomeFilterForm"}
        name={"incomeFilterForm"}
        fields={props.getFormFields}
        form={form}
        isEdit={props.isEdit}
        currentRow={
          props.isEdit || props.isView
            ? props.initialValues
            : props.defaultValues
        }
        layout="vertical"
      />
      {props.children}
    </Modal>
  );
}
