import {
  CREATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  EDIT_BENEFICIARY,
  GET_BENEFICIARIES,
} from "../../utility/graphql/BeneficiariesQueries";
import {
  DELETE_BENEFICIARY_TYPE,
  EDIT_BENEFICIARY_TYPE,
  GET_BENEFICIARIES_TYPES,
} from "../../utility/graphql/BeneficiariesTypeQueries";
import { ApolloRepo } from "../../utility/graphql/Client";
import { strapiAdapter } from "../../utility/methods/methods";

class GestioneFornitoreServiceC {
  getBeneficiaries(page, pageSize, sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_BENEFICIARIES,
        variables: {
          pagination:
            page && pageSize
              ? { page: page, pageSize: pageSize }
              : { limit: -1 },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.beneficiaries),
          meta: res.data.beneficiaries.meta,
        };
      });
  }

  createBeneficiary(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_BENEFICIARY,
      variables: data,
    });
  }

  updateBeneficiary(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_BENEFICIARY,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateBeneficiary);
      });
  }

  deleteBeneficiary(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_BENEFICIARY,
      variables: { id: id },
    });
  }

  /* START OF BENEFICIARY TYPE SECTION */

  getBeneficiariesTypes(filters) {
    return ApolloRepo.client
      .query({
        query: GET_BENEFICIARIES_TYPES,
        variables: {
          pagination: { limit: -1 },
          filters: filters,
          sort: "description:asc",
        },
      })
      .then((res) => {
        return {
          //data: strapiAdapter(res.data.banks),
          data: strapiAdapter(res.data.beneficiaryTypes),
        };
      });
  }

  createBeneficiaryType(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_BENEFICIARY,
      variables: data,
    });
  }

  updateBeneficiaryType(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_BENEFICIARY_TYPE,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateBeneficiaryType);
      });
  }

  deleteBeneficiaryType(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_BENEFICIARY_TYPE,
      variables: { id: id },
    });
  }

  /* END OF BENEFICIARY TYPE SECTION */
}

export const GestioneFornitoreService = new GestioneFornitoreServiceC();
