/* eslint-disable */
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";

export function getColumns(
  setOpen,
  setIsEdit,
  setIsView,
  setInitialValues,
  setDeleteModal,
  setDeleteId,
  setIsNew
) {
  let columns = [
    {
      title: "Utente",
      dataIndex: "username",
      key: "username",
      width: 100,
    },
    {
      title: "Ruolo",
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsView(false);
              setIsNew(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
              });
              setOpen(true);
            }}
          />
        );
      },
      width: 40,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <DeleteTwoTone
            twoToneColor={"#990000"}
            onClick={() => {
              setDeleteId(render.id);
              setDeleteModal(true);
            }}
            style={{ fontSize: 18 }}
          />
        );
      },
      width: 40,
    },
  ];

  return columns;
}

export function getUsersFormFields(roleType, isEdit) {
  let fields = [
    {
      id: "username",
      label: "Utente",
      name: "username",
      required: true,
      type: "input",
      disabled: isEdit,
    },
    {
      id: "password",
      label: "Password: (Deve contenere almeno 6 caratteri)",
      name: "password",
      required: !isEdit,
      type: "input",
      hidden: isEdit,
    },
    {
      id: "type",
      label: "Ruolo",
      name: "type",
      required: true,
      type: "select",
      dataForSelect: roleType,
    },
    { id: "id", name: "id", hidden: true, required: false },
  ];

  return fields;
}
