/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import "dayjs/locale/it";
import locale from "antd/locale/it_IT";
import { Provider } from "react-redux";
import { store } from "./redux/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);
//console.log(process.env)

root.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </BrowserRouter>
    </ConfigProvider>
  </Provider>
);
