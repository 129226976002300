/* eslint-disable */
import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import FormCustom from "../../components/FormCustom/FormCustom";
import { getHomeForm } from "./LoginPageConfig";
import styles from "./LoginPage.module.scss";
import { LoginService } from "./LoginPageService";
import { useDispatch } from "react-redux";
import { setAuth, setUserType } from "../../redux/slices/LoginSlice";

const LoginPage = () => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    delete values.submit;

    let credential = {
      input: {
        identifier: values.username,
        password: values.password,
        provider: "local",
      },
    };

    LoginService.login(credential).then((res) => {
      localStorage.setItem("access_token", res.data.login.jwt);
      dispatch(
        setAuth({
          token: res.data.login.jwt,
        })
      );
      dispatch(
        setUserType({
          id: res.data.login.user.id,
        })
      );
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          {/* LOGO SECTION */}
          <Row className={styles.imgContainer} justify={"center"}>
            <Col>
              <img src="https://rione24.it/wp-content/uploads/2023/05/Logo-Mini-1.png"></img>
            </Col>
          </Row>
          {/* FORM SECTION */}
          <Row>
            <Col span={24}>
              <div className={styles.formContainer}>
                <FormCustom
                  isInfo={true}
                  form={form}
                  currentRow={initialValues}
                  className={styles.form}
                  id={"homeForm"}
                  name={"homeForm"}
                  fields={getHomeForm()}
                  onFinish={onSubmit}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LoginPage;
