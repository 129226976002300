import { gql } from "@apollo/client";
/* START OF GET REQUEST */
export const GET_YEAR_INCOME = gql`
  query getIncomes(
    $pagination: PaginationArg
    $filters: IncomeFiltersInput
  ) {
    incomes(pagination: $pagination, filters: $filters) {
      data {
        id
        attributes {
          date
          chiusuraFiscale
          totaleBusta
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

/* END OF GET REQUEST */
