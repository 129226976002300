import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { gestioneIncassiSlice } from "./slices/GestioneIncassiSlice";
import { gestioneUsciteSlice } from "./slices/GestioneUsciteSlice";
import { loginSlice } from "./slices/LoginSlice";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import { gestioneAssegniSlice } from "./slices/GestioneAssegniSlice";
import { gestionePrenotazioniSlice } from "./slices/GestionePrenotazioniSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    "gestioneIncassi",
    "gestioneUscite",
    "gestioneAssegni",
    "login",
    "gestionePrenotazioni",
  ],
};

const userPersistConfig = {
  key: "login",
  storage,
  whitelist: ["isAdmin", "isAdministrative", "isSignedIn"],
};

const reducer = combineReducers({
  login: persistReducer(userPersistConfig, loginSlice.reducer),
  gestioneIncassi: gestioneIncassiSlice.reducer,
  gestioneUscite: gestioneUsciteSlice.reducer,
  gestioneAssegni: gestioneAssegniSlice.reducer,
  gestionePrenotazioni: gestionePrenotazioniSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  thunk,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});
