import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://api.rione24.it/graphql/", //PROD
  //uri: "https://api.uat.rione24.it/graphql", //TEST
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("access_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

class ApolloRepoC {
  client;

  constructor() {
    this.client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(false),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "no-cache",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        },
      },
    });
  }
}

export const ApolloRepo = new ApolloRepoC();
