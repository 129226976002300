import {
  CREATE_BANK,
  DELETE_BANK,
  GET_BANKS,
} from "../../utility/graphql/BanksQueries";
import {
  CREATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  GET_BENEFICIARIES,
} from "../../utility/graphql/BeneficiariesQueries";
import {
  CREATE_CHECK,
  DELETE_CHECK,
  EDIT_CHECK,
  GET_CHECKS,
  GET_MAX_DATE,
} from "../../utility/graphql/ChecksQueries";
import { ApolloRepo } from "../../utility/graphql/Client";
import { strapiAdapter } from "../../utility/methods/methods";

class GestioneAssegniServiceC {
  getMaxDate() {
    return ApolloRepo.client
      .query({
        query: GET_MAX_DATE,
        variables: {
          pagination: {
            page: 1,
            pageSize: 1,
          },
          sort: "date:desc",
        },
      })
      .then((res) => {
        return { data: strapiAdapter(res.data.checks) };
      });
  }

  /* START OF BANK SECTION */
  getBanks() {
    return ApolloRepo.client
      .query({
        query: GET_BANKS,
        variables: {
          pagination: { pageSize: 1000 },
          sort: "description:asc",
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.banks),
        };
      });
  }

  createBank(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_BANK,
      variables: data,
    });
  }

  deleteBank(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_BANK,
      variables: { id: id },
    });
  }

  /* END OF BANK SECTION */
  /* START OF BENEFICIARIES SECTION */
  getBeneficiaries() {
    return ApolloRepo.client
      .query({
        query: GET_BENEFICIARIES,
        variables: {
          pagination: { pageSize: 1000 },
          sort: "businessName:asc",
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.beneficiaries),
        };
      });
  }

  createBeneficiary(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_BENEFICIARY,
      variables: data,
    });
  }

  deleteBeneficiary(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_BENEFICIARY,
      variables: { id: id },
    });
  }
  /* END OF BENEFICIARIES SECTION */

  getChecks(page, pageSize, sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_CHECKS,
        variables: {
          pagination: { page: page, pageSize: pageSize },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.checks),
          meta: res.data.checks?.meta,
        };
      });
  }

  createCheck(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_CHECK,
      variables: data,
    });
  }

  deleteCheck(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_CHECK,
      variables: { id: id },
    });
  }

  updateCheck(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_CHECK,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateCheck);
      });
  }
}

export const GestioneAssegniService = new GestioneAssegniServiceC();
