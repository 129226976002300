import { gql } from "@apollo/client";

export const GET_BILLS = gql`
  query getBillList(
    $pagination: PaginationArg
    $sort: [String]
    $filters: BillFiltersInput
  ) {
    bills(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          beneficiary {
            data {
              id
              attributes {
                businessName
                beneficiary_type {
                  data {
                    id
                    attributes {
                      supplierId
                      description
                    }
                  }
                }
              }
            }
          }
          billNumber
          issueDate
          import
          tax
          totalBill
          receiptDate
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
        }
      }
    }
  }
`;

/* START OF MUTATION */
export const CREATE_BILL = gql`
  mutation CreateBill($data: BillInput!) {
    createBill(data: $data) {
      data {
        id
        attributes {
          beneficiary {
            data {
              id
              attributes {
                businessName
                beneficiary_type {
                  data {
                    id
                    attributes {
                      supplierId
                    }
                  }
                }
              }
            }
          }
          billNumber
          issueDate
          import
          tax
          totalBill
          receiptDate
        }
      }
    }
  }
`;

export const EDIT_BILL = gql`
  mutation updateBill($id: ID!, $data: BillInput!) {
    updateBill(id:$id,data: $data) {
      data {
        id
        attributes {
          beneficiary {
            data {
              id
              attributes {
                businessName
                beneficiary_type {
                  data {
                    id
                    attributes {
                      supplierId
                    }
                  }
                }
              }
            }
          }
          billNumber
          issueDate
          import
          tax
          totalBill
          receiptDate
        }
      }
    }
  }
`;

export const DELETE_BILL = gql`
  mutation deleteBill($id: ID!) {
    deleteBill(id: $id) {
      data {
        id
      }
    }
  }
`;

/* END OF MUTATION */
