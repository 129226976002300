import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";

export function getColumns(
  setOpen,
  setIsEdit,
  setInitialValues,
  setDeleteModal,
  setDeleteId,
  readData,
  setIsNew
) {
  let columns = [
    {
      title: "Tipo Fornitore",
      dataIndex: "description",
      key: "description",
      width: 100,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "ID Fornitore",
      dataIndex: "supplierId",
      key: "supplierID",
      width: 100,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsNew(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
              });
              setOpen(true);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <DeleteTwoTone
            twoToneColor={"#990000"}
            onClick={() => {
              setDeleteId(render.id);
              setDeleteModal(true);
            }}
            style={{ fontSize: 18 }}
          />
        );
      },
    },
  ];

  return columns;
}

export function getBeneficiaryTypeFormFields(
  isSelect,
  isHidden,
  isEdit,
  initialValues,
  onBeneficiaryTypeSubmit
) {
  let fields = [
    {
      id: "description",
      label: "Tipo Fornitore",
      name: "description",
      required: true,
      type: "input",
      controls: false,
    },
    {
      id: "supplierId",
      label: "ID Fornitore",
      name: "supplierId",
      required: true,
      type: "input",
      controls: false,
    },
    { id: "id", name: "id", hidden: true, required: false },
  ];

  return fields;
}

export function getBeneficiaryTypeFormFilters(
) {
  let fields = [
    {
      id: "beneficiaryTypeSearch",
      label: "Tipo di Fornitore",
      name: "beneficiaryTypeSearch",
      required: false,
      type: "input",
      controls: false,
      allowClear: true,
      placeholder: "Tipo, ID Fornitore...",
    },
    {
      id: "search",
      label: "Cerca",
      name: "search",
      type: "button",
    },
  ];
  return fields;
}
