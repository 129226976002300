import { gql } from "@apollo/client";

export const GET_MAX_DATE = gql`
  query getMaxDate($pagination: PaginationArg, $sort: [String]) {
    checks(pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          date
        }
      }
      meta {
        pagination {
          page
          pageSize
        }
      }
    }
  }
`;

export const GET_CHECKS = gql`
  query getCheckList(
    $pagination: PaginationArg
    $sort: [String]
    $filters: CheckFiltersInput
  ) {
    checks(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          dateCheck
          beneficiary {
            data {
              id
              attributes {
                businessName
                beneficiary_type {
                  data {
                    id
                    attributes {
                      description
                    }
                  }
                }
              }
            }
          }
          bank {
            data {
              id
              attributes {
                description
              }
            }
          }
          import
          paid
          checkNumber
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
        }
      }
    }
  }
`;

/* START OF MUTATION */
export const CREATE_CHECK = gql`
  mutation CreateCheck($data: CheckInput!) {
    createCheck(data: $data) {
      data {
        id
        attributes {
          dateCheck
          beneficiary {
            data {
              id
              attributes {
                beneficiary_type {
                  data {
                    id
                    attributes {
                      description
                    }
                  }
                }
              }
            }
          }
          bank {
            data {
              id
              attributes {
                description
              }
            }
          }
          import
          paid
        }
      }
    }
  }
`;

export const EDIT_CHECK = gql`
  mutation updateCheck($id: ID!, $data: CheckInput!) {
    updateCheck(id: $id, data: $data) {
      data {
        id
        attributes {
          dateCheck
          beneficiary {
            data {
              id
              attributes {
                beneficiary_type {
                  data {
                    id
                    attributes {
                      description
                    }
                  }
                }
              }
            }
          }
          bank {
            data {
              id
              attributes {
                description
              }
            }
          }
          import
          paid
        }
      }
    }
  }
`;

export const DELETE_CHECK = gql`
  mutation deleteCheck($id: ID!) {
    deleteCheck(id: $id) {
      data {
        id
      }
    }
  }
`;

/* END OF MUTATION */
