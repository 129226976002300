import React from "react";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { LoginService } from "./../../pages/LoginPage/LoginPageService";
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/slices/LoginSlice";
const NavBar = (props) => {
  const dispatch = useDispatch();
  return (
    <nav className="navbar">
      <Button
        className="menu"
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => props.setSelectClose(true)}
      />
      <Drawer
        title="Menu"
        placement="left"
        onClick={() => props.setSelectClose(!props.selectClose)}
        onClose={() => props.setSelectClose(!props.selectClose)}
        open={props.selectClose}
        width={"35%"}
      >
        {props.menu}
      </Drawer>
      <Link to={"/Home"}>
        {
          <img
            src={"https://rione24.it/wp-content/uploads/2023/05/Logo-Mini-1.png"}
            className="logo"
            alt="logo"
          />
        }
      </Link>
      <Button
        className="button"
        type="primary"
        onClick={() =>
          LoginService.logout().then((res) => {
            dispatch(
              setAuth({
                token: null,
                isAdmin: false,
              })
            );
          })
        }
      >
        {"Logout"}
      </Button>
    </nav>
  );
};
export default NavBar;
