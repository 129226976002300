import { gql } from "@apollo/client";

export const GET_RESERVATIONS = gql`
  query getReservation(
    $pagination: PaginationArg
    $sort: [String]
    $filters: ReservationFiltersInput
  ) {
    reservations(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          resDate
          people
          moment
          time
          note
          sit
          status
          event {
            data {
              id
              attributes {
                description
              }
            }
          }
          client {
            data {
              id
              attributes {
                name
                lastName
                telephone
                email
                clientNote
                allergies {
                  data {
                    id
                    attributes {
                      description
                    }
                  }
                }
                dietary_needs {
                  data {
                    id
                    attributes {
                      description
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
        }
      }
    }
  }
`;

export const CREATE_RESERVATION = gql`
  mutation createReservation($data: ReservationInput!) {
    createReservation(data: $data) {
      data {
        id
      }
    }
  }
`;

export const EDIT_RESERVATION = gql`
  mutation updateReservation($id: ID!, $data: ReservationInput!) {
    updateReservation(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_RESERVATION = gql`
  mutation deleteReservation($id: ID!) {
    deleteReservation(id: $id) {
      data {
        id
      }
    }
  }
`;
