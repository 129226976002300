/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import TopicMenu from "./components/TopicMenu/TopicMenu";
import style from "./App.module.css";
import NavBar from "./components/Navbar/Navbar";
import SideBar from "./components/Sidebar/Sidebar";
import useWindowDimensions from "./utility/hooks/useWindowDimensions";
import HomePage from "./pages/Homepage/Homepage";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GestioneIncassiPage from "./pages/GestioneIncassiPage/GestioneIncassiPage";
import GestioneUscitePage from "./pages/GestioneUscitePage/GestioneUscitePage";
import GestioneUtentiPage from "./pages/GestioneUtentiPage/GestioneUtentiPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import Protected from "./components/Protected/Protected";
import {
  setAdmin,
  setAuth,
  setAdministrative,
  setUserType
} from "./redux/slices/LoginSlice";
import dayjs from "dayjs";
import { isTokenExpired } from "./utility/methods/methods";
import { GestioneUtentiService } from "./pages/GestioneUtentiPage/GestioneUtentiService";
import { LoginService } from "./pages/LoginPage/LoginPageService";
import GestioneAssegniPage from "./pages/GestioneAssegniPage/GestioneAssegniPage";
import GestionePrenotazioniPage from "./pages/GestionePrenotazioniPage/GestionePrenotazioniPage";
import GestioneClientiPage from "./pages/GestioneClientiPage/GestioneClientiPage";
import GestioneCalendarioPage from "./pages/GestioneCalendarioPage/GestioneCalendarioPage";
import { Tabs } from "antd";
import GestioneFornitorePage from "./pages/GestioneFornitorePage/GestioneFornitorePage";
import GestioneFatturePage from "./pages/GestioneFatturePage/GestioneFatturePage";
import GestioneTipoFornitorePage from "./pages/GestioneTipoFornitorePage/GestioneTipoFornitorePage";
import { useInterval } from "../src/utility/hooks/useInterval";
import { triggerFocus } from "antd/es/input/Input";

/* START NAVIGATION CONFIG */
const items = [
  { key: "0", label: "Home", path: "/Home" },
  { key: "1", label: "Gestione Prenotazioni", path: "/Gestione-Prenotazioni" },
  { key: "2", label: "Gestione Clienti", path: "/Gestione-Clienti" },
  { key: "3", label: "Gestione Assegni", path: "/Gestione-Assegni" },
  { key: "4", label: "Gestione Fornitore", path: "/Gestione-Fornitore" },
  { key: "5", label: "Gestione Fatture", path: "/Gestione-Fatture" },
  { key: "6", label: "Gestione Uscite", path: "/Gestione-Uscite" },
  { key: "7", label: "Gestione Incassi", path: "/Gestione-Incassi" },
  { key: "8", label: "Gestione Utenti", path: "/Gestione-Utenti" },
];

function matchPathKey(items) {
  if (items.length) {
    return items.find((_item) => location?.pathname?.startsWith(_item.path))
      ?.key;
  }
}
/* END NAVIGATION CONFIG */

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState("0");
  const [selectClose, setSelectClose] = useState(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const credential = useSelector((state) => state.login);
  const token = localStorage.getItem("access_token");
  /* const [checkToken, setCheckToken] = useState(isTokenExpired(token));
  const isExp = checkToken; */
  const tabs = [
    {
      key: "1",
      label: `Prenotazioni`,
      children: <GestionePrenotazioniPage />,
    },
    {
      key: "2",
      label: `Calendario`,
      children: <GestioneCalendarioPage />,
    },
  ];
  const tabsFornitore = [
    {
      key: "1",
      label: `Beneficiari`,
      children: <GestioneFornitorePage />,
    },
    {
      key: "2",
      label: `Tipo Fornitore`,
      children: <GestioneTipoFornitorePage />,
    },
  ];

  const readUser = async () => {
    if (credential.id) {
      const res = await GestioneUtentiService.getUser(credential.id);
      if (res.data) {
        dispatch(setAdmin({ isAdmin: res.data.type.trim() === "admin" }));
        dispatch(
          setAdministrative({
            isAdministrative: res.data.type.trim() === "administrative",
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setUserType({ id: credential.id }));
  }, [credential.id, dispatch]);

  useEffect(() => {
    readUser();
  }, [credential.id]);

  useInterval(() => {
    if (isTokenExpired(token)) {
      dispatch(
        setAuth({
          token: "",
        })
      );
    } else {
      dispatch(
        setAuth({
          token: token,
        })
      );
    }
  }, 5000);

  useEffect(() => {
    if (location.pathname === "/" && credential.isSignedIn) {
      navigate("/Home");
      setSelectedKey("0");
    } else if (location.pathname !== "/" && !credential.isSignedIn) {
      navigate("/");
      LoginService.logout().then((res) => {
        dispatch(
          setAuth({
            token: null,
            isAdmin: false,
            isAdministrative: false,
          })
        );
      });
    }
    setSelectedKey(matchPathKey(items));
  }, [location, credential.isSignedIn]);

  const changeSelectedKey = (item) => {
    const clicked = items.find((_item) => _item.key === item.key);
    navigate(clicked.path);
    if (width <= 820) {
      setSelectClose(!selectClose);
    }
  };

  const menu = (
    <TopicMenu
      topics={items.map((item) => item.label)}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
      isAdmin={credential.isAdmin}
      isAdministrative={credential.isAdministrative}
    />
  );
  /* END NAVIGATION CONFIG */

  return (
    <>
      {credential.isSignedIn ? (
        <div className="App">
          <NavBar
            menu={menu}
            selectClose={selectClose}
            setSelectClose={setSelectClose}
          />
          <Layout>
            <SideBar menu={menu} />
            <Layout.Content className={style.content}>
              <Routes>
                <Route
                  path="/Home"
                  element={
                    <Protected isSignedIn={credential.isSignedIn}>
                      <HomePage />
                    </Protected>
                  }
                />
                {credential.isAdmin && (
                  <Route
                    path="/Gestione-Utenti"
                    element={
                      <Protected isSignedIn={credential.isSignedIn}>
                        <GestioneUtentiPage />
                      </Protected>
                    }
                  />
                )}
                <Route
                  path="/Gestione-Incassi"
                  element={
                    <Protected isSignedIn={credential.isSignedIn}>
                      <GestioneIncassiPage />
                    </Protected>
                  }
                />
                <Route
                  path="/Gestione-Uscite"
                  element={
                    <Protected isSignedIn={credential.isSignedIn}>
                      <GestioneUscitePage />
                    </Protected>
                  }
                />
                <Route
                  path="/Gestione-Assegni"
                  element={
                    <Protected isSignedIn={credential.isSignedIn}>
                      <GestioneAssegniPage />
                    </Protected>
                  }
                />
                <Route
                  path="/Gestione-Prenotazioni"
                  element={
                    <Protected isSignedIn={credential.isSignedIn}>
                      <Tabs
                        destroyInactiveTabPane={true}
                        defaultActiveKey="1"
                        items={tabs}
                      />
                    </Protected>
                  }
                />
                <Route
                  path="/Gestione-Clienti"
                  element={
                    <Protected isSignedIn={credential.isSignedIn}>
                      <GestioneClientiPage />
                    </Protected>
                  }
                />
                <Route
                  path="/Gestione-Fornitore"
                  element={
                    <Protected isSignedIn={credential.isSignedIn}>
                      <Tabs
                        destroyInactiveTabPane={true}
                        defaultActiveKey="1"
                        items={tabsFornitore}
                      />
                    </Protected>
                  }
                />
                <Route
                  path="/Gestione-Fatture"
                  element={
                    <Protected isSignedIn={credential.isSignedIn}>
                      <GestioneFatturePage />
                    </Protected>
                  }
                />
              </Routes>
            </Layout.Content>
          </Layout>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<LoginPage />} />
        </Routes>
      )}
    </>
  );
}
export default App;
