/* eslint-disable */
import React from "react";
import { Button, Table} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import * as XLSX from "xlsx";

const TableCustom = (props) => {
  const { width } = useWindowDimensions();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const paginationInfo = {
    position: props.pagPosition ? props.pagPosition : ["bottomRight"],
    current: props.pagination.current,
    pageSize: props.pagination.pageSize,
    total: props.pagination.total,
    pageSizeOptions: props.pageSizeOptions,
    showSizeChanger: props.showSizeChanger,
  };

  const handleExport = (data) => {
    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    sheet["!cols"] = Object.keys(data[0]).map((el) => {
      return { wch: props.excelConfig?.colSize };
    });
    XLSX.utils.book_append_sheet(workbook, sheet, "Pagina1");
    XLSX.writeFile(workbook, props.excelConfig?.fileName + ".xlsx");
  };

  return (
    <>
      <div
        className={props.btnStyle}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        {props.newRecord && (
          <Button type="primary" onClick={() => props.newRecAction()}>
            {props.newRecText}
          </Button>
        )}
        {props.custom && (
          <Button type="primary" onClick={() => props.customAction}>
            {props.customText}
          </Button>
        )}
        {props.excelConfig?.excel && (
          <Button
            type="primary"
            onClick={() => handleExport(props.excelConfig?.dataToExport)}
          >
            {props.excelConfig?.excelText}
          </Button>
        )}
      </div>

      <Table
        loading={{
          spinning: props.loading,
          indicator: antIcon,
        }}
        rowClassName={props.rowClassName}
        title={props.title}
        size={props.size}
        className={props.className}
        columns={props.columns}
        rowKey={(record) => record.id}
        dataSource={props.dataSource}
        scroll={width <= 1024 ? props.scroll : {}}
        showSorterTooltip={props.showSorterTooltip}
        pagination={props.showPagination ? paginationInfo : false}
        onChange={props.onChange}
        rowSelection={props.rowSelection}
        sticky={{
          offsetHeader: -15,
        }}
        tableLayout={"auto"}
      />
    </>
  );
};

export default TableCustom;
