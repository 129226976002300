import dayjs from "dayjs";

export const strapiAdapter = (data) => {
  const isObject = (data) =>
    Object.prototype.toString.call(data) === "[object Object]";
  const isArray = (data) =>
    Object.prototype.toString.call(data) === "[object Array]";

  const flatten = (data) => {
    if (!data.attributes) return data;

    return {
      id: data.id,
      ...data.attributes,
    };
  };

  if (isArray(data)) {
    return data.map((item) => strapiAdapter(item));
  }

  if (isObject(data)) {
    if (isArray(data.data)) {
      data = [...data.data];
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data });
    } else if (data.data === null) {
      data = null;
    } else {
      data = flatten(data);
    }

    for (const key in data) {
      data[key] = strapiAdapter(data[key]);
    }

    return data;
  }

  return data;
};

export function monthHandle(value) {
  if (value === "meseCorr") {
    return [dayjs().startOf("month"), dayjs().endOf("month")];
  } else if (value === "mesePrec") {
    return [
      dayjs(dayjs().subtract(1, "month")).startOf("month"),
      dayjs(dayjs().subtract(1, "month")).endOf("month"),
    ];
  }
}

export function isTokenExpired(token) {
  if (token) {
    // Separa il token in tre parti
    const parts = token.split(".");

    // Converti il payload in un oggetto JSON
    const expirationDate = JSON.parse(atob(parts[1])).exp;

    return dayjs(expirationDate * 1000) < dayjs();
  }
}
