import { gql } from "@apollo/client";

export const GET_DIETARY_NEED = gql`
  query getDietaryNeed(
    $pagination: PaginationArg
    $sort: [String]
    $filters: DietaryNeedFiltersInput
  ) {
    dietaryNeeds(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          description
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
        }
      }
    }
  }
`;

export const CREATE_DIETARY = gql`
  mutation createDietaryNeed($data: DietaryNeedInput!) {
    createDietaryNeed(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_DIETARY = gql`
  mutation deleteDietaryNeed($id: ID!) {
    deleteDietaryNeed(id: $id) {
      data {
        id
      }
    }
  }
`;
