import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";

export function getColumns(
  setOpen,
  setIsEdit,
  setIsView,
  setInitialValues,
  setDeleteModal,
  setDeleteId,
  setIsNew
) {
  let columns = [
    {
      title: "Data Emissione",
      dataIndex: "issueDate",
      key: "issueDate",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Data Ricezione",
      dataIndex: "receiptDate",
      key: "receiptDate",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Ragione Sociale",
      dataIndex: "businessNameBill",
      key: "businessNameBill",
      width: 100,
    },
    {
      title: "ID Fornitore",
      dataIndex: "idBeneficiary",
      key: "idBeneficiary",
      width: 100,
    },
    {
      title: "Numero Fattura/ Documento",
      dataIndex: "billNumber",
      key: "billNumber",
      width: 100,
    },

    {
      title: "Imponibile/ Importo",
      dataIndex: "import",
      key: "import",
      width: 100,
    },
    {
      title: "Imposta",
      dataIndex: "tax",
      key: "tax",
      width: 100,
    },
    {
      title: "Totale Fattura",
      dataIndex: "totalBill",
      key: "totalBill",
      width: 100,
    },

    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render, isEdit) => {
        return (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsView(false);
              setIsNew(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
                issueDate: dayjs(render.issueDate, "DD/MM/YYYY"),
                receiptDate: dayjs(render.receiptDate, "DD/MM/YYYY"),
              });
              setOpen(true);
            }}
          />
        );
      },
      width: 40,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <DeleteTwoTone
            twoToneColor={"#990000"}
            onClick={() => {
              setDeleteId(render.id);
              setDeleteModal(true);
            }}
            style={{ fontSize: 18 }}
          />
        );
      },
      width: 40,
    },
  ];

  return columns;
}

export function getBillsFormFields(initialValues, isView, beneficiaries) {
  let fields = [
    {
      id: "businessNameBill",
      label: "Ragione Sociale",
      name: "businessNameBill",
      required: true,
      type: "select",
      dataForSelect: beneficiaries,
      controls: false,
      listHeight: 80,
    },
    {
      id: "idBeneficiary",
      label: "ID Fornitore",
      name: "idBeneficiary",
      required: false,
      type: "input",
      controls: false,
      disabled: true,
      listHeight: 80,
    },
    {
      id: "billNumber",
      label: "Numero Fattura/Documento",
      name: "billNumber",
      required: true,
      type: "input",
    },
    {
      id: "issueDate",
      label: "Data Emissione",
      name: "issueDate",
      type: "datePicker",
      controls: false,
      required: true,
    },
    {
      id: "import",
      label: "Imponibile/Importo",
      name: "import",
      required: true,
      controls: false,
      type: "inputNumber",
    },
    {
      id: "tax",
      label: "Imposta",
      name: "tax",
      required: true,
      controls: false,
      type: "inputNumber",
    },
    {
      id: "totalBill",
      label: "Totale Fattura",
      name: "totalBill",
      required: false,
      controls: false,
      type: "inputNumber",
      disabled: true,
    },
    {
      id: "receiptDate",
      label: "Data Ricezione",
      name: "receiptDate",
      type: "datePicker",
      controls: false,
      required: true,
    },
    { id: "id", name: "id", hidden: true, required: false },
  ];
  return fields;
}

export function searchBarBills() {
  let fields = [
    {
      id: "pickerMonth",
      label: "Seleziona Mese/Anno Data Emissione",
      name: "pickerMonth",
      required: false,
      format: "monthFormat",
      type: "datePicker",
      picker: "month",
      controls: false,
    },
    {
      id: "search",
      label: "Cerca",
      name: "search",
      type: "button",
    },
  ];
  return fields;
}
