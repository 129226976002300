/* eslint-disable */
import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";

export function getColumns(
  setOpen,
  setIsEdit,
  setIsView,
  setInitialValues,
  setDeleteModal,
  setDeleteId,
  setIsNew
) {
  let columns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "data",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Chiusura Fiscale",
      dataIndex: "chiusuraFiscale",
      key: "chiusuraFiscale",
      width: 100,
    },
    {
      title: "Cash",
      dataIndex: "cash",
      key: "cash",
      width: 100,
    },
    {
      title: "Fatture",
      dataIndex: "fatture",
      key: "fatture",
      width: 100,
    },
    {
      title: "Chiusura POS",
      dataIndex: "chiusuraPos",
      key: "chiusuraPos",
      width: 100,
    },
    {
      title: "The Fork",
      dataIndex: "theFork",
      key: "theFork",
      width: 100,
    },
    {
      title: "Tot. Uscite",
      dataIndex: "totaleUscite",
      key: "totaleUscite",
      width: 100,
    },
    {
      title: "Fondo cassa",
      dataIndex: "fondoCassa",
      key: "fondoCassa",
      width: 100,
    },
    {
      title: "Cash Busta",
      dataIndex: "cashBusta",
      key: "cashBusta",
      width: 100,
    },
    {
      title: "Scarico Busta",
      dataIndex: "scaricoBusta",
      key: "scaricoBusta",
      width: 100,
    },
    {
      title: "Tot. Busta",
      dataIndex: "totaleBusta",
      key: "totaleBusta",
      width: 100,
    },
    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return render.disabled ? (
          <EyeTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsView(true);
              setIsNew(false);
              setIsEdit(false);
              setInitialValues({
                ...render,
                outflows: render.outflows?.map((el) => {
                  return {
                    id: el.id,
                    tipologiaUscita: el.outflow_type?.descrizione
                      ? el.outflow_type.descrizione
                      : "",
                    uscita: el.importo,
                  };
                }),
                date: dayjs(render.date, "DD/MM/YYYY"),
              });
              setOpen(true);
            }}
          />
        ) : (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsView(false);
              setIsNew(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
                outflows: render.outflows?.map((el) => {
                  return {
                    id: el.id,
                    tipologiaUscita: el.outflow_type?.descrizione
                      ? el.outflow_type.descrizione
                      : "",
                    uscita: el.importo,
                  };
                }),
                date: dayjs(render.date, "DD/MM/YYYY"),
              });
              setOpen(true);
            }}
          />
        );
      },
      width: 40,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          !render.disabled && (
            <DeleteTwoTone
              twoToneColor={"#990000"}
              onClick={() => {
                setDeleteId(render.id);
                setDeleteModal(true);
              }}
              style={{ fontSize: 18 }}
            />
          )
        );
      },
      width: 40,
    },
  ];

  return columns;
}

export function getColumnsInnerTable() {
  let innerColumns = [
    {
      title: "Tipologia Uscita",
      dataIndex: "tipologiaUscita",
      key: "tipologiaUscita",
    },
    {
      title: "Uscita",
      dataIndex: "uscita",
      key: "uscita",
    },
  ];

  return innerColumns;
}

export function getIncomeFormFields(isView) {
  let fields = [
    {
      id: "date",
      label: "Data",
      name: "date",
      required: true,
      type: "datePicker",
      controls: false,
      disabled: isView,
    },
    {
      id: "cash",
      label: "Cash",
      name: "cash",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: isView,
    },
    {
      id: "theFork",
      label: "The Fork",
      name: "theFork",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: isView,
    },
    {
      id: "chiusuraPos",
      label: "Chiusura POS",
      name: "chiusuraPos",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: isView,
    },
    {
      id: "chiusuraFiscale",
      label: "Chiusura Fiscale",
      name: "chiusuraFiscale",
      required: false,
      type: "inputNumber",
      disabled: true,
      controls: false,
    },
    {
      id: "fatture",
      label: "Fatture",
      name: "fatture",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: isView,
    },
    {
      id: "totaleUscite",
      label: "Tot. Uscite",
      name: "totaleUscite",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: true,
    },
    {
      id: "fondoCassa",
      label: "Fondo cassa",
      name: "fondoCassa",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: isView,
    },
    {
      id: "fondoCassaGp",
      label: "Fondo cassa GP",
      name: "fondoCassaGp",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: true,
    },
    {
      id: "virtualTable",
      type: "virtualTable",
      pagination: false,
      columns: getColumnsInnerTable(),
    },
    {
      id: "list",
      type: "list",
      className: "colContainer",
      list: [
        {
          id: "cashBusta",
          label: "Cash in Busta",
          name: "cashBusta",
          required: false,
          type: "inputNumber",
          controls: false,
          disabled: true,
        },
        {
          id: "scaricoBusta",
          label: "Scarico Busta",
          name: "scaricoBusta",
          required: false,
          type: "inputNumber",
          controls: false,
          disabled: isView,
        },
        {
          id: "totaleBusta",
          label: "Tot. in Busta",
          name: "totaleBusta",
          required: false,
          type: "inputNumber",
          controls: false,
          disabled: true,
        },
      ],
    },
    { id: "id", name: "id" },
    {
      id: "totaleBustaGp",
      name: "totaleBustaGp",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: true,
      hidden: true,
    },
    {
      id: "outflows",
      name: "outflows",
      required: false,
      type: "inputNumber",
      controls: false,
      disabled: true,
      hidden: true,
    },
  ];

  return fields;
}

export function getIncomeFormFilters() {
  let fields = [
    {
      id: "dataRange",
      label: "Seleziona periodo Incasso",
      name: "dataRange",
      placeholder: ["Incasso da", "Incasso a"],
      required: false,
      showTime: false,
      type: "rangeDatePicker",
    },
    {
      id: "meseRadioGroup",
      label: "",
      name: "meseRadioGroup",
      required: false,
      type: "radioGroup",
      group: [
        { value: "meseCorr", label: "Mese Corrente" },
        { value: "mesePrec", label: "Mese Precedente" },
      ],
    },
    {
      id: "search",
      label: "Cerca",
      name: "search",
      type: "button",
    },
  ];

  return fields;
}
