import { Col, Form, Row, Button } from "antd";
import React, { useState, useEffect } from "react";
import FormInModal from "../../components/FormInModal/FormInModal";
import TableCustom from "../../components/Table/TableCustom";
import FormCustom from "../../components/FormCustom/FormCustom";
import ModalCustom from "../../components/Modal/ModalCustom";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import {
  getColumns,
  getBeneficiaryTypeFormFields,
  getBeneficiaryTypeFormFilters,
} from "./GestioneTipoFornitoreConfig";
import styles from "./GestioneTipoFornitorePage.module.scss";
import { GestioneTipoFornitoreService } from "./GestioneTipoFornitoreService";
import openNotification from "./../../components/Notification/Notification";

const getBeneficiariesTypesParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

export default function GestioneTipoFornitorePage() {
  const [form] = Form.useForm();
  const { height, width } = useWindowDimensions();
  const [defaultValues, setDefaultValues] = useState({
    description: "",
    supplierId: "",
  });

  const [defaultSearchValues, setDefaultSearchValues] = useState({
    description: "",
    supplierId: "",
  });

  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: ["description"],
    order: "asc",
    filters: {},
  });

  const [dataSource, setDataSource] = useState([]);
  /* END OF TABLE CONFIG */

  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  /* END MODAL TRIGGERS */

  /* START MODAL FORM HANDLER SECTION */
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  /* END MODAL FORM HANDLER SECTION */

  /* START TABLE SECTION */
  const readData = () => {
    GestioneTipoFornitoreService.getBeneficiariesTypes(
      getBeneficiariesTypesParams(tableParams).page,
      getBeneficiariesTypesParams(tableParams).pageSize,
      getBeneficiariesTypesParams(tableParams).field +
        ":" +
        getBeneficiariesTypesParams(tableParams).order,
      getBeneficiariesTypesParams(tableParams).filters
    ).then((data) => {
      setDataSource(
        data.data.map((el) => {
          return {
            id: el.id,
            description: el.description,
            supplierId: el.supplierId,
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    readData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    let sort = {
      ...sorter,
      field: sorter.field ? sorter.field : "description",
      order: sorter?.order
        ? sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : ""
        : "desc",
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
        ...sort,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  const dataToExcel = (data) => {
    let list = [...data];
    let listToSend = [];

    list.forEach((el) => {
      listToSend.push({
        ["Tipo Fornitore"]: el.description,
        ["ID Fornitore"]: el.supplierId,
      });
    });

    return listToSend;
  };

  /* START OF BENEFICIARY TYPE SECTION */

  const onBeneficiaryTypeSubmit = (values) => {
    setLoading(true);

    let objToSend = {
      data: {
        description: values.description,
        supplierId: values.supplierId,
      },
    };

    if (isNew) {
      GestioneTipoFornitoreService.createBeneficiaryType(objToSend)
        .then(() => {
          readData();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    } else if (isEdit) {
      GestioneTipoFornitoreService.updateBeneficiaryType(
        parseInt(values.id),
        objToSend.data
      )
        .then((res) => {
          readData();
          openNotification(
            "Successo",
            "La modifica è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
    setOpen(false);
  };

  const onBeneficiaryTypeDelete = (id) => {
    setLoading(true);
    GestioneTipoFornitoreService.deleteBeneficiaryType(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });

    setDeleteModal(false);
  };
  /* END BENEFICIARY TYPE SECTION */

  /* START SEARCH SECTION */
  const onBeneficiaryTypeSearch = (values) => {
    let filtersToSend = {
      or: [
        { description: { containsi: values.beneficiaryTypeSearch } },
        { supplierId: { containsi: values.beneficiaryTypeSearch } },
      ],
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        filters: filtersToSend,
      };
    });
    delete values.search;
    setDefaultSearchValues(values);
  };
  /* END SEARCH SECTION */

  return (
    <>
      <Row>
        <Col span={24}>
          {/* START TABLE SECTION */}
          <Row>
            <FormCustom
              form={form}
              currentRow={defaultSearchValues}
              className={styles.suppliersSearchBar}
              id={"beneficiaryTypeSearch"}
              name={"beneficiaryTypeSearch"}
              allowClear
              fields={getBeneficiaryTypeFormFilters()}
              isSearch={true}
              onFinish={onBeneficiaryTypeSearch}
              style={{
                width: 200,
              }}
            />
          </Row>
          <Row>
            <Col span={24}>
              <TableCustom
                excelConfig={{
                  excel: true,
                  excelText: "Esporta",
                  dataToExport: dataToExcel(dataSource),
                  fileName: "Gestione Tipo Fornitori",
                  colSize: 16,
                }}
                loading={loading}
                className={styles.table}
                size={width <= 1300 ? "small" : "large"}
                scroll={{
                  x: 500,
                }}
                columns={getColumns(
                  setOpen,
                  setIsEdit,
                  setInitialValues,
                  setDeleteModal,
                  setDeleteId,
                  readData,
                  setIsNew
                )}
                dataSource={dataSource}
                title={() => (
                  <>
                    <span style={{ fontWeight: "bold" }}>
                      {"Elenco Tipo Fornitori".toLocaleUpperCase()}
                    </span>
                  </>
                )}
                onChange={handleTableChange}
                pagination={tableParams.pagination}
                showPagination={true}
                defaultCurrent={3}
                total={500}
                pageSizeOptions={["5", "10", "20", "50"]}
                showSizeChanger={true}
              />
            </Col>
          </Row>
          {/* END TABLE SECTION */}
          {/* START NEW/EDIT RECORD SECTION */}
          <Row>
            <Col span={4}>
              <Button
                className={styles.newEntry}
                type="primary"
                onClick={() => {
                  setIsNew(true);
                  setIsEdit(false);
                  setOpen(true);
                }}
              >
                {"Nuovo Tipo Fornitore"}
              </Button>
            </Col>
          </Row>

          {/* START OF SUPPLIERS FORM */}
          <FormInModal
            isSearch={false}
            dataSource={dataSource}
            defaultValues={defaultValues}
            getFormFields={getBeneficiaryTypeFormFields(
              isSelect,
              isHidden,
              isEdit,
              initialValues,
              onBeneficiaryTypeSubmit
            )}
            modalStyle={isEdit ? styles.modalEditStyle : styles.modalStyle}
            className={styles.suppliersForm}
            isEdit={isEdit}
            initialValues={initialValues}
            title={
              isEdit ? "Modifica Tipo Fornitore" : "Creazione Tipo Fornitore"
            }
            okText={isEdit ? "Salva Modifiche" : "Crea Nuovo Tipo Fornitore"}
            cancelText={"Annulla"}
            width={600}
            open={open}
            centered={false}
            onSubmit={onBeneficiaryTypeSubmit}
            onCancel={() => {
              setOpen(false);
              setIsHidden(true);
              setIsSelect(false);
            }}
          />
          {/* END OF BENEFICIARIES FORM */}

          <ModalCustom
            centered={true}
            title={"Cancella Tipo Fornitore"}
            open={deleteModal}
            okText={"Conferma"}
            cancelText={"Annulla"}
            width={500}
            handleCancel={() => {
              setDeleteModal(false);
            }}
            handleOk={() => {
              onBeneficiaryTypeDelete(deleteId);
            }}
          >
            <Row className={styles.textContainer}>
              <Col className={styles.text} span={18}>
                {"Sei sicuro di voler cancellare questo tipo di fornitore?"}
              </Col>
            </Row>
          </ModalCustom>
        </Col>
      </Row>
    </>
  );
}
