import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSignedIn: false,
  isAdmin: false,
  isAdministrative: false,
  token: null,
  id: "",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUserType: (state, action) => {
      state.id = action.payload?.id;
    },
    setAuth: (state, action) => {
      if (action.payload.token !== "" && action.payload.token !== null) {
        state.isSignedIn = true;
      } else {
        state.isSignedIn = false;
      }
    },
    setAdmin: (state, action) => {
      state.isAdmin = action.payload?.isAdmin;
    },
    setAdministrative: (state, action) => {
      state.isAdministrative = action.payload?.isAdministrative;
    },
  },
});

export const { setAuth, setAdmin, setAdministrative, setUserType } =
  loginSlice.actions;

export default loginSlice.reducer;
