import React from "react";
import { Layout } from "antd";
import styles from "./Sidebar.module.scss"

const SideBar = (props) => {
  return (
    <Layout.Sider
      className={styles.sidebar}
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      trigger={null}
    >
      {props.menu}
   </Layout.Sider>
   );
};
export default SideBar;