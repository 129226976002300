import { gql } from "@apollo/client";

export const GET_EVENTS = gql`
  query getEvents(
    $pagination: PaginationArg
    $sort: [String]
    $filters: EventFiltersInput
  ) {
    events(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          description
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
        }
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent($data: EventInput!) {
    createEvent(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      data {
        id
      }
    }
  }
`;
