import { gql } from "@apollo/client";
/* START OF GET REQUEST */
export const GET_MAX_DATE = gql`
  query getMaxDate($pagination: PaginationArg, $sort: [String]) {
    incomes(pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          date
        }
      }
      meta {
        pagination {
          page
          pageSize
        }
      }
    }
  }
`;

export const GET_INCOMES = gql`
  query getIncomes(
    $pagination: PaginationArg
    $sort: [String]
    $filters: IncomeFiltersInput
  ) {
    incomes(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          date
          chiusuraFiscale
          cash
          fatture
          chiusuraPos
          totaleUscite
          fondoCassa
          cashBusta
          scaricoBusta
          totaleBusta
          fondoCassaGp
          totaleBustaGp
          theFork
          outflows {
            data {
              id
              attributes {
                dataUscita
                descrizioneUscita
                importo
                outflow_type {
                  data {
                    id
                    attributes {
                      descrizione
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

export const GET_REL_OUTFLOWS = gql`
  query getOutflows($filters: OutflowFiltersInput) {
    outflows(filters: $filters) {
      data {
        id
        attributes {
          dataUscita
          importo
          outflow_type {
            data {
              id
              attributes {
                descrizione
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SINGLE_INCOME = gql`
  query getSingleIncome($id: ID!) {
    income(id: $id) {
      data {
        id
        attributes {
          outflows {
            data {
              id
              attributes {
                importo
                outflow_type {
                  data {
                    id
                    attributes {
                      descrizione
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
/* END OF GET REQUEST */

/* START OF MUTATION */
export const CREATE_INCOME = gql`
  mutation CreateIncome($data: IncomeInput!) {
    createIncome(data: $data) {
      data {
        id
        attributes {
          date
          chiusuraFiscale
          cash
          fatture
          chiusuraPos
          totaleUscite
          fondoCassa
          cashBusta
          scaricoBusta
          totaleBusta
          theFork
          outflows {
            data {
              id
              attributes {
                dataUscita
                descrizioneUscita
                importo
                outflow_type {
                  data {
                    id
                    attributes {
                      descrizione
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const EDIT_INCOME = gql`
  mutation updateIncome($id: ID!, $data: IncomeInput!) {
    updateIncome(id: $id, data: $data) {
      data {
        id
        attributes {
          date
        }
      }
    }
  }
`;

export const DELETE_INCOME = gql`
  mutation deleteIncome($id: ID!) {
    deleteIncome(id: $id) {
      data {
        id
      }
    }
  }
`;

/* END OF MUTATION */
