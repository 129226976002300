import { EditTwoTone, EyeTwoTone, DeleteTwoTone } from "@ant-design/icons";

export function getColumns(
  setOpen,
  setIsEdit,
  setInitialValues,
  setDeleteModal,
  setDeleteId,
  readData,
  setIsNew
) {
  let columns = [
    {
      title: "Ragione Sociale",
      dataIndex: "businessName",
      key: "businessName",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Partita IVA",
      dataIndex: "ivaNumber",
      key: "ivaNumber",
      width: 100,
    },
    {
      title: "Tipo Fornitore",
      dataIndex: "description",
      key: "description",
      width: 100,
    },
    {
      title: "ID Fornitore",
      dataIndex: "supplierId",
      key: "supplierID",
      width: 100,
    },
    {
      title: "Telefono",
      dataIndex: "telephone",
      key: "telephone",
      width: 100,
      render: (text, render) => {
        if (render.prefix && render.telephone)
          return <span>{render.prefix + " " + render.telephone}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 100,
    },
    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsNew(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
                beneficiaryTypeInfo: render.beneficiaryType.description,
              });
              setOpen(true);
            }}
          />
        );
      },
      width: 40,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <DeleteTwoTone
            twoToneColor={"#990000"}
            onClick={() => {
              setDeleteId(render.id);
              setDeleteModal(true);
            }}
            style={{ fontSize: 18 }}
          />
        );
      },
      width: 40,
    },
  ];

  return columns;
}

export function getBeneficiaryFormFields(
  beneficiaryType,
) {
  let fields = [
    {
      id: "businessName",
      label: "Ragione Sociale",
      name: "businessName",
      required: true,
      type: "input",
      controls: false,
    },

    {
      id: "ivaNumber",
      label: "Partita IVA",
      name: "ivaNumber",
      required: false,
      type: "input",
    },
    {
      id: "telephone",
      label: "Telefono",
      name: "telephone",
      required: false,
      type: "inputNumber",
      controls: false,
      isAddOnBefore: true,
      addonBefore: {
        id: "prefix",
        name: "prefix",
        required: false,
        type: "select",
        controls: false,
      },
    },
    {
      id: "email",
      label: "Email",
      name: "email",
      required: false,
      type: "input",
    },
    {
      id: "beneficiaryTypeInfo",
      label: "Tipo Fornitore",
      name: "beneficiaryTypeInfo",
      required: true,
      type: "select",
      listHeight: 385,
      autoClearSearchValue: false,
      filterOption: false,
      placeholder: "Seleziona Tipo Fornitore",
      dataForSelect: beneficiaryType.map((el) => {
        return el.description;
      }),
    },
    {
      id: "supplierId",
      label: "ID Fornitore",
      name: "supplierId",
      required: false,
      type: "input",
      disabled: true,
    },
    { id: "id", name: "id", hidden: true, required: false },
  ];

  return fields;
}

export function getBeneficiariesFormFilters(
  beneficiaryTypes,
) {
  let fields = [
    {
      id: "businessName",
      label: "Ragione Sociale",
      name: "businessName",
      required: false,
      type: "input",
      controls: false,
      allowClear: true,
      placeholder: "Ragione Sociale",
    },
    {
      id: "ivaNumber",
      label: "Partita IVA",
      name: "ivaNumber",
      required: false,
      type: "input",
      controls: false,
      allowClear: true,
      placeholder: "Partita IVA",
    },
    {
      id: "beneficiaryType",
      label: "Tipo di Fornitore",
      name: "beneficiaryType",
      required: false,
      type: "select",
      dataForSelect: beneficiaryTypes,
      controls: false,
      listHeight: 80,
      dropdownRender: (menu) => {
        return <>{menu}</>;
      },
    },
    {
      id: "search",
      label: "Cerca",
      name: "search",
      type: "button",
    },
  ];
  return fields;
}
