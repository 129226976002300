/* eslint-disable */
import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";
import CustomDropDown from "../../components/SelectMenu/CustomDropDown";

export function getColumns(
  setOpen,
  setIsEdit,
  setIsView,
  setInitialValues,
  setDeleteModal,
  setDeleteId,
  setIsNew
) {
  let columns = [
    {
      title: "Data Assegno",
      dataIndex: "dateCheck",
      key: "dateCheck",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Importo",
      dataIndex: "import",
      key: "import",
      width: 100,
    },
    {
      title: "Beneficiario",
      dataIndex: "beneficiary",
      key: "beneficiary",
      width: 100,
    },
    {
      title: "Banca Appoggio",
      dataIndex: "bank",
      key: "bank",
      width: 100,
    },
    {
      title: "Pagato",
      dataIndex: "paid",
      key: "paid",
      width: 100,
    },
    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return render.disabled ? (
          <EyeTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsNew(false);
              setIsView(true);
              setIsEdit(false);
              setInitialValues({
                ...render,
                paid: render.paid === "SI" ? true : false,
                dateCheck: dayjs(render.dateCheck),
              });
              setOpen(true);
            }}
          />
        ) : (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsNew(false);
              setIsView(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
                paid: render.paid === "SI" ? true : false,
                dateCheck: dayjs(render.dateCheck),
              });
              setOpen(true);
            }}
          />
        );
      },
      width: 40,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          !render.disabled && (
            <DeleteTwoTone
              twoToneColor={"#990000"}
              onClick={() => {
                setDeleteId(render.id);
                setDeleteModal(true);
              }}
              style={{ fontSize: 18 }}
            />
          )
        );
      },
      width: 40,
    },
  ];

  return columns;
}

export function getChecksFormFields(
  isView,
  banks,
  beneficiaries,
  handleDeleteBank,
  onBankSubmit
) {
  let fields = [
    {
      id: "dateCheck",
      label: "Data Incasso",
      name: "dateCheck",
      required: true,
      type: "datePicker",
      controls: false,
      disabled: isView,
    },
    {
      id: "checkNumber",
      label: "Numero Assegno",
      name: "checkNumber",
      required: true,
      type: "input",
      controls: false,
      disabled: isView,
    },
    {
      id: "import",
      label: "Importo Assegno",
      name: "import",
      required: true,
      type: "inputNumber",
      controls: false,
      disabled: isView,
    },
    {
      id: "paid",
      label: "Pagato",
      name: "paid",
      required: true,
      type: "checkbox",
      controls: false,
      disabled: isView,
    },
    {
      id: "beneficiary",
      label: "Beneficiario",
      name: "beneficiary",
      required: false,
      type: "select",
      dataForSelect: beneficiaries,
      controls: false,
      disabled: isView,
      listHeight: 80,
    },
    {
      id: "bank",
      label: "Banca",
      name: "bank",
      required: true,
      type: "select",
      dataForSelect: banks,
      controls: false,
      disabled: isView,
      listHeight: 80,
      dropdownRender: (menu) => {
        return (
          <>
            {menu}
            <CustomDropDown
              showDelete={true}
              showInput={true}
              showAdd={true}
              placeholder={"Inserire la Banca"}
              onDelete={handleDeleteBank}
              onSubmit={onBankSubmit}
              addText={"Aggiungi"}
              cancelText={"Cancella"}
            />
          </>
        );
      },
    },
    { id: "id", name: "id" },
  ];

  return fields;
}

export function getChecksFormFilters(banks, beneficiaries) {
  let fields = [
    {
      id: "dataRange",
      label: "Seleziona periodo Incasso",
      name: "dataRange",
      placeholder: ["Incasso da", "Incasso a"],
      required: false,
      showTime: false,
      type: "rangeDatePicker",
    },
    {
      id: "meseRadioGroup",
      label: "",
      name: "meseRadioGroup",
      required: false,
      type: "radioGroup",
      group: [
        { value: "meseCorr", label: "Mese Corrente" },
        { value: "mesePrec", label: "Mese Precedente" },
      ],
    },
    {
      id: "bank",
      label: "Banca",
      name: "bank",
      required: false,
      type: "select",
      dataForSelect: banks,
      controls: false,
      disabled: false,
    },
    {
      id: "beneficiary",
      label: "Beneficiario",
      name: "beneficiary",
      required: false,
      type: "select",
      dataForSelect: beneficiaries,
      controls: false,
      disabled: false,
    },
    {
      id: "search",
      label: "Cerca",
      name: "search",
      type: "button",
    },
  ];

  return fields;
}
