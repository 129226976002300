import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";
import CustomDropDown from "../../components/SelectMenu/CustomDropDown";

export function getColumns(
  setOpen,
  setIsEdit,
  setIsView,
  setInitialValues,
  setIsNew,
  setDeleteId,
  setDeleteModal
) {
  let columns = [
    {
      title: "Cliente",
      dataIndex: "lastName",
      key: "lastName",
      sorter: true,
      showSorterTooltip: false,
      render: (text, render) => {
        if (render.name && render.lastName)
          return <span>{render.name + " " + render.lastName}</span>;
        else if (!render.name) return <span>{render.lastName}</span>;
        else if (!render.lastName) return <span>{render.name}</span>;
      },
    },
    {
      title: "Telefono",
      dataIndex: "telephone",
      key: "telephone",
      render: (text, render) => {
        if (render.prefix && render.telephone)
          return <span>{render.prefix + " " + render.telephone}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "",
      key: "view",
      align: "center",
      fixed: "right",
      render: (text, render, isEdit) => {
        return (
          <EyeTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsView(true);
              setIsNew(false);
              setIsEdit(false);
              setInitialValues({
                ...render,
              });
              setOpen(true);
            }}
          />
        );
      },
    },
    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render, isEdit) => {
        return (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsView(false);
              setIsNew(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
              });
              setOpen(true);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <DeleteTwoTone
            twoToneColor={"#990000"}
            onClick={() => {
              setDeleteId(render.id);
              setDeleteModal(true);
            }}
            style={{ fontSize: 18 }}
          />
        );
      },
    },
  ];

  return columns;
}

export function getClientsFormFields(
  configObj
) {
  let fields = [
    {
      id: "name",
      label: "Nome",
      name: "name",
      required: true,
      type: "input",
      disabled: configObj.isView,
    },
    {
      id: "lastName",
      label: "Cognome",
      name: "lastName",
      required: true,
      type: "input",
      disabled: configObj.isView,
    },
    {
      id: "telephone",
      label: "Telefono",
      name: "telephone",
      required: true,
      type: "inputNumber",
      controls: false,
      isAddOnBefore: true,
      disabled: configObj.isView,
      addonBefore: {
        id: "prefix",
        name: "prefix",
        required: false,
        type: "select",
        controls: false,
        disabled: configObj.isView,
      },
    },
    {
      id: "email",
      label: "Email",
      name: "email",
      required: false,
      type: "input",
      disabled: configObj.isView,
    },
    {
      id: "clientNote",
      label: "Note",
      name: "clientNote",
      required: false,
      type: "textArea",
      disabled: configObj.isView,
    },
    {
      id: "allergies",
      label: "Allergie e Intolleranze",
      name: "allergies",
      required: false,
      type: "select",
      mode: "multiple",
      disabled: configObj.isView,
      dataForSelect: configObj.allergies,
      controls: false,
      listHeight: 80,
      dropdownRender: (menu) => {
        return (
          <>
            {menu}
            <CustomDropDown
              isEnd={true}
              showDelete={true}
              showInput={true}
              showAdd={true}
              placeholder={"Inserire la Tipologia"}
              onDelete={configObj.handleDeleteAllergy}
              onSubmit={configObj.onAllergySubmit}
            />
          </>
        );
      },
    },
    {
      id: "dietaryNeeds",
      label: "Esigenze alimentari specifiche",
      name: "dietaryNeeds",
      required: false,
      type: "select",
      mode: "multiple",
      controls: false,
      disabled: configObj.isView,
      dataForSelect: configObj.dietaryNeeds,
      listHeight: 80,
      dropdownRender: (menu) => {
        return (
          <>
            {menu}
            <CustomDropDown
              isEnd={true}
              showDelete={true}
              showInput={true}
              showAdd={true}
              placeholder={"Inserire le esigenze alimentari"}
              onDelete={configObj.handleDeleteDietary}
              onSubmit={configObj.onDietarySubmit}
            />
          </>
        );
      },
    },

    { id: "id", name: "id", hidden: true, required: false },
  ];

  return fields;
}

export function searchBarClientsFields() {
  let fields = [
    {
      id: "clientSearchInfo",
      label: "Cerca Cliente",
      name: "clientSearchInfo",
      required: false,
      type: "input",
      controls: false,
      placeholder: "Nome, cognome, numero di telefono, email...",
      allowClear: true,
    },
    {
      id: "search",
      label: "Cerca",
      name: "search",
      type: "button",
    },
  ];
  return fields;
}
