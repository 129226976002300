import { gql } from "@apollo/client";

export const GET_BANKS = gql`
  query getBanks($pagination: PaginationArg, $sort: [String]) {
    banks(pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          description
        }
      }
      meta {
        pagination {
          pageSize
        }
      }
    }
  }
`;

export const CREATE_BANK = gql`
  mutation createBank($data: BankInput!) {
    createBank(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_BANK = gql`
  mutation deleteBank($id: ID!) {
    deleteBank(id: $id) {
      data {
        id
      }
    }
  }
`;
