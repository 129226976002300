import { ApolloRepo } from "../../utility/graphql/Client";
import {
  CREATE_INCOME,
  DELETE_INCOME,
  EDIT_INCOME,
  GET_INCOMES,
  GET_MAX_DATE,
  GET_REL_OUTFLOWS,
  GET_SINGLE_INCOME,
} from "../../utility/graphql/IncomesQueries";
import { strapiAdapter } from "../../utility/methods/methods";

class GestioneIncassiServiceC {
  getMaxDate() {
    return ApolloRepo.client
      .query({
        query: GET_MAX_DATE,
        variables: {
          pagination: {
            page: 1,
            pageSize: 1,
          },
          sort: "date:desc",
        },
      })
      .then((res) => {
        return { data: strapiAdapter(res.data.incomes) };
      });
  }

  getIncomes(page, pageSize, sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_INCOMES,
        variables: {
          pagination: { page: page, pageSize: pageSize },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.incomes),
          meta: res.data.incomes.meta,
        };
      });
  }

  getRelationOutflows(filters) {
    return ApolloRepo.client
      .query({
        query: GET_REL_OUTFLOWS,
        variables: {
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.outflows),
        };
      });
  }

  getSingleIncome(id) {
    if (id) {
      return ApolloRepo.client
        .query({
          query: GET_SINGLE_INCOME,
          variables: { id: id },
        })
        .then((res) => {
          return strapiAdapter(res.data.income);
        });
    }
  }

  createIncome(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_INCOME,
      variables: data,
    });
  }

  deleteIncome(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_INCOME,
      variables: { id: id },
    });
  }

  updateIncome(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_INCOME,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateIncome);
      });
  }
}

export const GestioneIncassiService = new GestioneIncassiServiceC();
