import { ApolloRepo } from "../../utility/graphql/Client";
import {
  CREATE_OUTFLOW,
  DELETE_OUTFLOW,
  EDIT_OUTFLOW,
  GET_OUTFLOWS,
  GET_SINGLE_OUTFLOW,
} from "../../utility/graphql/OutflowsQueries";
import {
  CREATE_OUTFLOWS_TYPE,
  DELETE_OUTFLOW_TYPE,
  GET_OUTFLOWS_TYPES,
} from "../../utility/graphql/OutflowsTypesQueries";
import { strapiAdapter } from "../../utility/methods/methods";
import { GET_MAX_DATE } from "./../../utility/graphql/OutflowsQueries";

class GestioneUsciteServiceC {
  getMaxDate() {
    return ApolloRepo.client
      .query({
        query: GET_MAX_DATE,
        variables: {
          pagination: {
            page: 1,
            pageSize: 1,
          },
          sort: "dataUscita:desc",
        },
      })
      .then((res) => {
        return { data: strapiAdapter(res.data.outflows) };
      });
  }

  getOutflows(page, pageSize, sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_OUTFLOWS,
        variables: {
          pagination: { page: page, pageSize: pageSize },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.outflows),
          meta: res.data.outflows.meta,
        };
      });
  }

  getSingleOutFlow(id) {
    if (id) {
      return ApolloRepo.client
        .query({
          query: GET_SINGLE_OUTFLOW,
          variables: { id: id },
        })
        .then((res) => {
          return strapiAdapter(res.data.outflow);
        });
    }
  }

  getOutflowsTypes() {
    return ApolloRepo.client
      .query({
        query: GET_OUTFLOWS_TYPES,
        variables: {
          pagination: { limit: -1 },
          sort: "descrizione:asc",
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.outflowTypes),
        };
      });
  }

  createOutflow(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_OUTFLOW,
      variables: data,
    });
  }

  createTypo(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_OUTFLOWS_TYPE,
      variables: data,
    });
  }

  deleteOutflowType(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_OUTFLOW_TYPE,
      variables: { id: id },
    });
  }

  deleteOutflow(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_OUTFLOW,
      variables: { id: id },
    });
  }

  updateOutflow(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_OUTFLOW,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateOutflow);
      });
  }
}

export const GestioneUsciteService = new GestioneUsciteServiceC();
