import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultValues: {
    cash: 0,
    cashBusta: 0,
    chiusuraFiscale: 0,
    chiusuraPos: 0,
    fatture: 0,
    fondoCassa: 0,
    outflows: [],
    scaricoBusta: 0,
    totaleBusta: 0,
    totaleUscite: 0,
    fondoCassaGp: 0,
    totaleBustaGp: 0,
    theFork: 0,
  },
};

export const gestioneIncassiSlice = createSlice({
  name: "gestioneIncassi",
  initialState,
  reducers: {
    setDayBefore: (state, action) => {
      state.defaultValues = {
        ...state.defaultValues,
        fondoCassaGp: action.payload.fondoCassa,
        totaleBustaGp: action.payload.totaleBusta,
      };
    },
    setOutflows: (state, action) => {
      state.defaultValues = {
        ...state.defaultValues,
        outflows: action.payload,
      };
    },
  },
});

export const { setDayBefore, setOutflows } = gestioneIncassiSlice.actions;

export default gestioneIncassiSlice.reducer;
