import { ApolloRepo } from "../../utility/graphql/Client";
import {
  GET_CLIENTS,
  CREATE_CLIENT,
  EDIT_CLIENT,
  GET_SINGLE_CLIENT,
  DELETE_CLIENT,
} from "../../utility/graphql/ClientQueries";
import {
  CREATE_ALLERGY,
  DELETE_ALLERGY,
  GET_ALLERGIES,
} from "../../utility/graphql/AllergiesQueries";
import {
  CREATE_DIETARY,
  DELETE_DIETARY,
  GET_DIETARY_NEED,
} from "../../utility/graphql/DietaryNeedQueries";
import { strapiAdapter } from "../../utility/methods/methods";

class GestioneClientiServiceC {
  /* START OF CLIENT SECTION */

  getClients(page, pageSize, sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_CLIENTS,
        variables: {
          pagination: { page: page, pageSize: pageSize },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.clients),
          meta: res.data.clients.meta,
        };
      });
  }

  getSingleClient(id) {
    if (id) {
      return ApolloRepo.client
        .query({
          query: GET_SINGLE_CLIENT,
          variables: { id: id },
        })
        .then((res) => {
          return strapiAdapter(res.data.client);
        });
    }
  }

  createClient(data) {
    return ApolloRepo.client
      .mutate({
        mutation: CREATE_CLIENT,
        variables: data,
      })
      .then((res) => {
        return {
          id: res.data?.createClient?.data?.id,
        };
      });
  }

  updateClient(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_CLIENT,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateClient);
      });
  }

  deleteClient(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_CLIENT,
      variables: { id: id },
    });
  }

  /* START OF ALLERGIES SECTION */

  getAllergies(sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_ALLERGIES,
        variables: {
          pagination: { limit: -1 },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.allergies),
          meta: res.data.allergies.meta,
        };
      });
  }

  createAllergy(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_ALLERGY,
      variables: data,
    });
  }

  deleteAllergy(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_ALLERGY,
      variables: { id: id },
    });
  }

  /* END OF ALLERGIES SECTION */
  /* START OF ALLERGIES SECTION */

  getDietaryNeed(sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_DIETARY_NEED,
        variables: {
          pagination: { limit: -1 },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.dietaryNeeds),
          meta: res.data.dietaryNeeds.meta,
        };
      });
  }

  createDietary(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_DIETARY,
      variables: data,
    });
  }

  deleteDietary(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_DIETARY,
      variables: { id: id },
    });
  }

  /* END OF ALLERGIES SECTION */
}

export const GestioneClientiService = new GestioneClientiServiceC();