import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
  query getClients(
    $pagination: PaginationArg
    $sort: [String]
    $filters: ClientFiltersInput
  ) {
    clients(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          name
          lastName
          telephone
          email
          allergies {
            data {
              id
              attributes {
                description
              }
            }
          }
          dietary_needs {
            data {
              id
              attributes {
                description
              }
            }
          }
          clientNote
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
        }
      }
    }
  }
`;

export const GET_SINGLE_CLIENT = gql`
  query getSingleClient($id: ID!) {
    client(id: $id) {
      data {
        id
        attributes {
          name
          lastName
          telephone
          email
          allergies {
            data {
              id
              attributes {
                description
              }
            }
          }
          dietary_needs {
            data {
              id
              attributes {
                description
              }
            }
          }
          clientNote
        }
      }
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient($data: ClientInput!) {
    createClient(data: $data) {
      data {
        id
      }
    }
  }
`;

export const EDIT_CLIENT = gql`
  mutation updateClient($id: ID!, $data: ClientInput!) {
    updateClient(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: ID!) {
    deleteClient(id: $id) {
      data {
        id
      }
    }
  }
`;

