/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Col, Row, Form, Spin } from "antd";
import styles from "./Homepage.module.scss";
import FormCustom from "../../components/FormCustom/FormCustom";
import { getColumns, getHomeForm } from "./HomepageConfig";
import { HomepageService } from "./HomepageService";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import TableCustom from "../../components/Table/TableCustom";
import { GestioneAssegniService } from "../GestioneAssegniPage/GestioneAssegniService";

const getChecksParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

export default function HomePage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const credential = useSelector((state) => state.login);
  const [initialValues, setInitialValues] = useState({
    annoCorrente: 0,
    totaleBusta: 0,
    meseCorrente: 0,
    mesePrecedente: 0,
  });
  const [dataSource, setDataSource] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: "date",
    order: "desc",
    filters: {},
  });

  function getMonthElements(array) {
    const currentMonth = new Date().getMonth();
    const filteredArray = array.filter(
      (item) => new Date(item.date).getMonth() === currentMonth
    );
    const sumCurrentMonth = filteredArray.reduce(
      (acc, item) => acc + item.chiusuraFiscale,
      0
    );

    const previousMonth = (currentMonth + 11) % 12;
    const filteredArrayPreviousMonth = array.filter(
      (item) => new Date(item.date).getMonth() === previousMonth
    );
    const sumPreviousMonth = filteredArrayPreviousMonth.reduce(
      (acc, item) => acc + item.chiusuraFiscale,
      0
    );

    return { sumCurrentMonth, sumPreviousMonth };
  }

  function getRecentElement(array) {
    const sortedArray = array.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const recentElement = sortedArray[0];
    return recentElement ? recentElement.totaleBusta : 0;
  }

  const readData = () => {
    setLoading(true);
    HomepageService.getYearIncome(1, 1000, {
      and: [
        {
          date: {
            gte: dayjs(dayjs()).startOf("year").format("YYYY-MM-DD"),
          },
        },
        {
          date: {
            lte: dayjs(dayjs()).endOf("year").format("YYYY-MM-DD"),
          },
        },
      ],
    }).then((res) => {
      if (res.data) {
        setInitialValues({
          annoCorrente: res.data.reduce(
            (acc, result) => acc + result.chiusuraFiscale,
            0
          ),
          meseCorrente: getMonthElements(res.data).sumCurrentMonth,
          mesePrecedente: getMonthElements(res.data).sumPreviousMonth,
          totaleBusta: getRecentElement(res.data),
        });
      }
    });
  };

  const readChecksData = () => {
    GestioneAssegniService.getChecks(
      getChecksParams(tableParams).page,
      getChecksParams(tableParams).pageSize,
      "dateCheck:desc",
      {
        paid: { eq: false },
      }
    ).then((data) => {
      setDataSource(
        data.data.map((el) => {
          return {
            id: el.id,
            import: parseFloat(el.import.toFixed(2)),
            bank: el.bank?.description,
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  }

  useEffect(() => {
    readData();
    readChecksData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination) => {
    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  return (
    <>
      {/* {true && (
        <div className={styles.indicator}>
          <Spin indicator={antIcon} />
        </div>
      )} */}
      <Row className={styles.homePageContainer}>
        {credential.isAdmin ? (
          <Col span={24}>
            {/* FORM SECTION */}
            <Row>
              <Col span={24}>
                <div className={styles.formContainer}>
                  <FormCustom
                    isInfo={true}
                    form={form}
                    currentRow={initialValues}
                    className={styles.form}
                    id={"homeForm"}
                    name={"homeForm"}
                    fields={getHomeForm()}
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col span={10}>
                <TableCustom
                  loading={loading}
                  className={styles.table}
                  size={"large"}
                  columns={getColumns()}
                  dataSource={dataSource}
                  title={() => (
                    <span style={{ fontWeight: "bold" }}>
                      {"Assegni da pagare".toLocaleUpperCase()}
                    </span>
                  )}
                  onChange={handleTableChange}
                  pagination={tableParams.pagination}
                  showPagination={true}
                />
              </Col>
            </Row>

            {/* LOGO SECTION */}
            {/* <Row className={styles.imgContainer} justify={"center"}>
            <Col>
              <img src="https://rione24.it/wp-content/uploads/2022/04/logo-rione-193x300.png"></img>
            </Col>
          </Row> */}
          </Col>
        ) : null}
      </Row>
    </>
  );
}
