import { gql } from "@apollo/client";

/* START OF MUTATION */
export const LOGIN = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        username
        id
        role {
          id
        }
      }
    }
  }
`;

/* END OF MUTATION */
