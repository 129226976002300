import { Input, Button, Divider, Space } from "antd";
import React, { useState } from "react";
import styles from "./CustomDropDown.module.scss";
import { EditOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";

export default function CustomDropDown(props) {
  const [value, setValue] = useState("");
  return (
    <>
      {props.topDivider ? (
        <Divider
          style={{
            margin: "8px 0",
          }}
        />
      ) : null}
      <Space
        style={{
          padding: "10px 8px 4px",
        }}
      >
        <div className={styles.dropDownFooter}>
          {props.showInput ? (
            <Input
              placeholder={props.placeholder}
              className={styles.input}
              onChange={(event) => setValue(event.target.value)}
            />
          ) : null}

          <div
            className={
              props.isEnd
                ? styles.dropDownButtonsEnd
                : props.isCenter
                ? styles.dropDownButtonCenter
                : styles.dropDownButtons
            }
          >
            {props.showEdit ? (
              <Button
                className={styles.editEntry}
                type="primary"
                icon={<EditOutlined />}
                onClick={() => props.onSubmit(value)}
              >
                {props.editText}
              </Button>
            ) : null}
            {props.showDelete ? (
              <Button
                className={styles.deleteEntry}
                type="primary"
                icon={<MinusOutlined />}
                onClick={() => props.onDelete(value)}
              >
                {props.cancelText}
              </Button>
            ) : null}
            {props.showAdd ? (
              <Button
                className={styles.newEntry}
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => props.onSubmit(value)}
              >
                {props.addText}
              </Button>
            ) : null}
          </div>
        </div>
      </Space>
      {props.bottomDivider ? (
        <Divider
          style={{
            margin: "8px 0",
          }}
        />
      ) : null}
    </>
  );
}
