import { gql } from "@apollo/client";

export const GET_MAX_DATE = gql`
  query getMaxDate($pagination: PaginationArg, $sort: [String]) {
    outflows(pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          dataUscita
        }
      }
      meta {
        pagination {
          page
          pageSize
        }
      }
    }
  }
`;

export const GET_OUTFLOWS = gql`
  query getOutflows(
    $pagination: PaginationArg
    $sort: [String]
    $filters: OutflowFiltersInput
  ) {
    outflows(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          dataUscita
          importo
          descrizioneUscita
          sales
          outflow_type {
            data {
              id
              attributes {
                descrizione
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
        }
      }
    }
  }
`;

export const GET_SINGLE_OUTFLOW = gql`
  query getSingleOutflow($id: ID!) {
    outflow(id: $id) {
      data {
        id
        attributes {
          importo
          outflow_type {
            data {
              id
              attributes {
                descrizione
              }
            }
          }
        }
      }
    }
  }
`;

/* START OF MUTATION */
export const CREATE_OUTFLOW = gql`
  mutation CreateOutflow($data: OutflowInput!) {
    createOutflow(data: $data) {
      data {
        id
        attributes {
          dataUscita
          descrizioneUscita
          importo
          outflow_type {
            data {
              id
              attributes {
                descrizione
              }
            }
          }
        }
      }
    }
  }
`;

export const EDIT_OUTFLOW = gql`
  mutation updateOutflow($id: ID!, $data: OutflowInput!) {
    updateOutflow(id: $id, data: $data) {
      data {
        id
        attributes {
          dataUscita
        }
      }
    }
  }
`;

export const DELETE_OUTFLOW = gql`
  mutation deleteOutflow($id: ID!) {
    deleteOutflow(id: $id) {
      data {
        id
      }
    }
  }
`;

/* END OF MUTATION */
