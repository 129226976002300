import { gql } from "@apollo/client";

export const GET_ALLERGIES = gql`
  query getAllergies(
    $pagination: PaginationArg
    $sort: [String]
    $filters: AllergyFiltersInput
  ) {
    allergies(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          description
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
        }
      }
    }
  }
`;

export const CREATE_ALLERGY = gql`
  mutation createAllergy($data: AllergyInput!) {
    createAllergy(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_ALLERGY = gql`
  mutation deleteAllergy($id: ID!) {
    deleteAllergy(id: $id) {
      data {
        id
      }
    }
  }
`;
