import { Col, Form, Row, Button } from "antd";
import React, { useState, useEffect } from "react";
import FormInModal from "../../components/FormInModal/FormInModal";
import TableCustom from "../../components/Table/TableCustom";
import FormCustom from "../../components/FormCustom/FormCustom";
import ModalCustom from "../../components/Modal/ModalCustom";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import {
  getColumns,
  getBeneficiaryFormFields,
  getBeneficiariesFormFilters,
} from "./GestioneFornitoreConfig";
import styles from "./GestioneFornitorePage.module.scss";
import { GestioneFornitoreService } from "./GestioneFornitoreService";
import openNotification from "./../../components/Notification/Notification";

const getBeneficiariesParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

export default function GestioneFornitorePage() {
  const [form] = Form.useForm();
  const { height, width } = useWindowDimensions();
  const [defaultValues, setDefaultValues] = useState({
    businessName: "",
    ivaNumber: "",
    telephone: "",
    email: "",
    beneficiaryType: "",
    prefix:"+39"
  });

  const [defaultSearchValues, setDefaultSearchValues] = useState({
    businessName: "",
    ivaNumber: "",
    beneficiaryType: "",
  });

  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: ["businessName"],
    order: "asc",
    filters: {},
  });

  const [dataSource, setDataSource] = useState([]);
  /* END OF TABLE CONFIG */

  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  /* END MODAL TRIGGERS */

  /* START MODAL FORM HANDLER SECTION */
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [beneficiaryType, setBeneficiaryType] = useState([]);
  const [beneficiaryTypeIds, setBeneficiaryTypeIds] = useState([]);
  /* END MODAL FORM HANDLER SECTION */

  /* START TABLE SECTION */
  const readData = () => {
    GestioneFornitoreService.getBeneficiaries(
      getBeneficiariesParams(tableParams).page,
      getBeneficiariesParams(tableParams).pageSize,
      getBeneficiariesParams(tableParams).field +
        ":" +
        getBeneficiariesParams(tableParams).order,
      getBeneficiariesParams(tableParams).filters
    ).then((data) => {
      setDataSource(
        data.data.map((el) => {
          return {
            id: el.id,
            businessName: el.businessName,
            ivaNumber: el.ivaNumber,
            prefix: el.telephone?.split(" ")[0],
            telephone: el.telephone?.split(" ")[1],
            email: el.email,
            beneficiaryType: el.beneficiary_type ? el.beneficiary_type : "Fornitore Eliminato",
            description: el.beneficiary_type?.description ? el.beneficiary_type.description : "Fornitore Eliminato",
            supplierId: el.beneficiary_type?.supplierId ? el.beneficiary_type.supplierId : "Fornitore Eliminato",
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    readData();
    handleBeneficiaryTypes();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    let sort = {
      ...sorter,
      field: sorter.field ? sorter.field : "businessName",
      order: sorter?.order
        ? sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : ""
        : "desc",
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
        ...sort,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  const dataToExcel = (data) => {
    let list = [...data];
    let listToSend = [];

    list.forEach((el) => {
      listToSend.push({
        ["Ragione Sociale"]: el.businessName,
        ["Partita IVA"]: el.ivaNumber,
        ["Tipo Fornitore"]: el.description,
        ["ID Fornitore"]: el.supplierId,
        ["Telefono"]: el.prefix + " " + el.telephone,
        ["Email"]: el.email,
      });
    });

    return listToSend;
  };

  /* START OF BENEFICIARY SECTION */

  const onBeneficiarySubmit = (values) => {
    setLoading(true);

    let beneficiaryTypeId = beneficiaryTypeIds.find(
      (el) => el.description === values.beneficiaryTypeInfo
    )?.id;

    let objToSend = {
      data: {
        businessName: values.businessName,
        ivaNumber: values.ivaNumber,
        telephone: values?.telephone === "" ? null : values.prefix + " " + values?.telephone,
        email: values.email === "" ? null : values.email,
        beneficiary_type: beneficiaryTypeId,
      },
    };

    if (isNew) {
      GestioneFornitoreService.createBeneficiary(objToSend)
        .then(() => {
          readData();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    } else if (isEdit) {
      GestioneFornitoreService.updateBeneficiary(
        parseInt(values.id),
        objToSend.data
      )
        .then((res) => {
          readData();
          openNotification(
            "Successo",
            "La modifica è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
    setOpen(false);
  };

  const onBeneficiaryDelete = (id) => {
    setLoading(true);
    GestioneFornitoreService.deleteBeneficiary(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });

    setDeleteModal(false);
  };
  /* END BENEFICIARY SECTION */

  /* START OF BENEFICIARY_TYPE SECTION */

  const handleBeneficiaryTypes = () => {
    GestioneFornitoreService.getBeneficiariesTypes().then((res) => {
      let arr = [];
      res.data.forEach((el, key) => {
        arr.push({
          key: key,
          id: el.id,
          supplierId: el.supplierId,
          description: el.description,
        });
      });
      setBeneficiaryType(arr);
      setBeneficiaryTypeIds(res.data);
    });
  };

  /* END BENEFICIARY_TYPE SECTION */

  /* START SEARCH SECTION */
  const onBeneficiarySearch = (values) => {
    let filtersToSend = {
      or: [
        {
          businessName:
            values.businessName !== ""
              ? {
                  containsi: values.businessName,
                }
              : {},
        },
        {
          ivaNumber:
            values.ivaNumber !== ""
              ? {
                  containsi: values.ivaNumber,
                }
              : {},
        },
        {
          beneficiary_type:
            values.beneficiaryType !== ""
              ? {
                  description: {
                    containsi: values.beneficiaryType,
                  },
                }
              : {},
        },
      ],
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        filters: filtersToSend,
      };
    });

    delete values.search;
    setDefaultSearchValues(values);
  };
  /* END SEARCH SECTION */

  return (
    <>
      <Row>
        <Col span={24}>
          {/* START TABLE SECTION */}
          <Row>
            <FormCustom
              form={form}
              currentRow={defaultSearchValues}
              className={styles.suppliersSearchBar}
              id={"beneficiarySearch"}
              name={"beneficiarySearch"}
              allowClear
              fields={getBeneficiariesFormFilters(
                beneficiaryType.map((el) => {
                  return el.description;
                })
              )}
              isSearch={true}
              onFinish={onBeneficiarySearch}
              style={{
                width: 200,
              }}
            />
          </Row>
          <Row>
            <Col span={24}>
              <TableCustom
                excelConfig={{
                  excel: true,
                  excelText: "Esporta",
                  dataToExport: dataToExcel(dataSource),
                  fileName: "Gestione Fornitori",
                  colSize: 16,
                }}
                loading={loading}
                className={styles.table}
                size={width <= 1300 ? "small" : "large"}
                scroll={{
                  x: 1250,
                }}
                columns={getColumns(
                  setOpen,
                  setIsEdit,
                  setInitialValues,
                  setDeleteModal,
                  setDeleteId,
                  readData,
                  setIsNew
                )}
                dataSource={dataSource}
                title={() => (
                  <>
                    <span style={{ fontWeight: "bold" }}>
                      {"Elenco Fornitori".toLocaleUpperCase()}
                    </span>
                  </>
                )}
                onChange={handleTableChange}
                pagination={tableParams.pagination}
                showPagination={true}
                pageSizeOptions={["5", "10", "20", "50"]}
                showSizeChanger={true}
              />
            </Col>
          </Row>
          {/* END TABLE SECTION */}
          {/* START NEW/EDIT RECORD SECTION */}
          <Row>
            <Col span={4}>
              <Button
                className={styles.newEntry}
                type="primary"
                onClick={() => {
                  setIsNew(true);
                  setIsEdit(false);
                  setOpen(true);
                }}
              >
                {"Nuovo Fornitore"}
              </Button>
            </Col>
          </Row>

          {/* START OF SUPPLIERS FORM */}
          <FormInModal
            isSearch={false}
            dataSource={beneficiaryType}
            defaultValues={defaultValues}
            getFormFields={getBeneficiaryFormFields(
              beneficiaryType
            )}
            modalStyle={isEdit ? styles.modalEditStyle : styles.modalStyle}
            className={styles.suppliersForm}
            isEdit={isEdit}
            initialValues={initialValues}
            title={isEdit ? "Modifica Fornitore" : "Creazione Fornitore"}
            okText={isEdit ? "Salva Modifiche" : "Crea nuovo Fornitore"}
            cancelText={"Annulla"}
            width={600}
            open={open}
            centered={false}
            onSubmit={onBeneficiarySubmit}
            onCancel={() => {
              setOpen(false);
              setIsHidden(true);
              setIsSelect(false);
            }}
          />
          {/* END OF BENEFICIARIES FORM */}

          <ModalCustom
            centered={true}
            title={"Cancella Fornitore"}
            open={deleteModal}
            okText={"Conferma"}
            cancelText={"Annulla"}
            width={500}
            handleCancel={() => {
              setDeleteModal(false);
            }}
            handleOk={() => {
              onBeneficiaryDelete(deleteId);
            }}
          >
            <Row className={styles.textContainer}>
              <Col className={styles.text} span={18}>
                {"Sei sicuro di voler cancellare questo fornitore?"}
              </Col>
            </Row>
          </ModalCustom>
        </Col>
      </Row>
    </>
  );
}
