import { notification } from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";

const openNotification = (title, description, type) => {
  const status = {
    success: <CheckCircleTwoTone twoToneColor={"#33A921"} />,
    info: <InfoCircleTwoTone />,
    warning: <WarningTwoTone twoToneColor={"#FF8000"} />,
    error: <CloseCircleTwoTone twoToneColor={"#990000"} />,
  };

  notification.open({
    message: title,
    description: description,
    icon: status[type],
  });
};

export default openNotification;
