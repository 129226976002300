/* eslint-disable */
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";

export function getHomeForm() {
  let fields = [
    {
      id: "totaleBusta",
      label: "Tot. in busta",
      name: "totaleBusta",
      type: "input",
      disabled: true,
    },
    {
      id: "meseCorrente",
      label: "Incasso mese corrente",
      name: "meseCorrente",
      type: "input",
      disabled: true,
    },
    {
      id: "mesePrecedente",
      label: "Incasso Mese precedente",
      name: "mesePrecedente",
      type: "input",
      controls: false,
      disabled: true,
    },
    {
      id: "annoCorrente",
      label: "Incasso Anno corrente",
      name: "annoCorrente",
      type: "input",
      controls: false,
      disabled: true,
    },
  ];

  return fields;
}

export function getColumns() {
  let columns = [
    {
      title: "Banca",
      dataIndex: "bank",
      key: "bank",
      width: 100,
    },
    {
      title: "Importo",
      dataIndex: "import",
      key: "import",
      width: 100,
    },
  ];

  return columns;
}
