/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Form,
  DatePicker,
  Input,
  Select,
  Checkbox,
  Table,
  InputNumber,
  Radio,
  Button,
  Spin,
} from "antd";
const { RangePicker } = DatePicker;
import styles from "./FormCustom.module.scss";
import { monthHandle } from "../../utility/methods/methods";
import dayjs from "dayjs";
import VirtualTable from "../VirtualTable/VirtualTable";
import { GestioneIncassiService } from "./../../pages/GestioneIncassiPage/GestioneIncassiService";
import { GestionePrenotazioniService } from "../../pages/GestionePrenotazioniPage/GestionePrenotazioniService";
import { LoadingOutlined } from "@ant-design/icons";
import CountryCode from "../../utility/files/CountryCodes.json";

/*
LIST OF ALL INPUT FIELD TYPE: 
- checkBoxGroup 
- checkbox 
- input 
- inputNumber
- select
- datePicker, 
- rangeDatePicker 
- button 
- textArea 
- table
- radioGroup
- list
*/

/* START SINGLE ITEM FORM */

const timeSlots = {
  lunch: [
    {
      value: "12:30:00.000",
      label: "12:30",
    },
    {
      value: "12:45:00.000",
      label: "12:45",
    },
    {
      value: "13:00:00.000",
      label: "13:00",
    },
    {
      value: "13:15:00.000",
      label: "13:15",
    },
    {
      value: "13:30:00.000",
      label: "13:30",
    },
    {
      value: "13:45:00.000",
      label: "13:45",
    },
    {
      value: "14:00:00.000",
      label: "14:00",
    },
    {
      value: "14:15:00.000",
      label: "14:15",
    },
    {
      value: "14:30:00.000",
      label: "14:30",
    },
  ],
  dinner: [
    {
      value: "19:30:00.000",
      label: "19:30",
    },
    {
      value: "19:45:00.000",
      label: "19:45",
    },
    {
      value: "20:00:00.000",
      label: "20:00",
    },
    {
      value: "20:15:00.000",
      label: "20:15",
    },
    {
      value: "20:30:00.000",
      label: "20:30",
    },
    {
      value: "20:45:00.000",
      label: "20:45",
    },
    {
      value: "21:00:00.000",
      label: "21:00",
    },
    {
      value: "21:15:00.000",
      label: "21:15",
    },
    {
      value: "21:30:00.000",
      label: "21:30",
    },
    {
      value: "21:45:00.000",
      label: "21:45",
    },
    {
      value: "22:00:00.000",
      label: "22:00",
    },
  ],
};

function getSingleItem(
  element,
  key,
  formValue,
  handleChangeValue,
  handleChangeDateRadio,
  props,
  TextArea,
  Option,
  outflows
) {
  return (
    <Form.Item
      style={{
        display:
          props?.isView && element.id === "fondoCassaGp"
            ? "none"
            : element.hidden
            ? "none"
            : "block",
      }}
      key={key}
      className={element.className}
      id={element.id}
      name={element.name}
      label={element.label}
      rules={[
        {
          required: element.required,
          message: "*" + element.label + " è obbligatorio.",
        },
      ]}
      validateTrigger={["onChange", "onBlur", "onSelect"]}
      initialValue={formValue && formValue[element.id]}
    >
      {
        /* INPUT */
        element.type === "input" ? (
          <Input
            type={element.kind}
            key={element.id}
            maxLength={element.maxLength}
            disabled={element.disabled ? element.disabled : false}
            placeholder={element.placeholder ? element.placeholder : null}
            allowClear={element.allowClear ? element.allowClear : false}
            id={element.id}
            onChange={(e) => {
              handleChangeValue(element.id, e.target.value);
            }}
          />
        ) : /* INPUT NUMBER */ element.type === "inputNumber" ? (
          <InputNumber
            addonBefore={
              element.isAddOnBefore ? (
                <Form.Item
                  id={element.addonBefore.id}
                  name={element.addonBefore.name}
                  rules={[
                    {
                      required: !element.addonBefore.isHidden,
                      message: "*" + "Prefisso" + " è obbligatorio.",
                    },
                  ]}
                  validateTrigger={["onChange", "onBlur", "onSelect"]}
                  initialValue={formValue && formValue[element.addonBefore.id]}
                  noStyle
                >
                  {element.addonBefore.type === "select" ? (
                    <Select
                      onChange={(e) => {
                        handleChangeValue(element.addonBefore.id, e);
                      }}
                      showSearch={true}
                      defaultActiveFirstOption={false}
                      style={{
                        width: 80,
                      }}
                      defaultValue={"+39"}
                      disabled={element.addonBefore.disabled}
                      options={CountryCode.map((el, key) => {
                        return {
                          key: key,
                          value: el.prefix,
                          label: el.prefix,
                        };
                      })}
                    ></Select>
                  ) : null}
                </Form.Item>
              ) : null
            }
            value={0}
            key={element.id}
            controls={element.controls}
            disabled={element.disabled ? element.disabled : false}
            id={element.id}
            onChange={(e) => {
              handleChangeValue(element.id, e);
            }}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              if (charCode == 46 || charCode == 45) {
              } //Accepts .
              else if (charCode < 48 || charCode > 57) {
                //Accepts only numbers (charCodes 48-57)
                e.preventDefault();
              }
            }}
          />
        ) : /* TEXT AREA */
        element.type === "textArea" ? (
          <TextArea
            key={element.id}
            disabled={element.disabled ? element.disabled : false}
            id={element.id}
            onChange={(e) => {
              handleChangeValue(element.id, e.target.value);
            }}
          />
        ) : /* SELECT */
        element.type === "select" ? (
          <Select
            autoClearSearchValue={element.autoClearSearchValue}
            key={element.id}
            disabled={element.disabled ? element.disabled : false}
            placeholder={element.placeholder}
            onChange={(e) => {
              handleChangeValue(element.id, e);
            }}
            showSearch={element.showSearch}
            onSearch={element.onSearch}
            filterOption={element.filterOption}
            allowClear
            listHeight={element.listHeight}
            dropdownRender={element.dropdownRender}
            mode={element.mode}
            open={element.open}
            defaultActiveFirstOption={false}
            onSelect={element.onSelect}
            options={
              element.dataForSelect && element.dataForSelect.length > 0
                ? element.dataForSelect.map((item, key) => {
                    return {
                      key: key,
                      value: item,
                      label: item,
                    };
                  })
                : element.timeSlot === true &&
                  formValue &&
                  formValue.moment === "pranzo"
                ? timeSlots.lunch.map((lunchTime, key) => {
                    return {
                      ...lunchTime,
                      key: key,
                    };
                  })
                : element.timeSlot === true &&
                  formValue &&
                  formValue.moment === "cena"
                ? timeSlots.dinner.map((dinnerTime, key) => {
                    return {
                      ...dinnerTime,
                      key: key,
                    };
                  })
                : element.dataForSelectCustom &&
                  element.dataForSelectCustom.length > 0
                ? element.dataForSelectCustom
                : []
            }
          ></Select>
        ) : /* RANGE DATE PICKER */
        element.type === "rangeDatePicker" ? (
          <RangePicker
            key={element.id}
            placeholder={element.placeholder}
            picker={element.picker}
            showTime={element.showTime}
            allowEmpty={true}
            onChange={(e) => {
              handleChangeValue(element.id, e);
            }}
          />
        ) : element.type === "virtualTable" ? (
          <VirtualTable
            scroll={element.scroll}
            key={element.id}
            pagination={element.pagination}
            columns={element.columns}
            dataSource={outflows}
          />
        ) : element.type === "radioGroup" ? (
          <Radio.Group
            key={element.id}
            name={element.name}
            label={element.label}
            value={element.value}
            onChange={(e) => handleChangeDateRadio(element.id, e.target.value)}
          >
            {element.group.map((radio, key) => {
              return (
                <Radio.Button key={key} value={radio.value}>
                  {radio.label}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        ) : element.type === "id" ? (
          <div></div>
        ) : null
      }
    </Form.Item>
  );
}
/* END SINGLE ITEM FORM */

export default function FormCustom(props) {
  const [fields, setFields] = useState([]);
  const [formValue, setFormValue] = useState(null);
  const { Option } = Select;
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  function convertKeysToString(value, key) {
    if (typeof value === "object") {
      return Object.keys(value).filter((el) => value[el]);
    } else if (value === true) {
      return [key];
    } else if (value === false) {
      return [];
    }
  }
  /* START USE EFFECT SECTION */
  useEffect(() => {
    setFields(props.fields);
  }, [props.fields]);

  useEffect(() => {
    if (props.currentRow) {
      let obj = {};
      Object.keys(props.currentRow).forEach((key) => {
        obj[key] = props.currentRow[key];
      });
      setFormValue(obj);
    }
  }, [props.currentRow]);

  useEffect(() => {
    if (formValue) {
      if (formValue.totaleUscite || formValue.totaleUscite === 0) {
        let obj = { ...formValue };
        let outflows = obj.totaleUscite;
        let chiusuraFiscale = obj.cash + obj.theFork + obj.chiusuraPos;
        let cashBusta =
          obj.fondoCassaGp === 0
            ? obj.cash - outflows - obj.fondoCassa
            : obj.cash - outflows - (obj.fondoCassaGp - obj.fondoCassa);
        let totaleBusta =
          props?.dataSource?.length === 1 && props.isEdit
            ? cashBusta - obj.scaricoBusta
            : obj.totaleBustaGp + cashBusta - obj.scaricoBusta;
        let outflowsList = obj.outflows;

        props.form.setFieldsValue({
          obj,
          chiusuraFiscale: parseFloat(chiusuraFiscale.toFixed(2)),
          totaleUscite: parseFloat(outflows.toFixed(2)),
          cashBusta: parseFloat(cashBusta.toFixed(2)),
          totaleBusta: parseFloat(totaleBusta.toFixed(2)),
          outflows: outflowsList,
        });
      } else if (formValue.totalBill || formValue.totalBill === 0) {
        let obj = { ...formValue };
        let totalBill = obj.import + obj.tax;
        props.form.setFieldsValue({
          obj,
          totalBill: parseFloat(totalBill.toFixed(2)),
        });
      } else {
        props.form.setFieldsValue({
          ...formValue,
        });
      }

      if (props.isSearch) {
        if (formValue.orarioRadioGroup) {
          props.form.setFieldsValue({
            ...formValue,
            orarioRadioGroup: formValue.orarioRadioGroup,
          });
        } else {
          props.form.setFieldsValue({
            ...formValue,
            meseRadioGroup:
              /* !formValue.dataRange ||
              formValue.dataRange[0] !==
                dayjs(dayjs().subtract(1, "month")).startOf("month") ||
              formValue.dataRange[1] !==
                dayjs(dayjs().subtract(1, "month")).endOf("month")
                ? ""
                :  */ formValue.meseRadioGroup,
          });
        }
      }

      if (props.isInfo) {
        props.form.setFieldsValue(formValue);
      }

      if (formValue.clientInfo) {
        let obj = { ...formValue };
        props.form.setFieldsValue({
          obj,
          clientId: obj.clientId,
          email: obj.email,
          name: obj.name,
          lastName: obj.lastName,
          prefix: obj.prefix,
          telephone: obj.telephone,
          clientNote: obj.clientNote,
          allergies: obj.allergies,
          dietaryNeeds: obj.dietaryNeeds,
          people: obj.people,
        });
      }

      if (formValue.businessNameBill) {
        let obj = { ...formValue };
        props.form.setFieldsValue({
          ...obj,
          businessNameBill: obj.businessNameBill,
          idBeneficiary: obj.idBeneficiary,
        });
      }

      if (formValue.beneficiaryTypeInfo) {
        let obj = { ...formValue };
        props.form.setFieldsValue({
          ...obj,
          beneficiaryTypeInfo: obj.beneficiaryTypeInfo,
          supplierId: obj.supplierId,
        });
      }

      if (formValue.moment) {
        let obj = { ...formValue };
        props.form.setFieldsValue({
          ...obj,
          time: obj.time,
        });
      }
    }
  }, [formValue]);

  /* END USE EFFECT SECTION */

  const MultipleCheckbox = (props) => {
    const options =
      props.data.options &&
      Object.keys(props.data.options).map((el) => {
        return { label: el, value: props.data.options[el] };
      });

    const singleOption = props.data.singleOption && {
      label: props.data.singleOption,
      value: props.data.singleOption,
    };

    return (
      <>
        {options ? (
          <>
            <span>{props.data.checkBoxCol}</span>
            <Checkbox.Group
              defaultValue={props.initialValue}
              onChange={props.onChange}
            >
              {options.map((el) => {
                return (
                  <Checkbox key={el.id} value={el.label}>
                    {" "}
                    {el.label}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </>
        ) : singleOption ? (
          <>
            <span>{props.data.checkBoxCol}</span>
            <Checkbox.Group
              defaultValue={props.initialValue}
              onChange={props.onChange}
            >
              <Checkbox value={singleOption.value}></Checkbox>
            </Checkbox.Group>
          </>
        ) : null}
      </>
    );
  };

  /* START OF HADLE CHANGE VALUE */
  function handleChangeValue(id, value) {
    let currentValue = value === null ? undefined : value;
    if (id === "businessNameBill") {
      setFormValue((prevState) => {
        return {
          ...prevState,
          [id]: value,
          idBeneficiary: props.dataSource?.filter(
            (el) => el.businessName === value
          )[0]?.supplierId,
        };
      });
    }

    if (id === "beneficiaryTypeInfo") {
      setFormValue((prevState) => {
        return {
          ...prevState,
          [id]: value,
          supplierId: props.dataSource?.filter(
            (el) => el.description === value
          )[0]?.supplierId,
        };
      });
    }

    if (id === "clientInfo" && value) {
      setLoading(true);
      GestionePrenotazioniService.getSingleClient(value)
        .then((res) => {
          setFormValue((prevState) => {
            return {
              ...prevState,
              clientId: res.id,
              email: res.email,
              name: res.name,
              lastName: res.lastName,
              prefix:
                res.telephone === undefined || res.telephone === null
                  ? " "
                  : res.telephone?.split(" ")[0],
              telephone: res.telephone?.split(" ")[1],
              clientNote: res.clientNote,
              allergies:
                res.allergies && res.allergies.length > 0
                  ? res.allergies.map((el) => {
                      return el.description;
                    })
                  : undefined,
              dietaryNeeds:
                res.dietary_needs && res.dietary_needs.length > 0
                  ? res.dietary_needs.map((el) => {
                      return el.description;
                    })
                  : undefined,
            };
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }

    /* START OF RADIO DATE SECTION */

    if (props.isSearch) {
      switch (id) {
        case "dataRange": {
          setFormValue((prevState) => {
            return {
              ...prevState,
              [id]: value,
              meseRadioGroup:
                !value ||
                value[0] !==
                  dayjs(dayjs().subtract(1, "month")).startOf("month") ||
                value[1] !== dayjs(dayjs().subtract(1, "month")).endOf("month")
                  ? ""
                  : prevState.meseRadioGroup,
            };
          });
          break;
        }
        default:
          setFormValue((prevState) => {
            return {
              ...prevState,
              [id]: value === null && value === undefined ? {} : value,
            };
          });
      }
    }
    /* END OF RADIO DATE SECTION */
    /* DEFAULT CHANGE VALUE */
    switch (id) {
      case "date": {
        if (value) {
          let total = 0;
          let filters = {
            dataUscita: { eq: dayjs(value).format("YYYY-MM-DD") },
          };

          GestioneIncassiService.getRelationOutflows(filters).then((res) => {
            let arr = [];
            res.data?.map((el, key) => {
              arr.push({
                key: key,
                id: el.id,
                tipologiaUscita: el.outflow_type?.descrizione
                  ? el.outflow_type.descrizione
                  : "",
                uscita: el.importo,
              });
            });
            arr.forEach((el) => {
              total += el.uscita;
            });
            setFormValue((prevState) => {
              return {
                ...prevState,
                outflows: arr,
                totaleUscite: total,
              };
            });
          });
        }
        break;
      }
      case "cash": {
        setFormValue((prevState) => {
          let outflows = formValue.totaleUscite;
          return {
            ...prevState,
            [id]: currentValue,
            chiusuraFiscale:
              currentValue + prevState.theFork + prevState.chiusuraPos,
            cashBusta:
              currentValue -
              outflows -
              (prevState.fondoCassaGp - prevState.fondoCassa),
          };
        });
        break;
      }
      case "theFork": {
        setFormValue((prevState) => {
          return {
            ...prevState,
            [id]: currentValue,
            chiusuraFiscale:
              prevState.cash + currentValue + prevState.chiusuraPos,
          };
        });
        break;
      }
      case "chiusuraPos": {
        setFormValue((prevState) => {
          return {
            ...prevState,
            [id]: currentValue,
            chiusuraFiscale: prevState.cash + prevState.theFork + currentValue,
          };
        });
        break;
      }
      case "fondoCassa": {
        setFormValue((prevState) => {
          let outflows = formValue.totaleUscite;
          return {
            ...prevState,
            [id]: currentValue,
            cashBusta:
              prevState.fondoCassaGp === 0
                ? prevState.cash - outflows - currentValue
                : prevState.cash -
                  outflows -
                  (prevState.fondoCassaGp - currentValue),
          };
        });
        break;
      }
      case "scaricoBusta": {
        setFormValue((prevState) => {
          return {
            ...prevState,
            [id]: currentValue,
            totaleBusta:
              props?.dataSource?.length === 1 && props.isEdit
                ? prevState.cashBusta - currentValue
                : prevState.totaleBustaGp + prevState.cashBusta - currentValue,
          };
        });
        break;
      }
      //TOTAL BILL//
      case "import": {
        setFormValue((prevState) => {
          return {
            ...prevState,
            [id]: currentValue,
            totalBill:
              currentValue &&
              parseFloat(currentValue?.toFixed(2) + prevState?.tax?.toFixed(2)),
          };
        });
        break;
      }
      case "tax": {
        setFormValue((prevState) => {
          return {
            ...prevState,
            [id]: currentValue,
            totalBill: parseFloat(
              prevState.import.toFixed(2) + currentValue.toFixed(2)
            ),
          };
        });
        break;
      }
      //END TOTAL BILL
      default:
        setFormValue((prevState) => {
          return { ...prevState, [id]: currentValue };
        });
    }
  }

  function handleChangeValueCheck(id, value) {
    let obj = {};

    if (value.options) {
      Object.keys(value.options).forEach((el) => (obj[el] = false));
      value.checked.forEach((el) => (obj[el] = true));
    } else {
      obj = value.checked.length ? true : false;
    }
    setFormValue((prevState) => {
      return { ...prevState, [id]: obj };
    });
  }

  function handleChangeDateRadio(id, value) {
    if (id === "moment") {
      setFormValue((prevState) => {
        return {
          ...prevState,
          [id]: value,
          time: "",
        };
      });
    }

    if (id === "orarioRadioGroup") {
      setFormValue((prevState) => {
        return {
          ...prevState,
          [id]: value,
        };
      });
    } else {
      setFormValue((prevState) => {
        return {
          ...prevState,
          [id]: value,
          dataRange: monthHandle(value),
        };
      });
    }
  }

  /* END OF HADLE CHANGE VALUE */

  return (
    <>
      {loading && (
        <div className={styles.indicator}>
          <Spin indicator={antIcon} />
        </div>
      )}
      <Form
        preserve={props.preserve}
        form={props.form}
        id={props.id}
        className={props.className}
        size={props.size}
        name={props.name}
        onFinish={props.onFinish}
        validateTrigger={props.validateTrigger}
        layout="vertical"
        autoComplete="off"
      >
        {fields &&
          fields.map((el, key) => {
            /* CHECKBOX GROUP */
            return el.type === "checkboxGroup" ? (
              <div key={key} className={styles.multipleCheckboxContainer}>
                {el.data.map((item) => {
                  return (
                    <Form.Item
                      key={el.id}
                      className={el.className}
                      name={item.name}
                      initialValue={
                        formValue &&
                        convertKeysToString(
                          formValue[item.name],
                          item.singleOption
                        )
                      }
                    >
                      <MultipleCheckbox
                        initialValue={
                          formValue &&
                          convertKeysToString(
                            formValue[item.name],
                            item.singleOption
                          )
                        }
                        data={item}
                        onChange={(e) => {
                          handleChangeValueCheck(item.name, {
                            checked: e,
                            options: item.options,
                            singleOption: item.singleOption,
                          });
                        }}
                      />
                    </Form.Item>
                  );
                })}
              </div>
            ) : /* CHECKBOX */
            el.type === "checkbox" ? (
              <Form.Item
                key={key}
                className={el.className}
                name={el.name}
                label={el.label}
                rules={[
                  {
                    required: el.required,
                    message: "*" + el.label + " è obbligatorio.",
                  },
                ]}
                valuePropName="checked"
                initialValue={
                  formValue && formValue[el.id] === props.singleCheckCondition
                    ? ""
                    : formValue[el.id]
                }
              >
                <Checkbox
                  key={el.id}
                  checked={props.checked}
                  disabled={props.disabled}
                  onChange={(e) => {
                    handleChangeValue(el.id, e.target?.checked);
                  }}
                />
              </Form.Item>
            ) : /* DATEPICKER */
            el.type === "datePicker" ? (
              <Form.Item
                key={key}
                className={el.className}
                name={el.name}
                label={el.label}
                rules={[
                  {
                    required: el.required,
                    message: "*" + el.label + " è obbligatorio.",
                  },
                ]}
                validateTrigger={["onChange", "onBlur", "onSelect"]}
                initialValue={formValue && formValue[el.id]}
              >
                <DatePicker
                  allowEmpty={true}
                  picker={el.picker}
                  key={el.id}
                  disabled={el.disabled}
                  disabledDate={el.disabledDate}
                  format={props.format}
                  onChange={(e) => {
                    handleChangeValue(el.id, e);
                  }}
                />
              </Form.Item>
            ) : /* LIST */ el.type === "list" ? (
              <div
                key={key}
                className={el.hidden ? el.hiddenClass : el.className}
              >
                {el.list.map((subEl, index) => {
                  return getSingleItem(
                    subEl,
                    index,
                    formValue,
                    handleChangeValue,
                    handleChangeDateRadio,
                    props,
                    TextArea,
                    Option
                  );
                })}
              </div>
            ) : el.type === "button" ? (
              <Form.Item key={key} name={el.name}>
                <Button type="primary" htmlType="submit">
                  {el.label}
                </Button>
              </Form.Item>
            ) : (
              getSingleItem(
                el,
                key,
                formValue,
                handleChangeValue,
                handleChangeDateRadio,
                props,
                TextArea,
                Option,
                formValue?.outflows
              )
            );
          })}
      </Form>
    </>
  );
}
