/* eslint-disable */
import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";
import CustomDropDown from "../../components/SelectMenu/CustomDropDown";
import { Divider, Form, Select } from "antd";
import { GestionePrenotazioniService } from "./GestionePrenotazioniService";
import styles from "./GestionePrenotazioniPage.module.scss";

export function getColumns(
  setOpen,
  setIsEdit,
  setInitialValues,
  setDeleteModal,
  setDeleteId,
  readData,
  setIsNew
) {
  let columns = [
    {
      title: "Data Prenotazione",
      dataIndex: "resDate",
      key: "resDate",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
      /* render: (text, render) => {
        if (render.date && render.time)
          return <span>{render.date + " / " + render.time.slice(0, 5)}</span>;
      }, */
    },
    {
      title: "Orario",
      dataIndex: "time",
      key: "time",
      width: 100,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
      render: (text, render) => {
        if (render.name && render.lastName)
          return <span>{render.name + " " + render.lastName}</span>;
      },
    },
    {
      title: "Numero Persone",
      dataIndex: "people",
      key: "people",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Tavolo",
      dataIndex: "sit",
      key: "sit",
      width: 100,
    },
    {
      title: "",
      dataIndex: "status",
      key: "",
      width: 45,
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <Select
            key={render.id}
            defaultValue={render.status}
            bordered={false}
            onChange={(event) => {
              GestionePrenotazioniService.updateReservation(render.id, {
                resDate: dayjs(render.resDate).format("YYYY-MM-DD"),
                people: parseInt(render.people),
                moment: render.moment,
                time: /* render.time === "" ? null : dayjs(render.time) */ dayjs().format(
                  "HH:mm:ss.SSS"
                ),
                note: render.note,
                event: null,
                client: render.clientId,
                status: event,
              }).then(() => {
                readData();
              });
            }}
            options={[
              {
                value: "arrivato",
                label: "Arrivato",
              },
              {
                value: "prenotato",
                label: "Prenotato",
              },
              {
                value: "cancellato",
                label: "Cancellato",
              },
              {
                value: "noShow",
                label: "No Show",
              },
            ]}
          ></Select>
        );
      },
    },
    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsNew(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
                resDate: dayjs(render.resDate),
              });
              setOpen(true);
            }}
          />
        );
      },
      width: 40,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          <DeleteTwoTone
            twoToneColor={"#990000"}
            onClick={() => {
              setDeleteId(render.id);
              setDeleteModal(true);
            }}
            style={{ fontSize: 18 }}
          />
        );
      },
      width: 40,
    },
  ];

  return columns;
}

export function getReservationFormFields(
  onClientSearch,
  switchLayout,
  isSelect,
  isHidden,
  allergies,
  dietaryNeeds,
  initialValues,
  isEdit,
  client,
  events,
  onEventSubmit,
  handleDeleteEvent,
  onAllergySubmit,
  handleDeleteAllergy,
  onDietarySubmit,
  handleDeleteDietary
) {
  let fields = [
    {
      id: "resDate",
      label: "Data Prenotazione",
      name: "resDate",
      required: true,
      type: "datePicker",
      controls: false,
      disabledDate: (current) => {
        return dayjs().add(-1, "days") >= current;
      },
    },
    {
      id: "clientInfo",
      label: "Cliente",
      name: "clientInfo",
      required: isEdit ? true : !isSelect,
      type: "select",
      showSearch: true,
      onSearch: onClientSearch,
      onSelect: switchLayout,
      listHeight: 385,
      autoClearSearchValue: false,
      filterOption: false,
      placeholder: "Nome, numero di telef...",
      dataForSelectCustom: client.map((el, key) => {
        return {
          key: key,
          value: el.id,
          label: (
            <div onClick={switchLayout}>
              <div className={styles.clientInfo}>
                <span className={styles.client}>
                  {el.name + " " + el.lastName}
                </span>
                <span>{el.telephone}</span>
                <span>{el.email}</span>
                <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
              </div>
            </div>
          ),
          labelInValue: true,
        };
      }),
      disabled: !isEdit ? isSelect : true,
      hidden: !isEdit ? isSelect : true,
      dropdownRender: (menu) => {
        return (
          <>
            <CustomDropDown
              isCenter={true}
              showAdd={!isEdit ? true : false}
              addText={"Nuovo cliente"}
              showEdit={isEdit}
              editText={"Modifica Cliente"}
              bottomDivider={true}
              onSubmit={switchLayout}
            />
            {menu}
          </>
        );
      },
    },
    {
      id: "list",
      type: "list",
      className: "clientContainer",
      hiddenClass: "hiddenContainer",
      hidden: !isEdit ? isHidden : false,
      list: [
        {
          id: "name",
          label: "Nome",
          name: "name",
          required: !isEdit ? !isHidden : true,
          type: "input",
          controls: false,
          disabled: isEdit
        },
        {
          id: "lastName",
          label: "Cognome",
          name: "lastName",
          required: !isEdit ? !isHidden : true,
          type: "input",
          controls: false,
          disabled: isEdit
        },
        {
          id: "telephone",
          label: "Telefono",
          name: "telephone",
          required: false,
          type: "inputNumber",
          controls: false,
          isAddOnBefore: true,
          disabled: isEdit,
          addonBefore: {
            id: "prefix",
            name: "prefix",
            required: false,
            type: "select",
            controls: false,
            disabled: isEdit,
            isHidden: !isEdit ? !isHidden : true,
          },
        },
        {
          id: "email",
          label: "Email",
          name: "email",
          required: false,
          type: "input",
          controls: false,
          disabled: isEdit
        },
        {
          id: "clientNote",
          label: "Nota sul Cliente",
          name: "clientNote",
          required: false,
          type: "textArea",
          controls: false,
          //disabled: isEdit
        },
        {
          id: "allergies",
          label: "Allergie e Intolleranze",
          name: "allergies",
          required: false,
          type: "select",
          dataForSelect: allergies,
          mode: "multiple",
          controls: false,
          listHeight: 80,
          disabled: isEdit,
          dropdownRender: (menu) => {
            return (
              <>
                {menu}
                <CustomDropDown
                  isEnd={true}
                  showDelete={true}
                  showInput={true}
                  showAdd={true}
                  placeholder={"Inserire l'allergia"}
                  onDelete={handleDeleteAllergy}
                  onSubmit={onAllergySubmit}
                />
              </>
            );
          },
        },
        {
          id: "dietaryNeeds",
          label: "Esigenze alimentari specifiche",
          name: "dietaryNeeds",
          required: false,
          type: "select",
          mode: "multiple",
          controls: false,
          dataForSelect: dietaryNeeds,
          listHeight: 80,
          disabled: isEdit,
          dropdownRender: (menu) => {
            return (
              <>
                {menu}
                <CustomDropDown
                  isEnd={true}
                  showDelete={true}
                  showInput={true}
                  showAdd={true}
                  placeholder={"Inserire le esigenze alimentari"}
                  onDelete={handleDeleteDietary}
                  onSubmit={onDietarySubmit}
                />
              </>
            );
          },
        },
      ],
    },
    {
      id: "people",
      label: "Numero Persone",
      name: "people",
      required: true,
      type: "inputNumber",
      controls: false,
    },
    {
      id: "moment",
      label: " ",
      name: "moment",
      required: false,
      type: "radioGroup",
      group: [
        { value: "pranzo", label: "Pranzo" },
        { value: "cena", label: "Cena" },
      ],
    },
    {
      id: "time",
      label: "Orario",
      name: "time",
      required: true,
      type: "select",
      timeSlot: true,
    },
    {
      id: "event",
      label: "Occasioni speciali",
      name: "event",
      required: false,
      type: "select",
      dataForSelect: events,
      controls: false,
      listHeight: 80,
      dropdownRender: (menu) => {
        return (
          <>
            {menu}
            <CustomDropDown
              isEnd={true}
              showDelete={true}
              showInput={true}
              showAdd={true}
              placeholder={"Inserire l'evento"}
              onDelete={handleDeleteEvent}
              onSubmit={onEventSubmit}
            />
          </>
        );
      },
    },
    {
      id: "sit",
      label: "Tavolo",
      name: "sit",
      required: false,
      type: "input",
      controls: false,
    },
    {
      id: "note",
      label: "Note sulla prenotazione",
      name: "note",
      required: false,
      type: "textArea",
      controls: false,
    },
    { id: "id", name: "id" },
    { id: "clientId", name: "clientId" },
  ];

  return fields;
}

export function getReservationsFormFilters() {
  let fields = [
    {
      id: "resDateSearch",
      label: "Seleziona data Prenotazione",
      name: "resDateSearch",
      required: false,
      type: "datePicker",
    },
    {
      id: "orarioRadioGroup",
      label: "",
      name: "orarioRadioGroup",
      required: false,
      type: "radioGroup",
      value: "horizontal",
      group: [
        {
          value: "allService" /*  ["12:30:00.000", "23:00:00.000"] */,
          label: "Tutti i Servizi (12:30 - 23:00)",
        },
        {
          value: "lunch" /*  ["12:30:00.000", "15:00:00.000"] */,
          label: "Pranzo (12:30 - 15:00)",
        },
        {
          value: "dinner" /* ["19:30:00.000", "23:00:00.000"] */,
          label: "Cena (19:30 - 23:00)",
        },
      ],
    },
    {
      id: "search",
      label: "Cerca",
      name: "search",
      type: "button",
    },
  ];

  return fields;
}
