/* eslint-disable */
import { Col, Form, Row, Button } from "antd";
import React, { useState, useEffect } from "react";
import FormInModal from "../../components/FormInModal/FormInModal";
import TableCustom from "../../components/Table/TableCustom";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import { getColumns, getUsersFormFields } from "./GestioneUtentiConfig";
import styles from "./GestioneUtentiPage.module.scss";
import { GestioneUtentiService } from "./GestioneUtentiService";
import openNotification from "./../../components/Notification/Notification";
import ModalCustom from "../../components/Modal/ModalCustom";

const getUsersParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

export default function GestioneUtentiPage() {
  /* const [form] = Form.useForm();
  const { height, width } = useWindowDimensions(); */
  const [defaultValues, setDefaultValues] = useState({
    username: "",
    password: "",
  });

  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [dataSource, setDataSource] = useState([]);
  const [roleType] = useState(["Amministrativo", "Cameriere", "Admin"]);
  /* END OF TABLE CONFIG */

  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  /* END MODAL TRIGGERS */

  /* START MODAL FORM HANDLER SECTION */
  const [isView, setIsView] = useState(false);
  const [isNew, setIsNew] = useState(false);
  //const [isIncome, setIsIncome] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  /* END MODAL FORM HANDLER SECTION */

  const readData = () => {
    GestioneUtentiService.getUsers(
      getUsersParams(tableParams).page,
      getUsersParams(tableParams).pageSize
    ).then((data) => {
      setDataSource(
        data.data.map((el) => {
          return {
            id: el.id,
            username: el.username,
            type:
              el.type === "admin"
                ? "Admin"
                : el.type === "administrative"
                ? "Amministrativo"
                : "Cameriere",
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    readData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  const onSubmit = (values) => {
    setLoading(true);
    let objToSend = {
      data: {
        ...values,
        type:
          values.type && values.type === "Amministrativo"
            ? "administrative"
            : values.type === "Admin"
            ? "admin"
            : "default",
        email: `${values.username}@strapi.io`,
        role: "1",
      },
    };

    if (objToSend.data.id === null || objToSend.data.id === undefined) {
      delete objToSend.data.id;
    } else {
      delete objToSend.data.id;
      delete objToSend.data.password;
    }

    if (isNew) {
      GestioneUtentiService.createUser(objToSend)
        .then(() => {
          readData();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    } else if (isEdit) {
      GestioneUtentiService.updateUser(parseInt(values.id), objToSend.data)
        .then((res) => {
          readData();
          openNotification(
            "Successo",
            "La modifica è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
    setOpen(false);
  };

  const onDelete = (id) => {
    setLoading(true);
    GestioneUtentiService.deleteUser(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
    setDeleteModal(false);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          {/* START TABLE SECTION */}
          <Row>
            <Col span={24}>
              <TableCustom
                loading={loading}
                className={styles.table}
                size={"large"}
                columns={getColumns(
                  setOpen,
                  setIsEdit,
                  setIsView,
                  setInitialValues,
                  setDeleteModal,
                  setDeleteId,
                  setIsNew
                )}
                dataSource={dataSource}
                title={() => (
                  <span style={{ fontWeight: "bold" }}>
                    {"Elenco Utenti".toLocaleUpperCase()}
                  </span>
                )}
                onChange={handleTableChange}
                pagination={tableParams.pagination}
                showPagination={true}
              />
            </Col>
          </Row>
          {/* END TABLE SECTION */}
          {/* START NEW/EDIT RECORD SECTION */}
          <Row>
            <Col span={4}>
              <Button
                className={styles.newEntry}
                type="primary"
                onClick={() => {
                  setIsNew(true);
                  setIsView(false);
                  setIsEdit(false);
                  setOpen(true);
                }}
              >
                {"Nuovo Utente"}
              </Button>
            </Col>
          </Row>

          {/* START OF USERS FORM */}
          <FormInModal
            isSearch={false}
            dataSource={dataSource}
            defaultValues={defaultValues}
            getFormFields={getUsersFormFields(roleType, isEdit)}
            modalStyle={!isView && !isEdit && styles.modalStyle}
            className={styles.usersForm}
            isEdit={isEdit}
            isView={isView}
            initialValues={initialValues}
            title={
              isEdit
                ? "Modifica Utente"
                : isView
                ? "Utente"
                : "Creazione Utente"
            }
            okText={isEdit ? "Salva Modifiche" : "Crea nuovo Utente"}
            cancelText={"Annulla"}
            width={500}
            open={open}
            onSubmit={onSubmit}
            onCancel={() => {
              setOpen(false);
            }}
          />
          {/* END OF USERS FORM */}
          <ModalCustom
            title={"Elimina Utente"}
            open={deleteModal}
            okText={"Conferma"}
            cancelText={"Annulla"}
            width={500}
            handleCancel={() => {
              setDeleteModal(false);
            }}
            handleOk={() => {
              onDelete(deleteId);
            }}
          >
            <Row className={styles.textContainer}>
              <Col className={styles.text} span={18}>
                {"Sei sicuro di voler cancellare questo utente?"}
              </Col>
            </Row>
          </ModalCustom>
        </Col>
      </Row>
    </>
  );
}
