import React from "react";
import { Modal } from "antd";
const ModalCustom = (props) => {
  return (
    <>
      <Modal
        centered={props.centered}
        width={props.width}
        open={props.open}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        okText={props.okText}
        cancelText={props.cancelText}
        title={props.title}
      >
        {props.children}
      </Modal>
    </>
  );
};
export default ModalCustom;
