import React, { useEffect, useState } from "react";
import {
  getColumns,
  getReservationFormFields,
  getReservationsFormFilters,
} from "./GestionePrenotazioniConfig";
import { Button, Table, Row, Col, Divider } from "antd";
import FormInModal from "../../components/FormInModal/FormInModal";
import dayjs from "dayjs";
import FormCustom from "../../components/FormCustom/FormCustom";
import styles from "./GestionePrenotazioniPage.module.scss";
import TableCustom from "../../components/Table/TableCustom";
import { GestionePrenotazioniService } from "./GestionePrenotazioniService";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import { Form } from "antd";
import ModalCustom from "../../components/Modal/ModalCustom";
import openNotification from "./../../components/Notification/Notification";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const getReservationsParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default function GestionePrenotazioniPage() {
  const [form] = Form.useForm();
  const { height, width } = useWindowDimensions();
  const [client, setClient] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventIds, setEventIds] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [allergyIds, setAllergyIds] = useState([]);
  const [dietaryNeeds, setDietaryNeeds] = useState([]);
  const [dietaryNeedIds, setDietaryNeedIds] = useState([]);
  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  /* END MODAL TRIGGERS */
  const defaultValues = useSelector(
    (state) => state.gestionePrenotazioni.defaultValues
  );
  const [defaultSearchValues, setDefaultSearchValues] = useState({
    resDateSearch: null,
    orarioRadioGroup: "",
  });
  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: ["resDate", "time"],
    order: "asc",
    filters: {},
  });
  const [dataSource, setDataSource] = useState([]);
  /* END OF TABLE CONFIG */
  /* START MODAL FORM HANDLER SECTION */
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    prefix: "+39",
  });
  const [isSelect, setIsSelect] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  /* END MODAL FORM HANDLER SECTION */

  const readData = () => {
    GestionePrenotazioniService.getReservations(
      getReservationsParams(tableParams).page,
      getReservationsParams(tableParams).pageSize,
      getReservationsParams(tableParams).field?.map((el) => {
        return el + ":" + getReservationsParams(tableParams).order;
      }),
      getReservationsParams(tableParams)?.filters
    ).then((data) => {
      setDataSource(
        data.data?.map((el) => {
          return {
            id: el?.id ? el.id : "",
            resDate: el.resDate,
            eventId: el.event?.id,
            event: el.event?.description ? el.event.description : [],
            moment: el.moment,
            note: el.note,
            people: el.people,
            sit: el.sit,
            time: el.time.slice(0, 5),
            clientId: el.client?.id,
            allergies:
              el.client?.allergies && el.client?.allergies.length > 0
                ? el.client.allergies?.map((el) => {
                    return el.description;
                  })
                : undefined,
            clientNote: el.client?.clientNote,
            dietaryNeeds:
              el.client?.dietary_needs && el.client.dietary_needs.length > 0
                ? el.client.dietary_needs.map((el) => {
                    return el.description;
                  })
                : undefined,
            email: el.client?.email,
            lastName: el.client?.lastName,
            name: el.client?.name,
            prefix: el.client?.telephone?.split(" ")[0],
            telephone: el.client?.telephone?.split(" ")[1],
            status: el.status,
            clientInfo: el.client?.name + " " + el.client?.lastName,
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    handleEvents();
    handleClients();
    handleDietaryNeeds();
    handleAllergies();
  }, []);

  useEffect(() => {
    setLoading(true);
    readData();
  }, [JSON.stringify(tableParams)]);

  const onSubmit = (values) => {
    setLoading(true);

    let time =
      values.time && values.time.length === 5
        ? values.time + ":00.000"
        : values.time;
    let event = values.event?.length > 0 ? values.event : "";
    let allergy = values.allergies?.length > 0 ? values.allergies : "";
    let dietary = values.dietaryNeeds?.length > 0 ? values.dietaryNeeds : "";

    let eventId = eventIds.find((el) => el.description === event)?.id;
    let allergyId = allergyIds
      .filter((el) => {
        return allergy.includes(el.description);
      })
      .map((el) => {
        return el.id;
      });
    let dietaryNeedId = dietaryNeedIds
      .filter((el) => {
        return dietary.includes(el.description);
      })
      .map((el) => {
        return el.id;
      });

    let objToSend = {};

    let reservation = {
      resDate: dayjs(values.resDate).format("YYYY-MM-DD"),
      people: values.people === "" ? 1 : parseInt(values.people),
      moment: values.moment,
      time: time,
      sit: values.sit,
      note: values.note,
      event: eventId ? eventId : null,
    };
    let client = {
      data: {
        name: values.name,
        lastName: values.lastName,
        telephone: values?.telephone === "" ? null : values.prefix + " " + values.telephone,
        email: values.email === "" ? null : values.email,
        allergies: allergyId ? allergyId : null,
        dietary_needs: dietaryNeedId ? dietaryNeedId : null,
        clientNote: values.clientNote,
      },
    };

    //PER CREARE UNA PRENOTAZIONE E MODIFICARE IL CLIENTE
    if (isNew && values.clientInfo && values.clientInfo.length > 0) {
      GestionePrenotazioniService.updateClient(values.clientId, client.data)
        .then((res) => {
          objToSend = {
            data: {
              ...reservation,
              client: res.id,
            },
          };
          GestionePrenotazioniService.createReservation(objToSend).then(() => {
            handleClients();
            readData();
            openNotification(
              "Successo",
              "La creazione è andata a buon fine",
              "success"
            );
          });
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
      //PER CREARE UNA PRENOTAZIONE E CREARE IL CLIENTE
    } else if (isNew) {
      GestionePrenotazioniService.createClient(client)
        .then((res) => {
          objToSend = {
            data: {
              ...reservation,
              client: res.id,
            },
          };
          GestionePrenotazioniService.createReservation(objToSend).then(() => {
            handleClients();
            readData();
            openNotification(
              "Successo",
              "La creazione è andata a buon fine",
              "success"
            );
          });
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
      //PER MODIFICARE UNA PRENOTAZIONE E MODIFICARE IL CLIENTE
    } else if (isEdit) {
      GestionePrenotazioniService.updateClient(values.clientId, client.data)
        .then((res) => {
          objToSend = {
            ...reservation,
            client: res.id,
          };
          GestionePrenotazioniService.updateReservation(
            values.id,
            objToSend
          ).then(() => {
            handleClients();
            readData();
            openNotification(
              "Successo",
              "La creazione è andata a buon fine",
              "success"
            );
          });
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }

    setIsHidden(true);
    setIsSelect(false);
    setOpen(false);
  };

  const onSearch = (values) => {
    let dateToSend = {
      resDate: values.resDateSearch
        ? { eq: dayjs(values.resDateSearch).format("YYYY-MM-DD") }
        : {},
    };
    let timeToSend =
      values.orarioRadioGroup?.length && values.orarioRadioGroup === "lunch"
        ? {
            and: [
              {
                time: {
                  gte: "12:30:00.000",
                },
              },
              {
                time: {
                  lte: "15:00:00.000",
                },
              },
            ],
          }
        : values.orarioRadioGroup?.length &&
          values.orarioRadioGroup === "dinner"
        ? {
            and: [
              {
                time: {
                  gte: "19:30:00.000",
                },
              },
              {
                time: {
                  lte: "23:00:00.000",
                },
              },
            ],
          }
        : {};

    let filtersToSend = {
      ...dateToSend,
      ...timeToSend,
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        filters: filtersToSend,
      };
    });

    delete values.search;
    setDefaultSearchValues(values);
  };

  const onDelete = (id) => {
    setLoading(true);
    GestionePrenotazioniService.deleteReservation(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });

    setDeleteModal(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let fields = [...tableParams.field];

    let sort = {
      ...sorter,
      field:
        sorter.field === "name"
          ? ["client.name"]
          : sorter.field === "resDate"
          ? ["resDate", "time"]
          : [sorter.field][sorter.field]
          ? fields.includes(sorter.field)
            ? [fields]
            : [...fields, sorter.field]
          : ["resDate", "time"],
      order: sorter?.order
        ? sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : ""
        : "desc",
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
        ...sort,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  /* START OF CLIENT SECTION */

  const handleClientSearch = React.useRef(
    debounce(async (value) => {
      let filters = {
        or: [
          { name: { containsi: value } },
          { lastName: { containsi: value } },
          { email: { containsi: value } },
          { telephone: { containsi: value } },
        ],
      };

      await handleClients(filters);
      return client;
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      handleClientSearch.cancel();
    };
  }, [handleClientSearch]);

  const handleClients = (filters) => {
    GestionePrenotazioniService.getClients(filters).then((res) => {
      let arr = [];
      res.data.forEach((el) => {
        arr.push({
          id: el.id,
          name: el.name,
          lastName: el.lastName,
          telephone: el.telephone?.split(" ")[1].length > 0 ? el.telephone : "",
          email: el.email ? el.email : "",
        });
      });
      setClient(arr);
    });
  };
  /* END OF CLIENT SECTION */

  /* START OF EVENTS SECTION */
  const handleEvents = () => {
    GestionePrenotazioniService.getEvents().then((res) => {
      let arr = [];
      res.data.forEach((el) => {
        arr.push({
          id: el.id,
          description: el.description,
        });
      });
      setEvents(arr);
      setEventIds(res.data);
    });
  };

  const onEventSubmit = (values) => {
    if (values != "") {
      let objToSend = {
        data: {
          description: values,
        },
      };

      GestionePrenotazioniService.createEvent(objToSend)
        .then(() => {
          handleEvents();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
  };

  const handleDeleteEvent = (values) => {
    let eventToDelete = events.filter((el) =>
      el.description.toLowerCase().includes(values.toLowerCase())
    )[0].id;

    GestionePrenotazioniService.deleteEvent(eventToDelete)
      .then(() => {
        handleEvents();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
  };
  /* END OF EVENTS SECTION */

  /* START OF ALLERGIES SECTION */
  const handleAllergies = () => {
    GestionePrenotazioniService.getAllergies().then((res) => {
      let arr = [];
      res.data.forEach((el, key) => {
        arr.push({
          key: key,
          id: el.id,
          description: el.description,
        });
      });
      setAllergies(arr);
      setAllergyIds(res.data);
    });
  };

  const onAllergySubmit = (values) => {
    if (values != "") {
      let objToSend = {
        data: {
          description: values,
        },
      };

      GestionePrenotazioniService.createAllergy(objToSend)
        .then(() => {
          handleAllergies();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
  };

  const handleDeleteAllergy = (values) => {
    let allergyToDelete = allergies.filter((el) =>
      el.description.toLowerCase().includes(values.toLowerCase())
    )[0].id;

    GestionePrenotazioniService.deleteAllergy(allergyToDelete)
      .then(() => {
        handleAllergies();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
  };
  /* END OF ALLERGIES SECTION */

  /* START OF ALLERGIES SECTION */
  const handleDietaryNeeds = () => {
    GestionePrenotazioniService.getDietaryNeed().then((res) => {
      let arr = [];
      res.data.forEach((el, key) => {
        arr.push({
          key: key,
          id: el.id,
          description: el.description,
        });
      });
      setDietaryNeeds(arr);
      setDietaryNeedIds(res.data);
    });
  };

  const onDietarySubmit = (values) => {
    if (values != "") {
      let objToSend = {
        data: {
          description: values,
        },
      };

      GestionePrenotazioniService.createDietary(objToSend)
        .then(() => {
          handleDietaryNeeds();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
  };

  const handleDeleteDietary = (values) => {
    let dietaryToDelete = dietaryNeeds.filter((el) =>
      el.description.toLowerCase().includes(values.toLowerCase())
    )[0].id;

    GestionePrenotazioniService.deleteDietary(dietaryToDelete)
      .then(() => {
        handleDietaryNeeds();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
  };

  /* END OF ALLERGIES SECTION */

  const dataToExcel = (data) => {
    let list = [...data];
    let listToSend = [];

    list.forEach((el) => {
      listToSend.push({
        ["Data Prenotazione"]: el.resDate,
        ["Orario"]: el.time,
        ["Nome"]: el.name + " " + el.lastName,
        ["Numero Persone"]: el.people,
        ["Tavolo"]: el.sit,
        ["Stato"]: el.status,
      });
    });

    return listToSend;
  };

  function switchLayout() {
    setIsHidden(!isHidden);
    setIsSelect(!isSelect);
  }

  return (
    <div>
      {/* START OF FILTER SECTION */}
      <div className={styles.searchFormContainer}>
        <FormCustom
          form={form}
          currentRow={defaultSearchValues}
          className={styles.searchForm}
          id={"reservationsFilterForm"}
          name={"reservationsFilterForm"}
          fields={getReservationsFormFilters()}
          onFinish={onSearch}
          isSearch={true}
        />
      </div>
      {/* END OF FILTER SECTION */}

      {/* START TABLE SECTION */}
      <Row>
        <Col span={24}>
          <TableCustom
            excelConfig={{
              excel: true,
              excelText: "Esporta",
              dataToExport: dataToExcel(dataSource),
              fileName: "Gestione Prenotazioni",
              colSize: 16,
            }}
            loading={loading}
            className={styles.table}
            size={width <= 1300 ? "small" : "large"}
            scroll={{
              x: 1250,
            }}
            rowClassName={(record, index) =>
              record.status === "cancellato"
                ? styles.disabledRow
                : styles.defaultRow
            }
            columns={getColumns(
              setOpen,
              setIsEdit,
              setInitialValues,
              setDeleteModal,
              setDeleteId,
              readData,
              setIsNew
            )}
            dataSource={dataSource}
            title={() => (
              <span style={{ fontWeight: "bold" }}>
                {"Elenco Prenotazioni".toLocaleUpperCase()}
              </span>
            )}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            showPagination={true}
            pageSizeOptions={["5", "10", "20", "50"]}
            showSizeChanger={true}
          />
        </Col>
      </Row>
      {/* END TABLE SECTION */}

      {/* START NEW/EDIT RECORD SECTION */}
      <div className={styles.buttonContainer}>
        <div className={styles.buttonCol}>
          <Button
            className={styles.newEntry}
            type="primary"
            onClick={() => {
              setIsNew(true);
              setIsEdit(false);
              setOpen(true);
            }}
          >
            {"Nuova Prenotazione"}
          </Button>
        </div>
      </div>

      {/* START OF RESERVATION FORM */}
      <FormInModal
        isSearch={false}
        defaultValues={defaultValues}
        getFormFields={getReservationFormFields(
          handleClientSearch,
          switchLayout,
          isSelect,
          isHidden,
          allergies.map((el) => {
            return el.description;
          }),
          dietaryNeeds.map((el) => {
            return el.description;
          }),
          initialValues,
          isEdit,
          client,
          events.map((el) => {
            return el.description;
          }),
          onEventSubmit,
          handleDeleteEvent,
          onAllergySubmit,
          handleDeleteAllergy,
          onDietarySubmit,
          handleDeleteDietary
        )}
        modalStyle={isEdit ? styles.modalEditStyle : styles.modalStyle}
        className={styles.reservationForm}
        isEdit={isEdit}
        initialValues={initialValues}
        title={isEdit ? "Modifica Prenotazione" : "Crea Prenotazione"}
        okText={isEdit ? "Salva Modifiche" : "Crea Prenotazione"}
        cancelText={"Annulla"}
        width={800}
        open={open}
        centered={true}
        onSubmit={onSubmit}
        onCancel={() => {
          setOpen(false);
          setIsHidden(true);
          setIsSelect(false);
        }}
      >
        <Divider type="vertical" />
      </FormInModal>
      {/* END OF RESERVATION FORM */}

      <ModalCustom
        centered={true}
        title={"Cancella Prenotazione"}
        open={deleteModal}
        okText={"Conferma"}
        cancelText={"Annulla"}
        width={500}
        handleCancel={() => {
          setDeleteModal(false);
        }}
        handleOk={() => {
          onDelete(deleteId);
        }}
      >
        <Row className={styles.textContainer}>
          <Col className={styles.text} span={18}>
            {"Sei sicuro di voler cancellare questa prenotazione?"}
          </Col>
        </Row>
      </ModalCustom>
    </div>
  );
}
