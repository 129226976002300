import { ApolloRepo } from "../../utility/graphql/Client";
import {
  GET_USERS,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  GET_USER,
} from "../../utility/graphql/UsersQueries";
import { strapiAdapter } from "../../utility/methods/methods";

class GestioneUtentiServiceC {
  getUsers(page, pageSize) {
    return ApolloRepo.client
      .query({
        query: GET_USERS,
        variables: {
          pagination: { page: page, pageSize: pageSize },
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.usersPermissionsUsers),
          meta: res.data.usersPermissionsUsers.meta,
        };
      });
  }

  getUser(id) {
    return ApolloRepo.client
      .query({
        query: GET_USER,
        variables: {
          id: id,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.usersPermissionsUser),
        };
      });
  }

  createUser(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_USER,
      variables: data,
    });
  }

  updateUser(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_USER,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateUsersPermissionsRole);
      });
  }

  deleteUser(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_USER,
      variables: { id: id },
    });
  }
}

export const GestioneUtentiService = new GestioneUtentiServiceC();
