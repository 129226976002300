import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultValues: {
    event: "",
    moment: "pranzo",
    note: "",
    people: "",
    time: "",
    allergies: undefined,
    clientNote: "",
    dietaryNeeds: undefined,
    email: "",
    lastName: "",
    name: "",
    sit: "",
    telephone: "",
    prefix: "+39",
  },
};

export const gestionePrenotazioniSlice = createSlice({
  name: "gestionePrenotazioni",
  initialState,
  reducers: {},
});

//export const {} = gestionePrenotazioniSlice.actions;

export default gestionePrenotazioniSlice.reducer;
