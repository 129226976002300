import React, { useState, useEffect } from "react";
import { Col, Form, Row, Button } from "antd";
import FormInModal from "../../components/FormInModal/FormInModal";
import TableCustom from "../../components/Table/TableCustom";
import FormCustom from "../../components/FormCustom/FormCustom";
import ModalCustom from "../../components/Modal/ModalCustom";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import {
  getColumns,
  getBillsFormFields,
  searchBarBills,
} from "./GestioneFattureConfig";
import dayjs from "dayjs";
import styles from "./GestioneFatturePage.module.scss";
import { GestioneFornitoreService } from "../GestioneFornitorePage/GestioneFornitoreService";
import { GestioneFattureService } from "./GestioneFattureService";
import openNotification from "./../../components/Notification/Notification";
const getBillsParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

export default function GestioneFatturePage() {
  const [form] = Form.useForm();
  const { width } = useWindowDimensions();

  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: ["issueDate"],
    order: "desc",
    filters: {},
  });

  const [dataSource, setDataSource] = useState([]);

  /* END OF TABLE CONFIG */

  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [defaultValues] = useState({
    businessNameBill: "",
    idBeneficiary: "",
    billNumber: "",
    issueDate: "",
    import: 0,
    tax: 0,
    totalBill: 0,
    receiptDate: "",
  });
  const [defaultSearchValues, setDefaultSearchValues] = useState({});
  /* END MODAL TRIGGERS */

  /* START MODAL FORM HANDLER SECTION */
  const [isView, setIsView] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  /* END MODAL FORM HANDLER SECTION */

  const dataToExcel = (data) => {
    let list = [...data];
    let listToSend = [];

    list.forEach((el) => {
      listToSend.push({
        ["Ragione Sociale"]: el.businessNameBill,
        ["ID Fornitore"]: el.idBeneficiary,
        ["Numero Fattura/ Documento"]: el.billNumber,
        ["Data Emissione"]: el.issueDate,
        ["Imponibile/ Importo"]: el.import,
        ["Imposta"]: el.tax,
        ["Totale Fattura"]: el.totalBill,
        ["Data Ricezione"]: el.receiptDate,
      });
    });

    return listToSend;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let order = "desc";

    if (sorter?.order === "ascend") {
      order = "asc";
    }

    let sort = {
      ...sorter,
      field: sorter.field ? sorter.field : "issueDate",
      order: order,
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
        ...sort,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  const onSubmit = (values) => {
    setLoading(true);

    let beneficiaryId = beneficiaries?.find(
      (el) =>
        el.businessName === values.businessNameBill &&
        el.supplierId === values.idBeneficiary
    )?.id;

    let objToSend = {
      data: {
        beneficiary: beneficiaryId,
        billNumber: values.billNumber,
        issueDate: dayjs(values.issueDate).format("YYYY-MM-DD"),
        import: values.import,
        tax: values.tax,
        totalBill: values.totalBill,
        receiptDate: dayjs(values.receiptDate).format("YYYY-MM-DD"),
      },
    };

    if (isNew) {
      GestioneFattureService.createBill(objToSend)
        .then(() => {
          readData();
          handleBeneficiaries();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    } else if (isEdit) {
      GestioneFattureService.updateBill(parseInt(values.id), objToSend.data)
        .then((res) => {
          readData();
          handleBeneficiaries();
          openNotification(
            "Successo",
            "La modifica è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
    setOpen(false);
  };

  const onDelete = (id) => {
    setLoading(true);
    GestioneFattureService.deleteBill(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });

    setDeleteModal(false);
  };

  const handleBeneficiaries = () => {
    GestioneFornitoreService.getBeneficiaries().then((res) => {
      let arr = [];
      res.data.forEach((el) => {
        arr.push({
          id: el.id,
          businessName: el.businessName,
          supplierId: el.beneficiary_type?.supplierId,
          description: el.beneficiary_type?.description,
        });
      });
      setBeneficiaries(arr);
    });
  };

  /* START TABLE SECTION */
  const readData = () => {
    GestioneFattureService.getBills(
      getBillsParams(tableParams).page,
      getBillsParams(tableParams).pageSize,
      getBillsParams(tableParams).field +
        ":" +
        getBillsParams(tableParams).order,
      getBillsParams(tableParams).filters
    ).then((data) => {
      setDataSource(
        data.data.map((el) => {
          return {
            id: el.id,
            businessNameBill: el.beneficiary?.businessName,
            idBeneficiary: el.beneficiary?.beneficiary_type?.supplierId,
            billNumber: el.billNumber,
            issueDate: dayjs(el.issueDate).format("DD/MM/YYYY"),
            import: el.import,
            tax: el.tax,
            totalBill: el.totalBill ? parseFloat(el.totalBill.toFixed(2)) : 0,
            receiptDate: dayjs(el.receiptDate).format("DD/MM/YYYY"),
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    readData();
    handleBeneficiaries();
  }, [JSON.stringify(tableParams)]);

  //start search section
  const searchBillsFilters = (values) => {
    let dateToSend = values.pickerMonth
      ? {
          and: [
            {
              issueDate: {
                gte: dayjs(values.pickerMonth)
                  .startOf("month")
                  .format("YYYY-MM-DD"),
              },
            },

            {
              issueDate: {
                lte: dayjs(values.pickerMonth)
                  .endOf("month")
                  .format("YYYY-MM-DD"),
              },
            },
          ],
        }
      : {};

    setTableParams((prevState) => {
      return {
        ...prevState,
        filters: dateToSend,
      };
    });

    delete values.search;
    setDefaultSearchValues(values);
  };

  /* END SEARCH SECTION */

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <FormCustom
              form={form}
              currentRow={defaultSearchValues}
              className={styles.billsSearchBar}
              id={"billierSearch"}
              name={"billierSearch"}
              allowClear
              fields={searchBarBills()}
              onFinish={searchBillsFilters}
              style={{
                width: 200,
              }}
            />
          </Row>
          {/* START TABLE SECTION */}
          <Row>
            <Col span={24}>
              <TableCustom
                excelConfig={{
                  excel: true,
                  excelText: "Esporta",
                  dataToExport: dataToExcel(dataSource),
                  fileName: "Gestione Fatture",
                  colSize: 16,
                }}
                loading={loading}
                className={styles.table}
                size={width <= 1300 ? "small" : "large"}
                scroll={{
                  x: 1250,
                }}
                columns={getColumns(
                  setOpen,
                  setIsEdit,
                  setIsView,
                  setInitialValues,
                  setDeleteModal,
                  setDeleteId,
                  setIsNew
                )}
                dataSource={dataSource}
                title={() => (
                  <>
                    <span style={{ fontWeight: "bold" }}>
                      {"Elenco Fatture".toLocaleUpperCase()}
                    </span>
                  </>
                )}
                onChange={handleTableChange}
                pagination={tableParams.pagination}
                showPagination={true}
                defaultCurrent={3}
                total={500}
                pageSizeOptions={["5", "10", "20", "50"]}
                showSizeChanger={true}
              />
            </Col>
          </Row>
          {/* END TABLE SECTION */}
          {/* START NEW/EDIT RECORD SECTION */}
          <Row>
            <Col span={4}>
              <Button
                className={styles.newEntry}
                type="primary"
                onClick={() => {
                  setIsNew(true);
                  setIsView(false);
                  setIsEdit(false);
                  setOpen(true);
                }}
              >
                {"Nuova Fattura "}
              </Button>
            </Col>
          </Row>

          {/* START OF SUPPLIERS FORM */}
          <FormInModal
            isSearch={false}
            dataSource={beneficiaries}
            defaultValues={defaultValues}
            getFormFields={getBillsFormFields(
              initialValues,
              isView,
              beneficiaries.map((el) => {
                return el.businessName;
              })
            )}
            modalStyle={!isView && !isEdit && styles.modalStyle}
            className={styles.billsForm}
            isEdit={isEdit}
            isView={isView}
            initialValues={initialValues}
            title={
              isEdit
                ? "Modifica Fattura"
                : isView
                ? "Fattura"
                : "Creazione Fattura"
            }
            okText={isEdit ? "Salva Modifiche" : "Crea Nuova Fattura"}
            cancelText={"Annulla"}
            width={700}
            open={open}
            onSubmit={onSubmit}
            onCancel={() => {
              setOpen(false);
            }}
          />
          {/* END OF SUPPLIERS FORM */}

          <ModalCustom
            centered={true}
            title={"Cancella Fattura"}
            open={deleteModal}
            okText={"Conferma"}
            cancelText={"Annulla"}
            width={500}
            handleCancel={() => {
              setDeleteModal(false);
            }}
            handleOk={() => {
              onDelete(deleteId);
            }}
          >
            <Row className={styles.textContainer}>
              <Col className={styles.text} span={18}>
                {"Sei sicuro di voler cancellare questa fattura?"}
              </Col>
            </Row>
          </ModalCustom>
        </Col>
      </Row>
    </>
  );
}
