/* eslint-disable */
import {
  EditTwoTone,
  DeleteTwoTone,
  EyeTwoTone,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import CustomDropDown from "../../components/SelectMenu/CustomDropDown";

export function getColumns(
  setOpen,
  setIsEdit,
  setIsView,
  setInitialValues,
  setDeleteModal,
  setDeleteId,
  setIsNew
) {
  let columns = [
    {
      title: "Data",
      dataIndex: "dataUscita",
      key: "dataUscita",
      sorter: true,
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Tipologia Uscita",
      dataIndex: "tipologiaUscita",
      key: "tipologiaUscita",
      width: 100,
    },
    {
      title: "Descrizione Uscita",
      dataIndex: "descrizioneUscita",
      key: "descrizioneUscita",
      width: 100,
    },
    {
      title: "Uscita",
      dataIndex: "importo",
      key: "importo",
      width: 100,
    },
    {
      title: "Fatturato",
      dataIndex: "sales",
      key: "sales",
      width: 100,
    },
    {
      title: "",
      key: "edit",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return render.disabled ? (
          <EyeTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsView(true);
              setIsEdit(false);
              setIsNew(false);
              setInitialValues({
                ...render,
                dataUscita: dayjs(render.dataUscita),
                sales: render.sales === "SI" ? true : false,
              });
              setOpen(true);
            }}
          />
        ) : (
          <EditTwoTone
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsNew(false);
              setIsView(false);
              setIsEdit(true);
              setInitialValues({
                ...render,
                dataUscita: dayjs(render.dataUscita),
                sales: render.sales === "SI" ? true : false,
              });
              setOpen(true);
            }}
          />
        );
      },
      width: 40,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed: "right",
      render: (text, render) => {
        return (
          !render.disabled && (
            <DeleteTwoTone
              twoToneColor={"#990000"}
              onClick={() => {
                setDeleteId(render.id);
                setDeleteModal(true);
              }}
              style={{ fontSize: 18 }}
            />
          )
        );
      },
      width: 40,
    },
  ];

  return columns;
}

export function getOutflowsFormFields(
  isView,
  outFlowsType,
  handleDeleteTypo,
  onTypoSubmit
) {
  let fields = [
    {
      id: "dataUscita",
      label: "Data Uscita",
      name: "dataUscita",
      required: true,
      type: "datePicker",
      controls: false,
      disabled: isView,
    },
    {
      id: "sales",
      label: "Fatturato",
      name: "sales",
      required: false,
      type: "checkbox",
      controls: false,
      disabled: isView,
    },
    {
      id: "importo",
      label: "Importo",
      name: "importo",
      required: true,
      type: "inputNumber",
      controls: false,
      disabled: isView,
    },
    {
      id: "tipologiaUscita",
      label: "Tipologia Uscita",
      name: "tipologiaUscita",
      required: false,
      type: "select",
      dataForSelect: outFlowsType,
      controls: false,
      disabled: isView,
      listHeight: 176,
      dropdownRender: (menu) => {
        return (
          <>
            {menu}
            <CustomDropDown
              showDelete={true}
              showInput={true}
              showAdd={true}
              addText={"Aggiungi"}
              cancelText={"Cancella"}
              placeholder={"Inserire la Tipologia"}
              onDelete={handleDeleteTypo}
              onSubmit={onTypoSubmit}
            />
          </>
        );
      },
    },
    {
      id: "descrizioneUscita",
      label: "Descrizione Uscita",
      name: "descrizioneUscita",
      required: false,
      type: "textArea",
      controls: false,
      disabled: isView,
    },
    { id: "id", name: "id" },
  ];

  return fields;
}

export function getOutflowsFormFilters(outFlowsType) {
  let fields = [
    {
      id: "dataRange",
      label: "Seleziona periodo Incasso",
      name: "dataRange",
      placeholder: ["Incasso da", "Incasso a"],
      required: false,
      showTime: false,
      type: "rangeDatePicker",
    },
    {
      id: "meseRadioGroup",
      label: "",
      name: "meseRadioGroup",
      required: false,
      type: "radioGroup",
      group: [
        { value: "meseCorr", label: "Mese Corrente" },
        { value: "mesePrec", label: "Mese Precedente" },
      ],
    },
    {
      id: "descrizione",
      label: "Tipologia Uscita",
      name: "descrizione",
      required: false,
      type: "select",
      dataForSelect: outFlowsType,
      controls: false,
      disabled: false,
    },
    {
      id: "search",
      label: "Cerca",
      name: "search",
      type: "button",
    },
  ];

  return fields;
}

export function getTypoFormField(isNewTypo, outFlowsType) {
  let obj = isNewTypo
    ? {
        id: "descrizione",
        label: "Tipologia Uscita",
        name: "descrizione",
        required: true,
        type: "input",
      }
    : {
        id: "descrizione",
        label: "Tipologia Uscita",
        name: "descrizione",
        required: false,
        type: "select",
        dataForSelect: outFlowsType,
        controls: false,
        disabled: false,
      };

  let fields = [obj];

  return fields;
}
