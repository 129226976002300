import {
  CREATE_BILL,
  DELETE_BILL,
  EDIT_BILL,
  GET_BILLS,
} from "../../utility/graphql/BillQueries";
import { ApolloRepo } from "../../utility/graphql/Client";
import { strapiAdapter } from "../../utility/methods/methods";

class GestioneFattureServiceC {
  getBills(page, pageSize, sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_BILLS,
        variables: {
          pagination: { page: page, pageSize: pageSize },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.bills),
          meta: res.data.bills.meta,
        };
      });
  }

  createBill(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_BILL,
      variables: data,
    });
  }

  deleteBill(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_BILL,
      variables: { id: id },
    });
  }

  updateBill(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_BILL,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateBill);
      });
  }
}

export const GestioneFattureService = new GestioneFattureServiceC();
