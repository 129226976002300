import { ApolloRepo } from "../../utility/graphql/Client";
import { GET_RESERVATIONS } from "../../utility/graphql/ReservationQueries";
import { strapiAdapter } from "../../utility/methods/methods";

class GestioneCalendarioServiceC {
  getReservations(filters) {
    return ApolloRepo.client
      .query({
        query: GET_RESERVATIONS,
        variables: {
          pagination: { pageSize: 400 },
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.reservations),
          meta: res.data.reservations.meta,
        };
      });
  }
}

export const GestioneCalendarioService = new GestioneCalendarioServiceC();
