import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultValues: {
    import: 0,
    beneficiary: "",
    bank: "",
    paid: false,
    checkNumber: ""
  },
};

export const gestioneAssegniSlice = createSlice({
  name: "gestioneAssegni",
  initialState,
  reducers: {},
});

//export const {} = gestioneAssegniSlice.actions;

export default gestioneAssegniSlice.reducer;
