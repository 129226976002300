import { gql } from "@apollo/client";

export const GET_BENEFICIARIES = gql`
  query getBeneficiaries(
    $pagination: PaginationArg
    $sort: [String]
    $filters: BeneficiaryFiltersInput
  ) {
    beneficiaries(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          businessName
          ivaNumber
          email
          telephone
          beneficiary_type {
            data {
              id
              attributes {
                supplierId
                description
              }
            }
          }
        }
      }
      meta {
        pagination {
          pageSize
          total
        }
      }
    }
  }
`;

export const CREATE_BENEFICIARY = gql`
  mutation createBeneficiary($data: BeneficiaryInput!) {
    createBeneficiary(data: $data) {
      data {
        id
      }
    }
  }
`;

export const EDIT_BENEFICIARY = gql`
  mutation updateBeneficiary($id: ID!, $data: BeneficiaryInput!) {
    updateBeneficiary(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_BENEFICIARY = gql`
  mutation deleteBeneficiary($id: ID!) {
    deleteBeneficiary(id: $id) {
      data {
        id
      }
    }
  }
`;
