/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  getColumns,
  getIncomeFormFields,
  getIncomeFormFilters,
} from "./GestioneIncassiConfig";
import { Form, Button, Row, Col } from "antd";
import FormInModal from "../../components/FormInModal/FormInModal";
import dayjs from "dayjs";
import FormCustom from "../../components/FormCustom/FormCustom";
import styles from "./GestioneIncassiPage.module.scss";
import TableCustom from "../../components/Table/TableCustom";
import { GestioneIncassiService } from "./GestioneIncassiService";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import ModalCustom from "../../components/Modal/ModalCustom";
import openNotification from "./../../components/Notification/Notification";
import { useSelector, useDispatch } from "react-redux";
import { setDayBefore } from "../../redux/slices/GestioneIncassiSlice";

const getIncomesParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default function GestioneIncassiPage() {
  const [form] = Form.useForm();
  /* START REDUX SECTION */
  const defaultValues = useSelector(
    (state) => state.gestioneIncassi.defaultValues
  );
  /* END REDUX SECTION */
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  /* END MODAL TRIGGERS */
  const [totalExit, setTotalExit] = useState(0);
  const [maxDate, setMaxDate] = useState();
  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: "date",
    order: "desc",
    filters: {},
  });
  const [dataSource, setDataSource] = useState([]);

  /* END OF TABLE CONFIG */

  const [defaultSearchValues, setDefaultSearchValues] = useState({
    dataRange: [],
    meseRadioGroup: "",
  });
  /* START MODAL FORM HANDLER SECTION */
  const [isView, setIsView] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isIncome, setIsIncome] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  /* END MODAL FORM HANDLER SECTION */

  const readData = () => {
    GestioneIncassiService.getIncomes(
      getIncomesParams(tableParams).page,
      getIncomesParams(tableParams).pageSize,
      getIncomesParams(tableParams).field +
        ":" +
        getIncomesParams(tableParams).order,
      getIncomesParams(tableParams).filters
    ).then((data) => {
      let arrForTot = [];
      arrForTot = data.data.map((el) => {
        let total = 0;

        el.outflows.forEach((item) => {
          total += item.importo;
        });

        return { id: el.id, total: total };
      });

      if (data && data.data.length > 0) {
        let matchDate = dayjs(maxDate).format("YYYY-MM-DD");
        data.data.forEach((el) => {
          if (el.date === matchDate)
            dispatch(
              setDayBefore({
                fondoCassa: el.fondoCassa,
                totaleBusta: el.totaleBusta,
              })
            );
        });
      } else {
        dispatch(
          setDayBefore({
            fondoCassa: 0,
            totaleBusta: 0,
          })
        );
      }

      setDataSource(
        data.data.map((el) => {
          let totaleUscite = el.totaleUscite;

          if (arrForTot) {
            let arr = arrForTot.filter((item) => item.id === el.id);
            totaleUscite = arr[0].total;
          }

          let cash = el.cash ? parseFloat(el.cash.toFixed(2)) : 0;
          let cashBusta = el.cashBusta ? el.cashBusta : 0;
          let chiusuraFiscale = el.chiusuraFiscale ? el.chiusuraFiscale : 0;
          let chiusuraPos = el.chiusuraPos
            ? parseFloat(el.chiusuraPos.toFixed(2))
            : 0;
          let date = dayjs(el.date).format("DD/MM/YYYY");
          let fatture = el.fatture ? parseFloat(el.fatture.toFixed(2)) : 0;
          let fondoCassa = el.fondoCassa
            ? parseFloat(el.fondoCassa.toFixed(2))
            : 0;
          let scaricoBusta = el.scaricoBusta
            ? parseFloat(el.scaricoBusta.toFixed(2))
            : 0;
          let totaleBusta = el.totaleBusta ? el.totaleBusta : 0;
          let outflows = el.outflows ? el.outflows : 0;
          let disabled = dayjs(el.date) < maxDate ? true : false;
          let fondoCassaGp = el.fondoCassaGp ? el.fondoCassaGp : 0;
          let totaleBustaGp = el.totaleBustaGp ? el.totaleBustaGp : 0;
          let theFork = el.theFork ? parseFloat(el.theFork.toFixed(2)) : 0;

          return {
            id: el.id,
            cash: cash,
            cashBusta: cashBusta,
            chiusuraFiscale: chiusuraFiscale,
            chiusuraPos: chiusuraPos,
            date: date,
            fatture: fatture,
            fondoCassa: fondoCassa,
            scaricoBusta: scaricoBusta,
            totaleBusta: totaleBusta,
            totaleUscite: totaleUscite,
            outflows: outflows,
            disabled: disabled,
            fondoCassaGp: fondoCassaGp,
            totaleBustaGp: totaleBustaGp,
            theFork: theFork,
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    readData();
  }, [maxDate]);

  useEffect(() => {
    setLoading(true);
    GestioneIncassiService.getMaxDate().then((data) => {
      if (data.data) setMaxDate(dayjs(data?.data[0]?.date));
    });
    readData();
  }, [JSON.stringify(tableParams)]);

  const onSubmit = (values) => {
    setLoading(true);
    let objToSend = {
      data: {
        ...values,
        date: dayjs(values.date).format("YYYY-MM-DD"),
        outflows: values.outflows.map((el) => el.id),
      },
    };

    delete objToSend.data.id;

    if (isNew) {
      GestioneIncassiService.createIncome(objToSend)
        .then(() => {
          readData();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    } else if (isEdit) {
      GestioneIncassiService.updateIncome(parseInt(values.id), objToSend.data)
        .then((res) => {
          setMaxDate(dayjs(res.date));
          readData();
          openNotification(
            "Successo",
            "La modifica è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
    setOpen(false);
  };

  const onSearch = (values) => {
    let filtersToSend = values.dataRange.length
      ? {
          and: [
            {
              date: { gte: dayjs(values.dataRange[0]).format("YYYY-MM-DD") },
            },
            {
              date: { lte: dayjs(values.dataRange[1]).format("YYYY-MM-DD") },
            },
          ],
        }
      : {};

    setTableParams((prevState) => {
      return {
        ...prevState,
        filters: filtersToSend,
      };
    });

    delete values.search;
    setDefaultSearchValues(values);
  };

  const onDelete = (id) => {
    setLoading(true);
    GestioneIncassiService.deleteIncome(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
    setDeleteModal(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let order = "desc";

    if (sorter?.order === "ascend") {
      order = "asc";
    }

    let sort = {
      ...sorter,
      field: sorter.field ? sorter.field : "date",
      order: order,
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
        ...sort,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  const dataToExcel = (data) => {
    let list = [...data];
    let listToSend = [];

    list.forEach((el) => {
      listToSend.push({
        ["Cash"]: el.cash,
        ["Cash Busta"]: el.cashBusta,
        ["Chiusura Fiscale"]: el.chiusuraFiscale,
        ["Chiusura Pos"]: el.chiusuraPos,
        ["Data"]: el.date,
        ["Fatture"]: el.fatture,
        ["Fondo Cassa"]: el.fondoCassa,
        ["Scarico Busta"]: el.scaricoBusta,
        ["The Fork"]: el.theFork,
        ["Totale Busta"]: el.totaleBusta,
        ["Totale Uscite"]: el.totaleUscite,
      });
    });

    return listToSend;
  };

  return (
    <div>
      {/* START OF FILTER SECTION */}
      <div className={styles.searchFormContainer}>
        <FormCustom
          isSearch={true}
          form={form}
          currentRow={defaultSearchValues}
          className={styles.searchForm}
          id={"incomeFilterForm"}
          name={"incomeFilterForm"}
          fields={getIncomeFormFilters()}
          onFinish={onSearch}
        />
      </div>
      {/* END OF FILTER SECTION */}

      {/* START TABLE SECTION */}
      <Row>
        <Col span={24}>
          <TableCustom
            excelConfig={{
              excel: true,
              excelText: "Esporta",
              dataToExport: dataToExcel(dataSource),
              fileName: "Gestione Incassi",
              colSize: 13,
            }}
            loading={loading}
            className={styles.table}
            rowClassName={(record, index) =>
              record.disabled === true ? styles.disabledRow : styles.defaultRow
            }
            size={width <= 1300 ? "small" : "large"}
            scroll={{
              x: 1250,
            }}
            columns={getColumns(
              setOpen,
              setIsEdit,
              setIsView,
              setInitialValues,
              setDeleteModal,
              setDeleteId,
              setIsNew
            )}
            dataSource={dataSource}
            title={() => (
              <span style={{ fontWeight: "bold" }}>
                {"Elenco Incassi".toLocaleUpperCase()}
              </span>
            )}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            showPagination={true}
            pageSizeOptions={["5", "10", "20", "50"]}
            showSizeChanger={true}
          />
        </Col>
      </Row>
      {/* END TABLE SECTION */}

      {/* START NEW/EDIT RECORD SECTION */}
      <Row>
        <Col span={4}>
          <Button
            className={styles.newEntry}
            type="primary"
            onClick={() => {
              setTotalExit(0);
              setIsNew(true);
              setIsView(false);
              setIsEdit(false);
              setOpen(true);
              setIsIncome(true);
            }}
          >
            {"Nuovo Incasso"}
          </Button>
        </Col>
      </Row>

      <FormInModal
        dataSource={dataSource}
        isSearch={false}
        totalExit={totalExit}
        setIsIncome={setIsIncome}
        defaultValues={{ ...defaultValues }}
        getFormFields={getIncomeFormFields(isView)}
        modalStyle={!isView && !isEdit && styles.modalStyle}
        className={styles.incomeForm}
        isIncome={isIncome}
        isEdit={isEdit}
        isView={isView}
        initialValues={initialValues}
        title={
          isEdit ? "Modifica Incasso" : isView ? "Incasso" : "Nuovo Incasso"
        }
        okText={isEdit ? "Salva Modifiche" : "Salva Incasso"}
        cancelText={"Annulla"}
        width={1000}
        open={open}
        onSubmit={onSubmit}
        onCancel={() => {
          setOpen(false);
        }}
        centered={true}
      />

      <ModalCustom
        centered={true}
        title={"Cancella Incasso"}
        open={deleteModal}
        okText={"Conferma"}
        cancelText={"Annulla"}
        width={500}
        handleCancel={() => {
          setDeleteModal(false);
        }}
        handleOk={() => {
          onDelete(deleteId);
        }}
      >
        <Row className={styles.textContainer}>
          <Col className={styles.text} span={18}>
            {"Sei sicuro di voler cancellare questo incasso?"}
          </Col>
        </Row>
      </ModalCustom>
    </div>
  );
}
