import { gql } from "@apollo/client";

export const GET_BENEFICIARIES_TYPES = gql`
  query getBeneficiariesTypes(
    $pagination: PaginationArg
    $sort: [String]
    $filters: BeneficiaryTypeFiltersInput
  ) {
    beneficiaryTypes(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          description
          supplierId
        }
      }
      meta {
        pagination {
          pageSize
          total
        }
      }
    }
  }
`;

export const CREATE_BENEFICIARY_TYPE = gql`
  mutation createBeneficiaryType($data: BeneficiaryTypeInput!) {
    createBeneficiaryType(data: $data) {
      data {
        id
      }
    }
  }
`;

export const EDIT_BENEFICIARY_TYPE = gql`
  mutation updateBeneficiaryType($id: ID!, $data: BeneficiaryTypeInput!) {
    updateBeneficiaryType(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_BENEFICIARY_TYPE = gql`
  mutation deleteBeneficiaryType($id: ID!) {
    deleteBeneficiaryType(id: $id) {
      data {
        id
      }
    }
  }
`;
