import { ApolloRepo } from "../../utility/graphql/Client";
import { GET_YEAR_INCOME } from "../../utility/graphql/HomepageQueries";
import { strapiAdapter } from "../../utility/methods/methods";

class HomepageServiceC {
  getYearIncome(page, pageSize, filters) {
    return ApolloRepo.client
      .query({
        query: GET_YEAR_INCOME,
        variables: {
          pagination: { page: page, pageSize: pageSize },
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.incomes),
          meta: res.data.incomes.meta,
        };
      });
  }
}

export const HomepageService = new HomepageServiceC();
