import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsers {
    usersPermissionsUsers {
      data {
        id
        attributes {
          username
          type
          role {
            data {
              id
            }
          }
        }
      }
      meta {
        pagination {
          pageSize
          page
          total
          pageCount
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          type
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($data: UsersPermissionsUserInput!) {
    createUsersPermissionsUser(data: $data) {
      data {
        id
        attributes {
          username
          email
          role {
            data {
              id
            }
          }
          type
        }
      }
    }
  }
`;

export const EDIT_USER = gql`
  mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
        attributes {
          username
          email
          role {
            data {
              id
            }
          }
          type
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUsersPermissionsUser(id: $id) {
      data {
        id
      }
    }
  }
`;
