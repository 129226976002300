import { gql } from "@apollo/client";

export const GET_OUTFLOWS_TYPES = gql`
  query getOutflowsTypes($pagination: PaginationArg, $sort: [String]) {
    outflowTypes(pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          descrizione
        }
      }
      meta {
        pagination {
          pageSize
        }
      }
    }
  }
`;

export const CREATE_OUTFLOWS_TYPE = gql`
  mutation createOutflowType($data: OutflowTypeInput!) {
    createOutflowType(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_OUTFLOW_TYPE = gql`
  mutation deleteOutflowType($id: ID!) {
    deleteOutflowType(id: $id) {
      data {
        id
      }
    }
  }
`;
