import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultValues: {
    importo: 0,
    descrizioneUscita: "",
    descrizione: "",
    sales: false,
  },
};

export const gestioneUsciteSlice = createSlice({
  name: "gestioneUscite",
  initialState,
  reducers: {},
});

export const { setDayBefore } = gestioneUsciteSlice.actions;

export default gestioneUsciteSlice.reducer;
