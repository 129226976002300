import { ApolloRepo } from "../../utility/graphql/Client";
import { LOGIN } from "../../utility/graphql/LoginQueries";

class LoginServiceC {
  login(credential) {
    return ApolloRepo.client
      .mutate({
        mutation: LOGIN,
        variables: credential,
      })
      .then((res) => {
        return { data: res.data };
      });
  }

  logout() {
    return ApolloRepo.client.resetStore().then(() => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("persist:root");
      localStorage.clear();
    });
  }
}

export const LoginService = new LoginServiceC();
