import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
const TopicMenu = ({
  topics,
  selectedKey,
  changeSelectedKey,
  isAdmin,
  isAdministrative,
}) => {
  let styledTopics = [];

  topics.forEach((topic, index) =>
    styledTopics.push({
      label: <Link to={("/" + topic).replace(" ", "-")}>{topic}</Link>,
      key: index + "",
      onClick: changeSelectedKey,
    })
  );

  function createGroup(arr) {
    let countArr = arr.map((el) => {
      if (
        el.key === "3" ||
        el.key === "4" ||
        el.key === "5" ||
        el.key === "6" ||
        el.key === "7"
      )
        return el;
    });
    let restaurantArr = arr.map((el) => {
      if (el.key === "1" || el.key === "2") return el;
    });

    return [
      arr[0],
      !isAdministrative
        ? {
            type: "divider",
          }
        : "",
      !isAdministrative
        ? {
            type: "group",
            label: "Ristorazione",
            children: restaurantArr,
          }
        : "",
      isAdmin || isAdministrative
        ? {
            type: "divider",
          }
        : "",
      isAdmin || isAdministrative
        ? {
            type: "group",
            label: "Contabilità",
            children: countArr,
          }
        : "",
      !isAdmin || isAdministrative
        ? ""
        : {
            type: "divider",
          },
      !isAdmin || isAdministrative
        ? ""
        : {
            type: "group",
            label: "Impostazioni",
            children: [styledTopics[8]],
          },
    ];
  }

  return (
    <Menu
      mode="inline"
      selectedKeys={[selectedKey]}
      items={createGroup(styledTopics)}
    />
  );
};
export default TopicMenu;
