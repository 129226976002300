import {
  CREATE_ALLERGY,
  DELETE_ALLERGY,
  GET_ALLERGIES,
} from "../../utility/graphql/AllergiesQueries";
import { ApolloRepo } from "../../utility/graphql/Client";
import {
  CREATE_CLIENT,
  EDIT_CLIENT,
  GET_CLIENTS,
  GET_SINGLE_CLIENT,
} from "../../utility/graphql/ClientQueries";
import {
  CREATE_DIETARY,
  DELETE_DIETARY,
  GET_DIETARY_NEED,
} from "../../utility/graphql/DietaryNeedQueries";
import {
  CREATE_EVENT,
  DELETE_EVENT,
  GET_EVENTS,
} from "../../utility/graphql/EventQueries";
import {
  CREATE_RESERVATION,
  DELETE_RESERVATION,
  EDIT_RESERVATION,
  GET_RESERVATIONS,
} from "../../utility/graphql/ReservationQueries";
import { strapiAdapter } from "../../utility/methods/methods";

class GestionePrenotazioniServiceC {
  getReservations(page, pageSize, sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_RESERVATIONS,
        variables: {
          pagination: { page: page, pageSize: pageSize },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.reservations),
          meta: res.data.reservations.meta,
        };
      });
  }

  createReservation(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_RESERVATION,
      variables: data,
    });
  }

  updateReservation(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_RESERVATION,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateReservation);
      });
  }

  /* START OF CLIENT SECTION */

  getClients(filters) {
    return ApolloRepo.client
      .query({
        query: GET_CLIENTS,
        variables: {
          pagination: { limit: -1 },
          filters: filters,
          sort: "name:asc",
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.clients),
        };
      });
  }

  getSingleClient(id) {
    if (id) {
      return ApolloRepo.client
        .query({
          query: GET_SINGLE_CLIENT,
          variables: { id: id },
        })
        .then((res) => {
          return strapiAdapter(res.data.client);
        });
    }
  }

  createClient(data) {
    return ApolloRepo.client
      .mutate({
        mutation: CREATE_CLIENT,
        variables: data,
      })
      .then((res) => {
        return {
          id: res.data?.createClient?.data?.id,
        };
      });
  }

  updateClient(id, data) {
    return ApolloRepo.client
      .mutate({
        mutation: EDIT_CLIENT,
        variables: { id: id, data: data },
      })
      .then((res) => {
        return strapiAdapter(res.data.updateClient);
      });
  }

  deleteReservation(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_RESERVATION,
      variables: { id: id },
    });
  }
  /* END OF CLIENT SECTION */

  /* START OF EVENTS SECTION */

  getEvents(sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_EVENTS,
        variables: {
          pagination: { limit: -1 },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.events),
          meta: res.data.events.meta,
        };
      });
  }

  createEvent(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_EVENT,
      variables: data,
    });
  }

  deleteEvent(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_EVENT,
      variables: { id: id },
    });
  }

  /* END OF EVENTS SECTION */

  /* START OF ALLERGIES SECTION */

  getAllergies(sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_ALLERGIES,
        variables: {
          pagination: { limit: -1 },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.allergies),
          meta: res.data.allergies.meta,
        };
      });
  }

  createAllergy(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_ALLERGY,
      variables: data,
    });
  }

  deleteAllergy(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_ALLERGY,
      variables: { id: id },
    });
  }

  /* END OF ALLERGIES SECTION */

  /* START OF ALLERGIES SECTION */

  getDietaryNeed(sort, filters) {
    return ApolloRepo.client
      .query({
        query: GET_DIETARY_NEED,
        variables: {
          pagination: { limit: -1 },
          sort: sort,
          filters: filters,
        },
      })
      .then((res) => {
        return {
          data: strapiAdapter(res.data.dietaryNeeds),
          meta: res.data.dietaryNeeds.meta,
        };
      });
  }

  createDietary(data) {
    return ApolloRepo.client.mutate({
      mutation: CREATE_DIETARY,
      variables: data,
    });
  }

  deleteDietary(id) {
    return ApolloRepo.client.mutate({
      mutation: DELETE_DIETARY,
      variables: { id: id },
    });
  }

  /* END OF ALLERGIES SECTION */
}

export const GestionePrenotazioniService = new GestionePrenotazioniServiceC();
