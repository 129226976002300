import React, { useEffect, useState } from "react";
import {
  getColumns,
  getChecksFormFields,
  getChecksFormFilters,
} from "./GestioneAssegniConfig";
import { Button, Table, Row, Col } from "antd";
import FormInModal from "../../components/FormInModal/FormInModal";
import dayjs from "dayjs";
import FormCustom from "../../components/FormCustom/FormCustom";
import styles from "./GestioneAssegniPage.module.scss";
import TableCustom from "../../components/Table/TableCustom";
import { GestioneAssegniService } from "./GestioneAssegniService";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import { Form } from "antd";
import ModalCustom from "../../components/Modal/ModalCustom";
import openNotification from "./../../components/Notification/Notification";
import { useSelector } from "react-redux";

const getChecksParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default function GestioneAssegniPage() {
  const [form] = Form.useForm();
  const defaultValues = useSelector(
    (state) => state.gestioneAssegni.defaultValues
  );
  const { width } = useWindowDimensions();
  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  /* END MODAL TRIGGERS */

  const [defaultSearchValues, setDefaultSearchValues] = useState({
    dataRange: [],
    meseRadioGroup: "",
  });
  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: "dateCheck",
    order: "desc",
    filters: {},
  });
  const [dataSource, setDataSource] = useState([]);
  /* END OF TABLE CONFIG */
  /* START MODAL FORM HANDLER SECTION */
  const [isView, setIsView] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [banks, setBanks] = useState([]);
  const [banksId, setBanksIds] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesIds, setBeneficiariesIds] = useState([]);
  /* END MODAL FORM HANDLER SECTION */

  const readData = () => {
    GestioneAssegniService.getChecks(
      getChecksParams(tableParams).page,
      getChecksParams(tableParams).pageSize,
      getChecksParams(tableParams).field +
      ":" +
      getChecksParams(tableParams).order,
      getChecksParams(tableParams).filters
    ).then((data) => {
      setDataSource(
        data.data?.map((el) => {
          return {
            id: el.id,
            dateCheck: el.dateCheck,
            import: parseFloat(el.import.toFixed(2)),
            beneficiary: el.beneficiary?.businessName,
            bank: el.bank?.description,
            paid: el.paid ? "SI" : "NO",
            checkNumber: el.checkNumber,
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    handleBank();
    handleBeneficiaries();
  }, []);

  useEffect(() => {
    setLoading(true);
    readData();
  }, [JSON.stringify(tableParams)]);

  const onSubmit = (values) => {
    setLoading(true);
    let bankId = banksId?.find((el) => el.description === values.bank)?.id;

    let beneficiaryId = beneficiariesIds.find(
      (el) => el.businessName === values.beneficiary
    )?.id;
    let objToSend = {
      data: {
        ...values,
        dateCheck: dayjs(values.dateCheck).format("YYYY-MM-DD"),
        bank: bankId,
        beneficiary: beneficiaryId,
      },
    };

    delete objToSend.data.id;

    if (isNew) {
      GestioneAssegniService.createCheck(objToSend)
        .then(() => {
          readData();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    } else if (isEdit) {
      GestioneAssegniService.updateCheck(parseInt(values.id), objToSend.data)
        .then((res) => {
          readData();
          openNotification(
            "Successo",
            "La modifica è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }

    setOpen(false);
  };

  const onSearch = (values) => {
    let filtersToSend;
    let dateToSend = values.dataRange?.length
      ? {
        and: [
          {
            dateCheck: {
              gte: dayjs(values.dataRange[0]).format("YYYY-MM-DD"),
            },
          },
          {
            dateCheck: {
              lte: dayjs(values.dataRange[1]).format("YYYY-MM-DD"),
            },
          },
        ],
      }
      : {};

    let propsToSend =
      values.bank || values.beneficiary
        ? {
          or: [
            {
              bank: {
                description: { eq: values.bank ? values.bank : "" },
              },
            },
            {
              beneficiary: {
                businessName: {
                  eq: values.beneficiary ? values.beneficiary : "",
                },
              },
            },
          ],
        }
        : {};

    filtersToSend = {
      ...dateToSend,
      ...propsToSend,
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        filters: filtersToSend,
      };
    });

    delete values.search;
    setDefaultSearchValues(values);
  };

  const onDelete = (id) => {
    setLoading(true);
    GestioneAssegniService.deleteCheck(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });

    setDeleteModal(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let order = "desc";

    if (sorter?.order === "ascend") {
      order = "asc";
    }

    let sort = {
      ...sorter,
      field: sorter.field ? sorter.field : "dateCheck",
      order: order,
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
        ...sort,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  const dataToExcel = (data) => {
    let list = [...data];
    let listToSend = [];

    list.forEach((el) => {
      listToSend.push({
        ["Data Assegno"]: el.dateCheck,
        ["Beneficiario"]: el.beneficiary,
        ["Banca Appoggio"]: el.bank,
        ["Importo"]: el.import,
        ["Pagato"]: el.paid,
      });
    });

    return listToSend;
  };

  /* START OF BANK SECTION */

  const handleBank = () => {
    GestioneAssegniService.getBanks().then((res) => {
      let arr = [];
      res.data.forEach((el) => {
        arr.push({
          id: el.id,
          description: el.description,
        });
      });
      setBanks(arr);
      setBanksIds(res.data);
    });
  };

  const onBankSubmit = (values) => {
    if (values != "") {
      let objToSend = {
        data: {
          description: values,
        },
      };

      GestioneAssegniService.createBank(objToSend)
        .then(() => {
          handleBank();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
  };

  const handleDeleteBank = (values) => {
    let bankToDelete = banks.filter((el) =>
      el.description.toLowerCase().includes(values.toLowerCase())
    )[0].id;

    GestioneAssegniService.deleteBank(bankToDelete)
      .then(() => {
        handleBank();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
  };

  /* END OF BANK SECTION */

  /* START OF BENEFICIARIES SECTION */
  const handleBeneficiaries = () => {
    GestioneAssegniService.getBeneficiaries().then((res) => {
      let arr = [];
      res.data.forEach((el) => {
        arr.push({
          id: el.id,
          description: el.businessName,
        });
      });
      setBeneficiaries(arr);
      setBeneficiariesIds(res.data);
    });
  };

  /*END OF BENEFICIARIES SECTION */

  return (
    <div>
      {/* START OF FILTER SECTION */}
      <div className={styles.searchFormContainer}>
        <FormCustom
          form={form}
          currentRow={defaultSearchValues}
          className={styles.searchForm}
          id={"checksFilterForm"}
          name={"checksFilterForm"}
          fields={getChecksFormFilters(
            banks.map((el) => {
              return el.description;
            }),
            beneficiaries.map((el) => {
              return el.description;
            })
          )}
          onFinish={onSearch}
          isSearch={true}
        />
      </div>
      {/* END OF FILTER SECTION */}

      {/* START TABLE SECTION */}
      <Row>
        <Col span={24}>
          <TableCustom
            excelConfig={{
              excel: true,
              excelText: "Esporta",
              dataToExport: dataToExcel(dataSource),
              fileName: "Gestione Assegni",
              colSize: 16,
            }}
            loading={loading}
            className={styles.table}
            rowClassName={(record, index) =>
              record.disabled === true ? styles.disabledRow : styles.defaultRow
            }
            size={width <= 1300 ? "small" : "large"}
            scroll={{
              x: 1250,
            }}
            columns={getColumns(
              setOpen,
              setIsEdit,
              setIsView,
              setInitialValues,
              setDeleteModal,
              setDeleteId,
              setIsNew
            )}
            dataSource={dataSource}
            title={() => (
              <span style={{ fontWeight: "bold" }}>
                {"Elenco Assegni".toLocaleUpperCase()}
              </span>
            )}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            showPagination={true}
            pageSizeOptions={["5", "10", "20", "50"]}
            showSizeChanger={true}
          />
        </Col>
      </Row>
      {/* END TABLE SECTION */}

      {/* START NEW/EDIT RECORD SECTION */}
      <div className={styles.buttonContainer}>
        <div className={styles.buttonCol}>
          <Button
            className={styles.newEntry}
            type="primary"
            onClick={() => {
              setIsNew(true);
              setIsView(false);
              setIsEdit(false);
              setOpen(true);
            }}
          >
            {"Nuovo Assegno"}
          </Button>
        </div>
      </div>

      {/* START OF CHECKS FORM */}
      <FormInModal
        isSearch={false}
        dataSource={dataSource}
        defaultValues={defaultValues}
        getFormFields={getChecksFormFields(
          isView,
          banks.map((el) => {
            return el.description;
          }),
          beneficiaries.map((el) => {
            return el.description;
          }),
          handleDeleteBank,
          onBankSubmit
        )}
        modalStyle={!isView && !isEdit && styles.modalStyle}
        className={styles.outflowForm}
        isEdit={isEdit}
        isView={isView}
        initialValues={initialValues}
        title={
          isEdit ? "Modifica Assegno" : isView ? "Assegno" : "Nuovo Assegno"
        }
        okText={isEdit ? "Salva Modifiche" : "Salva Assegno"}
        cancelText={"Annulla"}
        width={800}
        open={open}
        onSubmit={onSubmit}
        onCancel={() => {
          setOpen(false);
        }}
      />
      {/* END OF CHECKS FORM */}

      <ModalCustom
        centered={true}
        title={"Cancella Assegno"}
        open={deleteModal}
        okText={"Conferma"}
        cancelText={"Annulla"}
        width={500}
        handleCancel={() => {
          setDeleteModal(false);
        }}
        handleOk={() => {
          onDelete(deleteId);
        }}
      >
        <Row className={styles.textContainer}>
          <Col className={styles.text} span={18}>
            {"Sei sicuro di voler cancellare questo assegno?"}
          </Col>
        </Row>
      </ModalCustom>
    </div>
  );
}
