import { Calendar, Divider, Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { GestioneCalendarioService } from "./GestioneCalendarioService";
import styles from "./GestioneCalendarioPage.module.scss";
import { UserOutlined } from "@ant-design/icons";

export default function GestioneCalendarioPage() {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    readData({});
  }, []);

  const readData = (filters) => {
    GestioneCalendarioService.getReservations(filters).then((data) => {
      setDataSource(
        data.data?.map((el) => {
          return {
            id: el?.id ? el.id : "",
            resDate: el.resDate,
            eventId: el.event?.id,
            event: el.event?.description ? el.event.description : [],
            moment: el.moment,
            note: el.note,
            people: el.people,
            time: el.time.slice(0, 5),
            clientId: el.client?.id,
            allergies:
              el.client?.allergies && el.client?.allergies.length > 0
                ? el.client.allergies?.map((el) => {
                    return el.description;
                  })
                : undefined,
            clientNote: el.client?.clientNote,
            dietaryNeeds:
              el.client?.dietary_needs && el.client.dietary_needs.length > 0
                ? el.client.dietary_needs.map((el) => {
                    return el.description;
                  })
                : undefined,
            email: el.client?.email,
            lastName: el.client?.lastName,
            name: el.client?.name,
            sit: el.client?.sit,
            prefix: el.client?.telephone.split(" ")[0],
            telephone: el.client?.telephone.split(" ")[1],
            status: el.status,
            clientInfo: el.client?.name + " " + el.client?.lastName,
          };
        })
      );
    });
  };

  const filterMomentList = (list, value) => {
    return list
      .filter((el) => el.moment === value)
      .reduce((acc, result) => acc + result.people, 0);
  };

  const onPanelChange = (value, mode) => {
    let dateToSend = value
      ? {
          and: [
            {
              resDate: {
                gte: dayjs(value).startOf("month").format("YYYY-MM-DD"),
              },
            },
            {
              resDate: {
                lte: dayjs(value).endOf("month").format("YYYY-MM-DD"),
              },
            },
          ],
        }
      : {};

    readData(dateToSend);
  };

  const dateCellRender = (value) => {
    const stringValue = dayjs(value).format("YYYY-MM-DD");
    const listData = dataSource.filter((el) => el.resDate === stringValue);

    return (
      <>
        {
          <div className={"momentContainer"}>
            <div className="dataContainer">
              <span>{"Pranzo"}</span>
              <div>
                <UserOutlined style={{ color: "#1890FF" }} />
                <span className={styles.people}>
                  {filterMomentList(listData, "pranzo") + "p"}
                </span>
              </div>
            </div>
            <Divider />
            <div className={"dataContainer"}>
              <span>{"Cena"}</span>
              <div>
                <UserOutlined style={{ color: "#1890FF" }} />
                <span className={styles.people}>
                  {filterMomentList(listData, "cena") + "p"}
                </span>
              </div>
            </div>
          </div>
        }
      </>
    );
  };

  return (
    <>
      <Calendar
        className={styles.calendar}
        disabledDate={(current) => {
          return dayjs().add(-1, "days") >= current;
        }}
        dateCellRender={dateCellRender}
        onPanelChange={onPanelChange}
      />
    </>
  );
}
