import { Col, Form, Row, Button } from "antd";
import React, { useState, useEffect } from "react";
import FormInModal from "../../components/FormInModal/FormInModal";
import TableCustom from "../../components/Table/TableCustom";
import FormCustom from "../../components/FormCustom/FormCustom";
import useWindowDimensions from "../../utility/hooks/useWindowDimensions";
import {
  getColumns,
  getClientsFormFields,
  searchBarClientsFields,
} from "./GestioneClientiConfig";
import styles from "./GestioneClientiPage.module.scss";
import { GestioneClientiService } from "./GestioneClientiService";
import openNotification from "./../../components/Notification/Notification";
import ModalCustom from "../../components/Modal/ModalCustom";

const getClientsParams = (params) => ({
  pageSize: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

export default function GestioneClientiPage() {
  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const [defaultValues] = useState({
    name: "",
    lastName: "",
    prefix: "+39",
    telephone: "",
    email: "",
    sit: "",
    clientNote: "",
    allergies: undefined,
    dietary_needs: undefined,
  });

  const [defaultSearchValues, setDefaultSearchValues] = useState({
    name: "",
    lastName: "",
    email: "",
    telephone: "",
  });

  /* START OF TABLE CONFIG */
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    field: ["lastName", "name"],
    order: "asc",
    filters: {},
  });

  const [dataSource, setDataSource] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [allergyIds, setAllergyIds] = useState([]);
  const [dietaryNeeds, setDietaryNeeds] = useState([]);
  const [dietaryNeedIds, setDietaryNeedIds] = useState([]);
  /* END OF TABLE CONFIG */

  /* START MODAL TRIGGERS */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  /* END MODAL TRIGGERS */

  /* START MODAL FORM HANDLER SECTION */
  const [isView, setIsView] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  /* END MODAL FORM HANDLER SECTION */

  const readData = () => {
    GestioneClientiService.getClients(
      getClientsParams(tableParams).page,
      getClientsParams(tableParams).pageSize,
      getClientsParams(tableParams).field?.map((el) => {
        return el + ":" + getClientsParams(tableParams).order;
      }),
      getClientsParams(tableParams).filters
    ).then((data) => {
      setDataSource(
        data.data.map((el) => {
          return {
            id: el.id,
            name: el.name,
            lastName: el.lastName,
            prefix: el.telephone?.split(" ")[0],
            telephone: el.telephone?.split(" ")[1],
            email: el.email,
            sit: el.sit,
            clientNote: el.clientNote,
            allergies:
              el.allergies && el.allergies.length > 0
                ? el.allergies?.map((el) => {
                    return el.description;
                  })
                : undefined,
            dietaryNeeds:
              el.dietary_needs && el.dietary_needs.length > 0
                ? el.dietary_needs.map((el) => {
                    return el.description;
                  })
                : undefined,
          };
        })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.meta.pagination.total,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    readData();
    handleAllergies();
    handleDietaryNeeds();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    let order = "desc";

    if (sorter?.order === "ascend") {
      order = "asc";
    }

    let sort = {
      ...sorter,
      field: sorter.field === "lastName" ? ["name", "lastName"] : sorter.field,
      order: order,
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: pagination,
        ...sort,
      };
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  const dataToExcel = (values) => {
    let list = [...values];
    let listToSend = [];
    list.forEach((el) => {
      listToSend.push({
        ["Nome"]: el.name,
        ["Cognome"]: el.lastName,
        ["Telefono"]: el.prefix + " " + el.telephone,
        ["Email"]: el.email,
        ["Posto"]: el.sit,
        ["Note"]: el.clientNote,
        ["Allergie e Intolleranze"]:
          el.allergies && el.allergies.length > 0
            ? el.allergies.join(", ")
            : "",
        ["Esigenze Alimentari Specifiche"]:
          el.dietaryNeeds && el.dietaryNeeds.length > 0
            ? el.dietaryNeeds.join(", ")
            : "",
      });
    });

    return listToSend;
  };

  const onSubmit = (values) => {
    setLoading(true);
    let allergy = values.allergies?.length > 0 ? values.allergies : "";
    let allergyId = allergyIds
      .filter((el) => {
        return allergy.includes(el.description);
      })
      .map((el) => {
        return el.id;
      });
    let dietary = values.dietaryNeeds?.length > 0 ? values.dietaryNeeds : "";

    let dietaryNeedId = dietaryNeedIds
      .filter((el) => {
        return dietary.includes(el.description);
      })
      .map((el) => {
        return el.id;
      });

    let objToSend = {
      data: {
        name: values.name,
        lastName: values.lastName,
        telephone: values.prefix + " " + values.telephone,
        email: values.email === "" ? null : values.email,
        sit: values.sit,
        allergies: allergyId ? allergyId : null,
        dietary_needs: dietaryNeedId ? dietaryNeedId : null,
        clientNote: values.clientNote,
      },
    };

    if (isNew) {
      GestioneClientiService.createClient(objToSend)
        .then(() => {
          readData();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    } else if (isEdit) {
      GestioneClientiService.updateClient(parseInt(values.id), objToSend.data)
        .then((res) => {
          readData();
          openNotification(
            "Successo",
            "La modifica è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
    setOpen(false);
  };

  /* START OF ALLERGIES SECTION */
  const handleAllergies = () => {
    GestioneClientiService.getAllergies().then((res) => {
      let arr = [];
      res.data.forEach((el, key) => {
        arr.push({
          key: key,
          id: el.id,
          description: el.description,
        });
      });
      setAllergies(arr);
      setAllergyIds(res.data);
    });
  };

  const onAllergySubmit = (values) => {
    if (values != "") {
      let objToSend = {
        data: {
          description: values,
        },
      };

      GestioneClientiService.createAllergy(objToSend)
        .then(() => {
          handleAllergies();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
  };

  const handleDeleteAllergy = (values) => {
    let allergyToDelete = allergies.filter((el) =>
      el.description.toLowerCase().includes(values.toLowerCase())
    )[0].id;

    GestioneClientiService.deleteAllergy(allergyToDelete)
      .then(() => {
        handleAllergies();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
  };
  /* END OF ALLERGIES SECTION */

  /* START OF DIETARY_NEEDS SECTION */
  const handleDietaryNeeds = () => {
    GestioneClientiService.getDietaryNeed().then((res) => {
      let arr = [];
      res.data.forEach((el, key) => {
        arr.push({
          key: key,
          id: el.id,
          description: el.description,
        });
      });
      setDietaryNeeds(arr);
      setDietaryNeedIds(res.data);
    });
  };

  const onDietarySubmit = (values) => {
    if (values != "") {
      let objToSend = {
        data: {
          description: values,
        },
      };

      GestioneClientiService.createDietary(objToSend)
        .then(() => {
          handleDietaryNeeds();
          openNotification(
            "Successo",
            "La creazione è andata a buon fine",
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          openNotification(
            "Errore",
            "Impossibile completare l'operazione",
            "error"
          );
        });
    }
  };

  const handleDeleteDietary = (values) => {
    let dietaryToDelete = dietaryNeeds.filter((el) =>
      el.description.toLowerCase().includes(values.toLowerCase())
    )[0].id;

    GestioneClientiService.deleteDietary(dietaryToDelete)
      .then(() => {
        handleDietaryNeeds();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });
  };

  /* END OF DIETARY_NEEDS SECTION */

  /* START OF CLIENT SEARCH SECTION */

  const onClientSearch = (value) => {
    let filtersToSend = {
      or: [
        { name: { containsi: value.clientSearchInfo } },
        { lastName: { containsi: value.clientSearchInfo } },
        { email: { containsi: value.clientSearchInfo } },
        { telephone: { containsi: value.clientSearchInfo } },
      ],
    };

    setTableParams((prevState) => {
      return {
        ...prevState,
        filters: filtersToSend,
      };
    });

    delete value.search;
    setDefaultSearchValues(value);
  };
  /* END OF CLIENT SEARCH SECTION */
  const onDelete = (id) => {
    setLoading(true);
    GestioneClientiService.deleteClient(id)
      .then(() => {
        readData();
        openNotification(
          "Successo",
          "La cancellazione è andata a buon fine",
          "success"
        );
      })
      .catch(() => {
        setLoading(false);
        openNotification(
          "Errore",
          "Impossibile completare l'operazione",
          "error"
        );
      });

    setDeleteModal(false);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          {/* START TABLE SECTION */}
          <Row>
            <FormCustom
              form={form}
              currentRow={defaultSearchValues}
              className={styles.clientSearchBar}
              id={"clientSearch"}
              name={"clientSearch"}
              fields={searchBarClientsFields()}
              isSearch={true}
              onFinish={onClientSearch}
              style={{
                width: 200,
              }}
            />
          </Row>
          <Row>
            <Col span={24}>
              <TableCustom
                excelConfig={{
                  excel: true,
                  excelText: "Esporta",
                  dataToExport: dataToExcel(dataSource),
                  fileName: "Gestione Clienti",
                  colSize: 16,
                }}
                loading={loading}
                className={styles.table}
                size={width <= 1300 ? "small" : "large"}
                scroll={{
                  x: 1250,
                }}
                columns={getColumns(
                  setOpen,
                  setIsEdit,
                  setIsView,
                  setInitialValues,
                  setIsNew,
                  setDeleteId,
                  setDeleteModal
                )}
                dataSource={dataSource}
                title={() => (
                  <>
                    <span style={{ fontWeight: "bold" }}>
                      {"Elenco Clienti".toLocaleUpperCase()}
                    </span>
                  </>
                )}
                onChange={handleTableChange}
                pagination={tableParams.pagination}
                showPagination={true}
                pageSizeOptions={["5", "10", "20", "50"]}
                showSizeChanger={true}
              />
            </Col>
          </Row>
          {/* END TABLE SECTION */}
          {/* START NEW/EDIT RECORD SECTION */}
          <Row>
            <Col span={4}>
              <Button
                className={styles.newEntry}
                type="primary"
                onClick={() => {
                  setIsNew(true);
                  setIsView(false);
                  setIsEdit(false);
                  setOpen(true);
                }}
              >
                {"Nuovo Cliente"}
              </Button>
            </Col>
          </Row>

          {/* START OF CLIENTS FORM */}
          <FormInModal
            isSearch={false}
            dataSource={dataSource}
            defaultValues={defaultValues}
            getFormFields={getClientsFormFields({
              initialValues: initialValues,
              isView: isView,
              allergies: allergies.map((el) => {
                return el.description;
              }),
              dietaryNeeds: dietaryNeeds.map((el) => {
                return el.description;
              }),
              onAllergySubmit: onAllergySubmit,
              handleDeleteAllergy: handleDeleteAllergy,
              onDietarySubmit: onDietarySubmit,
              handleDeleteDietary: handleDeleteDietary,
            })}
            modalStyle={!isView && !isEdit && styles.modalStyle}
            className={styles.clientsForm}
            isEdit={isEdit}
            isView={isView}
            initialValues={initialValues}
            title={
              isEdit
                ? "Modifica Cliente"
                : isView
                ? "Cliente"
                : "Creazione Cliente"
            }
            okText={isEdit ? "Salva Modifiche" : "Crea nuovo Cliente"}
            cancelText={"Annulla"}
            width={800}
            open={open}
            onSubmit={onSubmit}
            onCancel={() => {
              setOpen(false);
            }}
            centered={true}
          />
          {/* END OF CLIENTS FORM */}
        </Col>
        <ModalCustom
          centered={true}
          title={"Cancella Cliente"}
          open={deleteModal}
          okText={"Conferma"}
          cancelText={"Annulla"}
          width={500}
          handleCancel={() => {
            setDeleteModal(false);
          }}
          handleOk={() => {
            onDelete(deleteId);
          }}
        >
          <Row className={styles.textContainer}>
            <Col className={styles.text} span={18}>
              {"Sei sicuro di voler cancellare questo Cliente?"}
            </Col>
          </Row>
        </ModalCustom>
      </Row>
    </>
  );
}
