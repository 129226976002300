  import {
      CREATE_BENEFICIARY_TYPE,
    DELETE_BENEFICIARY_TYPE,
    EDIT_BENEFICIARY_TYPE,
    GET_BENEFICIARIES_TYPES,
  } from "../../utility/graphql/BeneficiariesTypeQueries";
  import { ApolloRepo } from "../../utility/graphql/Client";
  import { strapiAdapter } from "../../utility/methods/methods";
  
  class GestioneTipoFornitoreServiceC {
  
    /* START OF BENEFICIARY TYPE SECTION */
  
    getBeneficiariesTypes(page, pageSize, sort, filters,) {
      return ApolloRepo.client
        .query({
          query: GET_BENEFICIARIES_TYPES,
          variables: {
            pagination: { page: page, pageSize: pageSize },
            sort: sort,
            filters: filters,
          },
        })
        .then((res) => {
          return {
            data: strapiAdapter(res.data.beneficiaryTypes),
            meta: res.data.beneficiaryTypes.meta,
          };
        });
    }
  
    createBeneficiaryType(data) {
      return ApolloRepo.client.mutate({
        mutation: CREATE_BENEFICIARY_TYPE,
        variables: data,
      });
    }
  
    updateBeneficiaryType(id, data) {
      return ApolloRepo.client
        .mutate({
          mutation: EDIT_BENEFICIARY_TYPE,
          variables: { id: id, data: data },
        })
        .then((res) => {
          return strapiAdapter(res.data.updateBeneficiaryType);
        });
    }
  
    deleteBeneficiaryType(id) {
      return ApolloRepo.client.mutate({
        mutation: DELETE_BENEFICIARY_TYPE,
        variables: { id: id },
      });
    }
  
    /* END OF BENEFICIARY TYPE SECTION */
  }
  
  export const GestioneTipoFornitoreService = new GestioneTipoFornitoreServiceC();
  